import styled from "styled-components";
import { Popup } from "react-leaflet";

export const StyledPopup = styled(Popup)`
  .leaflet-popup-content {
    margin: 0;
    padding: 12px;
    display: flex;
    flex-direction: column;
  }
`;

export const TitleWrapper = styled.div`
  border-bottom: 1px solid #f2f4f7;
  padding-bottom: 12px;
`;

export const Title = styled.span`
  text-align: left;
  font: normal normal bold 14px Open Sans;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  opacity: 1;
`;

export const ChipWrapper = styled.div`
  background: #f2f4f7 0% 0% no-repeat padding-box;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 5px 5px 0;
`;

export const Icon = styled.img`
  margin-right: 9px;
`;

export const LabelChip = styled.span`
  font: normal normal 600 12px Open Sans;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  opacity: 1;
`;

export const LabelInfo = styled.span`
  font: normal normal 12px Open Sans;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  opacity: 1;
  margin-left: 7px;
`;

export const InfoTitle = styled.span`
  font: normal normal 600 12px Open Sans;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  opacity: 1;
  margin-left: 7px;
`;
