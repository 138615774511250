import styled from "styled-components";
import BoardGeofences from "Components/Map/Geofences/BoardGeofences";
import BoardTrips from "Components/Map/Trips/BoardTrips";
import TableOptions from "Components/TableOptions";

export const ContentWrapper = styled.div`
  display: flex;
  min-height: -webkit-fill-available;
  height: -webkit-fill-available;
  flex-direction: row;

  margin-top: 20px;
`;

export const DataWrapper = styled.div`
  width: 70%;
  margin-right: 16px;
`;

export const OwnerWrapper = styled.div`
  width: 30%;
`;

export const WrapperBoards = styled.div`
  display: flex;
  min-height: -webkit-fill-available;
  height: -webkit-fill-available;
  flex-direction: row;
`;

export const WrapperShifts = styled.div<{ height?: number }>`
  display: flex;
  width: 100%;
  min-height: 100px;
  height: ${(props) => (props.height ? `${props.height}px` : "auto")};
  flex-direction: row;
  gap: 12px;
  margin-bottom: 12px;
`;

export const Listagem = styled(BoardGeofences)`
  width: 30%;
  min-width: 250px;
  height: 600px;
  padding: auto;
  margin-bottom: 10px;
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.layout.shadow};
`;

export const ListagemTrips = styled(BoardTrips)`
  width: 30%;
  min-width: 250px;
  height: 600px;
  padding: auto;
  margin-bottom: 10px;
  margin-left: 20px;
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.layout.shadow};
`;

export const Filters = styled(TableOptions)`
  padding-left: 0px;
`;
