import React, { useState, useEffect, useCallback } from "react";
import Search from ".";
import { IFilter } from "..";

interface useSearchGeofencesProps {
  filters: IFilter[];
  setFilters: React.Dispatch<React.SetStateAction<IFilter[]>>;
  customLabel?: string;
  customName?: string;
  shouldPersist?: boolean;
}

const useSearchGeofences = ({
  filters,
  setFilters,
  customLabel,
  customName,
  shouldPersist = false,
}: useSearchGeofencesProps) => {
  const [openSearchGeofences, setSearchGeofences] = useState(false);
  const [nonPersistedFiltersGeofences, setNonPersistedFiltersGeofences] =
    useState<string[]>([]);
  const [persistedFiltersGeofences, setPersistedFiltersGeofences] =
    useLocalStorage<string[]>("persistedGeofences", []);

  const filtersGeofences = shouldPersist
    ? persistedFiltersGeofences
    : nonPersistedFiltersGeofences;

  const setFiltersGeofences = shouldPersist
    ? setPersistedFiltersGeofences
    : setNonPersistedFiltersGeofences;

  const clearFilters = () => {
    setFiltersGeofences([]);
  };

  const handleOpen = () => {
    setSearchGeofences(true);
  };

  const tagGeofence = useCallback(() => {
    const name = customName || "geofences";
    const index = filters.findIndex((value) => value.name === name);

    const auxLabel = customLabel || "Alvos";
    const label =
      filtersGeofences.length === 1 ? filtersGeofences[0] : auxLabel;

    if (index === -1) {
      if (filtersGeofences.length > 0) {
        const filterGeofence: IFilter = {
          label,
          name,
          clear: clearFilters,
          open: handleOpen,
        };

        setFilters([filterGeofence, ...filters]);
      }
    } else {
      const listFilters = [...filters];

      if (filtersGeofences.length === 0) {
        listFilters.splice(index, 1);
      } else {
        listFilters[index] = {
          label,
          name,
          clear: clearFilters,
          open: handleOpen,
        };
      }

      setFilters(listFilters);
    }
  }, [filtersGeofences]);

  const SearchGeofences = () => (
    <Search
      open={openSearchGeofences}
      onClose={() => setSearchGeofences(false)}
      onApplyFilters={(value) => setFiltersGeofences(value)}
      shouldPersist={shouldPersist}
    />
  );

  return {
    SearchGeofences,
    filtersGeofences,
    tagGeofence,
    setSearchGeofences,
    openSearchGeofences,
  };
};

export default useSearchGeofences;

export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
}
