import React, { useState } from "react";

import TimePicker from "BaseComponents/Calendar/TimePicker";
import { v4 as uuidv4 } from "uuid";
import { shiftsType } from "declarations";
import { H3SemiBold, H4SemiBold } from "BaseComponents/Typographies";
import {
  WrapperContent,
  AddIcon,
  StyledButton,
  WrapperShifts,
  WrapperInputs,
} from "./styles";

interface ShiftsProps {
  shifts: shiftsType;
  onChange: (value: shiftsType) => void;
}

const Shifts = ({ shifts, onChange }: ShiftsProps) => {
  const addShift = () => {
    const newShift = [null, null];
    const newShifts = [...shifts, newShift];
    onChange(newShifts);
  };

  const changeTimeShift = (index: number, pos: 0 | 1, newDate: Date) => {
    const newShifts = [...shifts];

    newShifts[index][pos] = newDate;

    onChange([...newShifts]);
  };

  return (
    <WrapperContent>
      <H3SemiBold>Turnos de trabalho</H3SemiBold>
      <WrapperShifts>
        {shifts.map((value, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <WrapperInputs key={`${index}`}>
            <H4SemiBold>Turno {index + 1}:</H4SemiBold>
            <TimePicker
              date={value[0]}
              setDate={(date) => changeTimeShift(index, 0, date)}
            />
            <TimePicker
              date={value[1]}
              setDate={(date) => changeTimeShift(index, 1, date)}
            />
          </WrapperInputs>
        ))}
        <StyledButton onClick={addShift}>
          <AddIcon />
        </StyledButton>
      </WrapperShifts>
    </WrapperContent>
  );
};

export default Shifts;
