/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from "react";
import Table, {
  Col as ColTable,
  Option as OptionTable,
} from "BaseComponents/Table";
import PageWrapper from "Components/Wrappers/PageWrapper";
import { Add, BorderColorOutlined, DeleteOutline } from "@material-ui/icons";
import { deleteUser, getUsers, deleteManyUsers } from "Api/Users";
import { User } from "declarations";
import toasts from "Shared/toasts";
import Pagination from "BaseComponents/Table/Pagination";
import TableOptions, { IFilter } from "Components/TableOptions";
import useCrud from "Hooks/useCrud";
import { ContentWrapper } from "./styles";

import CrudUsuarios from "./CrudUsuarios";

const optionsRowsPerPage = [10, 20, 30];

const Usuarios = () => {
  const [openCrud, setOpenCrud] = useState(false);
  const [userEdit, setUserEdit] = useState<User>();

  const {
    listCrud,
    setListCrud,
    totalCrud,
    setTotalCrud,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    sort,
    setSort,
    filters,
    setFilters,
    loading,
    setLoading,
    search,
    setSearch,
  } = useCrud<User>();

  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getUsers(
        rowsPerPage,
        page * rowsPerPage,
        sort,
        filters.map((filter) => filter.name),
        search
      );
      setListCrud(response.data);
      setTotalCrud(response.total);
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    rowsPerPage,
    page,
    sort,
    filters,
    setListCrud,
    setTotalCrud,
    search,
  ]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const removeUser = async (id: string) => {
    try {
      await deleteUser(id);
      toasts.success("Usuário removido com sucesso!");
      fetchUsers();
    } catch (error) {
      toasts.error((error as Error).message);
    }
  };

  const removeManyUsers = async (ids: User[]) => {
    try {
      await deleteManyUsers(ids.map((value) => value._id));
      toasts.success("Usuários removidos com sucesso!");
      fetchUsers();
    } catch (error) {
      toasts.error((error as Error).message, error);
    }
  };

  const openEdit = (itemData: any) => {
    setUserEdit({
      _id: itemData._id,
      email: itemData.email,
      roles: itemData.roles,
    });
  };

  const openEditCrud = !!userEdit;

  const removeFilter = (index: number) => {
    const listFilters = [...filters];
    listFilters.splice(index, 1);
    setFilters(listFilters);
  };

  const addFilter = (filter: IFilter) => {
    const isFilterExist = filters.find((value) => value.name === filter.name);
    !isFilterExist && setFilters([...filters, filter]);
  };

  const handleSort = (value: any) =>
    setSort({
      field: value.name,
      order: value.order === "asc" ? 1 : -1,
    });

  const handleNew = () => {
    setOpenCrud(true);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const itemsFilter = [
    {
      label: "Administrador",
      action: () => addFilter({ label: "Administrador", name: "admin" }),
    },
    {
      label: "Usuário",
      action: () => addFilter({ label: "Usuario", name: "regularUser" }),
    },
  ];

  const colsTable: ColTable[] = [
    { name: "_id", label: "ID", highlight: true, scope: "th" },
    { name: "email", label: "Email", align: "left" },
  ];

  const optionsTable: OptionTable[] = [
    {
      label: "Editar",
      action: openEdit,
      Icon: <BorderColorOutlined />,
    },
    {
      label: "Remover",
      action: (itemData) => removeUser(itemData._id as string),
      Icon: <DeleteOutline />,
    },
  ];

  const handlePage = (pageNumber: number) => {
    setPage(pageNumber - 1);
  };

  const handleRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
  };

  const handleCloseNewCrud = () => {
    setOpenCrud(false);
  };

  const handleCloseEditCrud = () => {
    setUserEdit(undefined);
  };

  return (
    <PageWrapper title="Usuarios">
      <ContentWrapper>
        <TableOptions
          itemsFilter={itemsFilter}
          filtersTags={filters}
          removeTag={removeFilter}
          onNew={handleNew}
          onSearch={handleSearch}
          placeholderSearchBar="Buscar por matricula ou nome"
        />
        <Table<User>
          loading={loading}
          cols={colsTable}
          data={listCrud}
          options={optionsTable}
          onOrder={handleSort}
          onDeleteAll={removeManyUsers}
        />
      </ContentWrapper>
      <Pagination
        count={totalCrud}
        onChangePage={handlePage}
        onChangeRowsPerPage={handleRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        optionsRowsPerPage={optionsRowsPerPage}
      />
      <CrudUsuarios
        open={openCrud}
        onClose={handleCloseNewCrud}
        title="Adicionar usuário"
        type="new"
        onSuccess={fetchUsers}
      />
      <CrudUsuarios
        open={openEditCrud}
        onClose={handleCloseEditCrud}
        title="Editar usuário"
        type="edit"
        user={userEdit}
        onSuccess={fetchUsers}
      />
    </PageWrapper>
  );
};

export default Usuarios;
