/* eslint-disable no-underscore-dangle */
import React, { useContext, useState, useEffect } from "react";
import { Geofence } from "declarations";
import Map from "Components/Map";

import { FeatureGroup } from "react-leaflet";

import CrudGeofences from "Components/Map/Geofences/CrudGeofences";

import { getGeofences, deleteGeofences } from "Api/Geofences";
import toasts from "Shared/toasts";

const Geocercas = () => {
  const [geofences, setGeofences] = useState<Geofence[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalGeofences, setTotalGeofences] = useState(0);

  const fetchGeofences = async (skip: number) => {
    try {
      setLoading(true);
      const response = await getGeofences(skip);
      setTotalGeofences(response.total);
      if (skip === 0) {
        setGeofences([...response.data]);
      } else {
        setGeofences([...geofences, ...response.data]);
      }
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGeofences(0);
  }, []);

  const centralizeGeofence = (latlng: L.LatLngExpression) => {};

  const removeGeofence = async (id: string) => {
    try {
      await deleteGeofences(id);
      const newGeofences = geofences.filter((geofence) => geofence._id !== id);
      setGeofences(newGeofences);
      setTotalGeofences(totalGeofences - 1);
      toasts.success("Geocerca removida com sucesso!");
    } catch (error) {
      toasts.error((error as Error).message);
    }
  };

  const loadMore = async () => {
    if (totalGeofences > geofences.length)
      await fetchGeofences(geofences.length);
  };

  const hasNextPage = totalGeofences > 0 && geofences.length < totalGeofences;

  const geofencesPerPage = 10;

  const pages = totalGeofences / geofencesPerPage - 1;

  return (
    <Map
      title="Gerenciamento - alvos"
      subtitle={`(${totalGeofences} cadastros)`}
      center={[-20.54213711944774, -43.74270918881854]}
      zoom={13}
      positionZoomControl="bottomright"
    >
      <FeatureGroup>
        <CrudGeofences
          geofences={geofences}
          centralize={centralizeGeofence}
          remove={removeGeofence}
          loading={loading}
          pages={pages}
          loadMore={loadMore}
          hasNextPage={hasNextPage}
          fetchGeofences={fetchGeofences}
        />
      </FeatureGroup>
    </Map>
  );
};

export default Geocercas;
