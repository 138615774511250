/* eslint-disable no-underscore-dangle */
import React from "react";
import { Geofence, GeofenceWithMachines } from "declarations";
import { v4 as uuidv4 } from "uuid";
import ItemGeofence from "Components/Map/Geofences/ItemGeofence";
import BoardList from "Components/BoardList";
import { isGeofenceWithMachines } from "Shared/utils";

interface ListGeofencesProps<T> {
  geofences: T[];
  loading?: boolean;
  clickGeofence?: (geofence: T) => void;
  className?: string;
  resize?: boolean;
  shouldStartMinimized?: boolean;
}

const BoardGeofences = <T extends GeofenceWithMachines | Geofence>({
  geofences,
  loading,
  clickGeofence = () => {},
  className,
  resize,
  shouldStartMinimized = false,
}: ListGeofencesProps<T>) => {
  const getExceeded = (timeTotal?: number, timeNow?: number) => {
    if (timeTotal && timeNow) {
      const diff = new Date().getTime() - timeNow;
      return diff > timeTotal;
    }
    return false;
  };

  const renderItemList = (geofence: T) => {
    let exceeded: boolean | undefined;

    if ("averageTime" in geofence && "averageTimeMachineNow" in geofence) {
      exceeded = getExceeded(
        geofence.averageTime,
        geofence.averageTimeMachineNow
      );
    }

    let machine: number | undefined;

    if (isGeofenceWithMachines(geofence)) {
      machine = geofence.machines.length;
    }

    return (
      <ItemGeofence
        key={geofence._id || uuidv4()}
        onClick={() => clickGeofence(geofence)}
        title={geofence.name}
        machines={machine}
        averageTime={geofence.averageTime}
        averageTimeNow={geofence.averageTimeMachineNow}
        exceeded={exceeded}
      />
    );
  };

  return (
    <BoardList
      title="Alvos"
      loading={loading}
      className={className}
      resize={resize}
      shouldStartMinimized={shouldStartMinimized}
    >
      {geofences.map((geofence) => renderItemList(geofence))}
    </BoardList>
  );
};

export default BoardGeofences;
