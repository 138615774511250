import React from "react";
import MapWrapper from "Components/Wrappers/MapWrapper";
import useTruckPath from "Components/Map/TruckPath/useTruckPath";

const Trajeto = () => {
  const { TruckPath } = useTruckPath({});

  return <MapWrapper title="Trajeto">{TruckPath()}</MapWrapper>;
};

export default Trajeto;
