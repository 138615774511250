import styled from "styled-components";
import {
  DeleteOutline,
  EditAttributes,
  EditOutlined,
} from "@material-ui/icons";
import PrimaryButton from "BaseComponents/Buttons/PrimaryButton";
import Input from "BaseComponents/Inputs/Input";
import { Avatar } from "@material-ui/core";

export const StyledAvatar = styled(Avatar)<{ exceeded: boolean }>`
  background-color: ${(props) =>
    props.exceeded
      ? props.theme.colors.denotative.error
      : "#0EE616"} !important;
  width: 30px !important;
  height: 30px !important;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 800;
  position: absolute;
  bottom: 20px;
  top: 20px;
  left: 20px;
  border-radius: 8px;
  overflow: auto;
  min-width: 250px;
`;

export const Header = styled.div`
  background: ${(props) => props.theme.colors.layout.blackPrimary};
  color: ${(props) => props.theme.colors.layout.whitePrimary};
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 10px;
`;

export const ListWrapper = styled.div`
  overflow: auto;
  max-height: 100vh;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 6px;
`;

export const ItemWrapper = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 2px 0;
  background: none;
  border: none;
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
  transition: background-color 400ms;
  position: relative;
  &:focus {
    outline: none;
  }
  &:active,
  &:hover {
    background: ${(props) => props.theme.colors.layout.whiteTertiary};
  }
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform-style: flat;
    transform: translate3d(-50%, -50%, 0);
    background: #ffffff1a;
    border-radius: 100%;
    transition: width 0.3s ease, height 0.3s ease;
  }
  &:active {
    &:after {
      width: 200px;
      height: 200px;
    }
  }
`;

export const EditWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100%;
`;

export const InputName = styled(Input)`
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 0;
`;

export const DeleteIcon = styled(DeleteOutline)`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  font-size: 14px;
`;
export const EditIcon = styled(EditOutlined)`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  font-size: 14px;
`;

export const Label = styled.p`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  font-size: 14px;
  margin-left: 10px;
  margin-right: auto;
`;

export const ButtonAction = styled(PrimaryButton)`
  width: 40%;
  height: 30px;
`;

export const PointsWrapper = styled.div`
  overflow: auto;
`;
