import styled from "styled-components";
import BoardGeofences from "Components/Map/Geofences/BoardGeofences";

export const Wrapper = styled.div`
  background: white 0% 0% no-repeat padding-box;

  box-shadow: 0px 0px 20px ${(props) => props.theme.colors.layout.shadow};
  overflow: auto;

  height: 90vh;
  width: 90vw;

  border-radius: 8px;

  &:focus {
    outline: none;
  }
`;
