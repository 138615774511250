import React from "react";
import { v4 as uuidv4 } from "uuid";
import { Wrapper, WrapperItem, LabelTitle } from "./styles";

export interface CaptionItem {
  src: string;
  title: string;
}

interface CaptionProps {
  items: CaptionItem[];
}

const Caption = ({ items }: CaptionProps) => {
  return (
    <Wrapper>
      {items.map((value) => (
        <WrapperItem key={uuidv4()}>
          <img
            className="my-div-image"
            src={value.src}
            alt={value.title}
            width="25"
          />
          <LabelTitle>{value.title}</LabelTitle>
        </WrapperItem>
      ))}
    </Wrapper>
  );
};

export default Caption;
