import { Modal } from "@material-ui/core";
import styled from "styled-components";

export const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div:focus {
    outline: none;
  }
`;
