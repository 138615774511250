import { DesktopAccessDisabled, DesktopWindows } from "@material-ui/icons";
import React from "react";

import { Button } from "./styles";

interface ButtonProps {
  className?: string;
  onClick?: () => void;
  resized: boolean;
}

const index = ({ className, onClick, resized }: ButtonProps) => {
  return (
    <Button
      onDoubleClickCapture={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick && onClick();
      }}
      aria-level={7}
      className={className}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick && onClick();
      }}
    >
      {resized ? <DesktopAccessDisabled /> : <DesktopWindows />}
    </Button>
  );
};

export default index;
