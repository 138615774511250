import React, { useEffect, useState } from "react";

/* eslint-disable no-underscore-dangle */
import { Backdrop, CircularProgress } from "@material-ui/core";

import { MapConsumer, MapContainer, TileLayer } from "react-leaflet";
import GeofencePolygon from "Components/Map/Geofences/Geofence";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { Geofence, GeofenceWithMachines } from "declarations";
import toasts from "Shared/toasts";
import { v4 as uuidv4 } from "uuid";
import { getGeofences } from "Api/Geofences";
import { LatLngExpression } from "leaflet";
import { center } from "Shared/utils";

import ItemOrigin from "Components/Map/MandatoryRoutes/ItemOrigin";
import SelectOrigin from "Components/Map/MandatoryRoutes/CrudRoutes/ModalOrigins/SelectOrigin";
import theme from "Styles/theme";
import { StyledList, WrapperMap } from "../styles";
import {
  WrapperContent,
  WrapperChoseAttendees,
  Title,
  WrapperAttendees,
} from "./styles";

interface AttendeesProps {
  originsSelected: Geofence[];
  owner?: Geofence;
  addOrigin: (value: Geofence) => void;
  removeOrigin: (value: Geofence) => void;
}

const Attendees = ({
  originsSelected,
  removeOrigin,
  addOrigin,
  owner,
}: AttendeesProps) => {
  const [origins, setOrigins] = useState<Geofence[]>([]);
  const [originToSelect, setOriginToSelect] = useState<Geofence>();
  const [totalOrigins, setTotalOrigins] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [coordinatesToZoom, setToZoom] = useState<LatLngExpression>();
  const fetchGeofences = async (skip: number) => {
    try {
      setLoading(true);
      const response = await getGeofences(skip);
      setTotalOrigins(response.total);
      if (skip === 0) {
        setOrigins([...response.data]);
      } else {
        setOrigins([...origins, ...response.data]);
      }
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGeofences(0);
  }, []);

  const selectOrigin = (geofence: Geofence | GeofenceWithMachines) => {
    setOriginToSelect({ ...geofence });
  };
  const setZoom = (geofence: Geofence | GeofenceWithMachines) => {
    setToZoom(center(geofence.coordinates.coordinates));
  };

  const handleAdd = (geofence: Geofence) => {
    addOrigin(geofence);
    setOriginToSelect(undefined);
  };

  const handleRemove = (geofence: Geofence) => {
    removeOrigin(geofence);
  };

  const hasNextPage: boolean = totalOrigins > origins.length;

  const fetchMore = async () => {
    if (isLoading) return;

    await fetchGeofences(origins.length);
  };

  const renderGeofences = origins.map((origin) => {
    if (owner && owner.name === origin.name) {
      return (
        <GeofencePolygon
          key={origin?._id || uuidv4()}
          geofence={origin}
          colorPolygon={theme.colors.interactive.primary}
        />
      );
    }

    return (
      <GeofencePolygon
        key={origin?._id || uuidv4()}
        geofence={origin}
        onClick={selectOrigin}
      />
    );
  });

  return (
    <WrapperContent>
      <WrapperChoseAttendees>
        <Title>Escolha os alvos participantes:</Title>
        <WrapperAttendees>
          {originsSelected.map((geofence) => (
            <ItemOrigin<Geofence>
              key={uuidv4()}
              item={geofence}
              onRemove={handleRemove}
              title={geofence.name}
            />
          ))}
        </WrapperAttendees>
      </WrapperChoseAttendees>
      <WrapperMap>
        <Backdrop open={isLoading} style={{ zIndex: 800 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <MapContainer
          zoom={13}
          center={[-20.54213711944774, -43.74270918881854]}
          zoomControl={false}
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <ReactLeafletGoogleLayer
            apiKey={process.env.REACT_APP_GMAPS_API_KEY}
            type="satellite"
          />
          <TileLayer url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png" />
          {renderGeofences}

          <MapConsumer>
            {(map) => {
              coordinatesToZoom && map.flyTo(coordinatesToZoom);
              return null;
            }}
          </MapConsumer>
        </MapContainer>
        <StyledList
          hasNextPage={hasNextPage}
          onLoadMore={fetchMore}
          origins={origins}
          onClick={setZoom}
          onSelect={selectOrigin}
          title="Alvos disponíveis"
          position="Right"
        />
        <SelectOrigin
          origin={originToSelect}
          onCancel={() => setOriginToSelect(undefined)}
          onConfirm={handleAdd}
          titleSelectedGeofences="lista de participantes"
        />
      </WrapperMap>
    </WrapperContent>
  );
};

export default Attendees;
