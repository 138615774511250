import styled from "styled-components";
import {
  AddOutlined,
  DeleteOutline,
  EditAttributes,
  EditOutlined,
} from "@material-ui/icons";
import PrimaryButton from "BaseComponents/Buttons/PrimaryButton";
import Input from "BaseComponents/Inputs/Input";
import { Avatar } from "@material-ui/core";

export const StyledAvatar = styled(Avatar)<{ exceeded: boolean }>`
  background-color: ${(props) =>
    props.exceeded
      ? props.theme.colors.denotative.error
      : "#0EE616"} !important;
  width: 30px !important;
  height: 30px !important;
`;

export const Wrapper = styled.div<{ position?: number }>`
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 800;
  position: absolute;
  bottom: ${(props) => (props.position ? "0" : "20px")};
  top: ${(props) => (props.position ? "0" : "20px")};
  left: ${(props) =>
    props.position
      ? `calc(${props.position} * 250px + (${props.position} + 1) * 20px)`
      : "20px"};
  border-radius: 8px;

  min-width: 250px;
`;

export const Header = styled.div`
  background: ${(props) => props.theme.colors.layout.blackPrimary};
  color: ${(props) => props.theme.colors.layout.whitePrimary};
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 10px;
`;

export const ListWrapper = styled.div`
  overflow: auto;
  max-height: 650px;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 6px;
`;

export const ItemWrapper = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 2px 0;
  background: none;
  border: none;
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
  transition: background-color 400ms;
  position: relative;
  &:focus {
    outline: none;
  }
  &:active,
  &:hover {
    background: ${(props) => props.theme.colors.layout.whiteTertiary};
  }
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform-style: flat;
    transform: translate3d(-50%, -50%, 0);
    background: #ffffff1a;
    border-radius: 100%;
    transition: width 0.3s ease, height 0.3s ease;
  }
  &:active {
    &:after {
      width: 200px;
      height: 200px;
    }
  }
`;

export const EditWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100%;
  position: relative;
`;

export const InputName = styled(Input)`
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
`;

export const ButtonsWrapper = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding: 16px;
  margin-top: auto;
  margin-bottom: 0;

  border-top: 1px solid ${(props) => props.theme.colors.layout.blackQuaternary};
`;

export const DeleteIcon = styled(DeleteOutline)`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  font-size: 14px;
`;
export const EditIcon = styled(EditOutlined)`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  font-size: 14px;
`;
export const AddIcon = styled(AddOutlined)`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  font-size: 14px;
`;

export const Label = styled.p`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  font-size: 14px;
  margin-left: 10px;
  margin-right: auto;
`;

export const ButtonAction = styled(PrimaryButton)`
  width: 40%;
  max-width: 150px;
  height: 30px;
`;

export const ListOrigins = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
`;
export const AddOrigin = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

export const LabelOrigins = styled.p`
  text-align: left;

  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  width: 100%;
  padding-bottom: 8px;
`;

export const ErrorText = styled.p`
  text-align: left;
  font-size: 12px;
  margin-top: 4px;
  color: ${(props) => props.theme.colors.denotative.error};
  opacity: 1;
`;
