/* eslint-disable no-underscore-dangle */
import React, { useContext, useState, useEffect } from "react";
import { ForbiddenDestination, Geofence, MandatoryRoute } from "declarations";
import { v4 as uuidv4 } from "uuid";
import Map from "Components/Map";
import UserContext from "Contexts/User";

import { FeatureGroup } from "react-leaflet";

import EditControl from "Components/Map/EditControl";

import CrudRoutes from "Components/Map/MandatoryRoutes/CrudRoutes";
import DestinationPolygon from "Components/Map/ForbiddenDestinations/Destination";
import GeofencePolygon from "Components/Map/Geofences/Geofence";

import {
  deleteRoute,
  getRoutes,
  postRoute,
  updateRoute,
} from "Api/MandatoryRoutes";
import toasts from "Shared/toasts";
import { Layer } from "leaflet";
import theme from "Styles/theme";

const RotasObrigatorias = () => {
  const { isAdmin } = useContext(UserContext);
  const [routes, setRoutes] = useState<MandatoryRoute[]>([]);
  const [layer, setLayer] = useState<Layer>();
  const [loading, setLoading] = useState(false);
  const [totalRoutes, setTotalRoutes] = useState(0);
  const [routeSelected, setRouteSelected] = useState<Geofence[]>([]);

  const fetchRoutes = async (skip: number) => {
    try {
      setLoading(true);
      const response = await getRoutes(skip);
      setTotalRoutes(response.total);
      if (skip === 0) {
        setRoutes([...response.data]);
      } else {
        setRoutes([...routes, ...response.data]);
      }
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoutes(0);
  }, []);

  const centralizeGeofence = (latlng: L.LatLngExpression) => {};

  const removeRoute = async (id: string) => {
    try {
      await deleteRoute(id);
      const newRoutes = routes.filter((route) => route._id !== id);
      setRoutes(newRoutes);
      setTotalRoutes(totalRoutes - 1);
      toasts.success("Rota removida com sucesso!");
    } catch (error) {
      toasts.error((error as Error).message);
    }
  };

  const loadMore = async () => {
    if (totalRoutes > routes.length) await fetchRoutes(routes.length);
  };

  const showRoute = (value: Geofence[]) => {
    setRouteSelected(value);
  };

  const hasNextPage = totalRoutes > 0 || routes.length < totalRoutes;

  const geofencesPerPage = 10;

  const pages = totalRoutes / geofencesPerPage - 1;

  return (
    <Map
      title="Rotas obrigatórias"
      subtitle={`(${totalRoutes} cadastros)`}
      center={[-20.54213711944774, -43.74270918881854]}
      zoom={13}
      positionZoomControl="bottomright"
    >
      <FeatureGroup>
        <CrudRoutes
          routes={routes}
          centralize={centralizeGeofence}
          remove={removeRoute}
          layer={layer}
          loading={loading}
          pages={pages}
          loadMore={loadMore}
          hasNextPage={hasNextPage}
          fetchRoutes={fetchRoutes}
          selectedRoute={showRoute}
        />

        {routeSelected.map((geofence, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <GeofencePolygon
              key={geofence?._id || uuidv4()}
              geofence={geofence}
              colorPolygon={theme.colors.denotative.success}
            />
          );
        })}
      </FeatureGroup>
    </Map>
  );
};

export default RotasObrigatorias;
