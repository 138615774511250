/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { Unit, ResponseUnits } from "declarations";
import feathers from "./Feathers";

const unitService = feathers.service("unit");

export const getUnits = (
  skip: number,
  search?: string
): Promise<ResponseUnits> =>
  unitService.find({
    query: {
      $limit: 10,
      $skip: skip,
      name: {
        $search: search,
      },
    },
  });

export const postUnit = (newUnit: Unit) => unitService.create(newUnit);

export const updateRoute = (unit: Unit): Promise<Unit> => {
  const id = unit._id;

  delete unit._id;
  const response = unitService.update(id, { ...unit });

  return response;
};

export const deleteUnit = (id: string) => unitService.remove(id);
