import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import Modal from "Components/Modal";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  DeepPartial,
  FieldName,
  FieldValues,
  Resolver,
  UnpackNestedValue,
  useForm,
} from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  Collapse,
  AccordionSummary,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { H4Bold, H4Regular } from "BaseComponents/Typographies";
import Input, { InputProps } from "BaseComponents/Inputs/Input";
import Confirmation from "BaseComponents/Popper/Confirmation";
import UserContext from "Contexts/User";
import {
  Wrapper,
  Footer,
  Body,
  Header,
  InfoAccordion,
  WrapperAccordion,
  WrapperInfos,
  ButtonCancel,
  ButtonSubmit,
} from "./styles";

export interface IDetails {
  key: string;
  name: string;
  format?: (value: any) => string;
}

export interface IInput<T> extends Omit<InputProps, "name"> {
  applyMask?: (event: React.ChangeEvent<HTMLInputElement>) => any;
  name?: keyof T;
}

interface ModalWithConfirmationProps<T extends FieldValues> {
  inputs?: IInput<T>[];
  validation?: Resolver<T, object>;
  defaultValues?: UnpackNestedValue<DeepPartial<T>>;
  open: boolean;
  onClose: () => void;
  onApply: (data: T) => void;
  children?: React.ReactNode;
  title: string;
  loading?: boolean;
  messageConfirmation?: string;
}

const messageConfirm =
  "Confira corretamente os dados, após a confirmação, a ação não poderá ser desfeita!";

const ModalWithConfirmation = <T extends FieldValues,>({
  inputs = [],
  open,
  onClose,
  title,
  defaultValues,
  validation,
  children,
  loading = false,
  onApply,
  messageConfirmation = messageConfirm,
}: ModalWithConfirmationProps<T>) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { register, handleSubmit, errors, setValue, getValues, reset } =
    useForm<T>({
      resolver: validation,
      defaultValues,
    });

  const initializeData = useCallback(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  const getValue = (value: T, key: string) => {
    const arrKeys = key.split(".");

    const returnValue = arrKeys.reduce((prev: any, curr) => {
      return prev[curr];
    }, value);

    return returnValue;
  };

  const getValueDetails = (value: T, detail: IDetails) => {
    const returnValue = getValue(value, detail.key);

    if (detail.format) return detail.format(returnValue);

    return returnValue;
  };

  useEffect(() => {
    initializeData();
  }, [initializeData]);

  const onOpenConfirm = () => {
    setAnchorEl(buttonRef.current);
  };

  const openConfirm = !!anchorEl;
  const id = openConfirm ? "simple-popper" : undefined;

  const onCloseConfirm = () => {
    setAnchorEl(null);
  };

  const submit = handleSubmit(async (data, e) => {
    if (loading) return;

    if (buttonRef.current) onOpenConfirm();
  });

  const applyData = () => {
    onCloseConfirm();
    onApply(getValues() as T);
    onClose();
  };

  const onCloseModal = () => {
    onCloseConfirm();
    onClose();
  };

  return (
    <Modal open={open} onClose={onCloseModal}>
      <Wrapper onSubmit={submit}>
        <Header>{title}</Header>
        <Body>
          {children}
          {inputs &&
            inputs.map((elem) => (
              <Input
                {...elem}
                // @ts-ignore
                errorText={(elem.name && errors[elem.name]?.message) || ""}
                name={elem.name?.toString()}
                ref={register()}
                onChange={(e) => {
                  elem.applyMask &&
                    elem.name &&
                    setValue(elem.name as any, elem.applyMask(e));
                }}
              />
            ))}
        </Body>
        <Footer>
          <ButtonSubmit ariaDescribedBy={id} ref={buttonRef} type="submit">
            Aplicar
          </ButtonSubmit>
          <ButtonCancel type="button" onClick={onCloseModal}>
            Cancelar
          </ButtonCancel>
          <Confirmation
            id={id}
            open={openConfirm}
            anchorEl={anchorEl}
            onCancel={onCloseConfirm}
            onConfirm={applyData}
            message={messageConfirmation}
          />
        </Footer>
      </Wrapper>
    </Modal>
  );
};

export default ModalWithConfirmation;
