import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  overflow-y: scroll;
  max-height: 100vh;
`;
