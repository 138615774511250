import React from "react";
import MenuLateral, { IListItems } from "BaseComponents/SideBar";
import theme from "Styles/theme";
import {
  DashboardOutlined,
  MapOutlined,
  ListOutlined,
  Assessment,
  Tune,
} from "@material-ui/icons";
import { Content, Wrapper } from "./styles";

const iconStyle = {
  color: theme.colors.layout.blackTertiary,
  fontSize: "24px",
};

const listItems: IListItems[] = [
  {
    titleLabel: "Dashboard",
    listLabels: ["Unidades", "Excesso de Velocidade", "Local Proibido"],
    icon: <DashboardOutlined style={iconStyle} />,
  },
  {
    titleLabel: "Monitoramento",
    listLabels: [
      "Mapa",
      "Trajeto",
      "Mapa de Calor",
      "Veículos Parados",
      "Pontos de Interesse"
    ],
    icon: <MapOutlined style={iconStyle} />,
  },
  {
    titleLabel: "Gerenciamento",
    listLabels: ["Alvos", "Rotas Obrigatórias", "Unidades", "Usuarios"],
    icon: <ListOutlined style={iconStyle} />,
  },
  {
    titleLabel: "Relatórios",
    listLabels: [
      "Excesso de Velocidade",
      "Local Proibido",
      "Permanência",
      "Viagens",
      "Rotas Proibidas",
      "Placas",
      "Auditoria Placas",
    ],
    icon: <Assessment style={iconStyle} />,
  },
  {
    titleLabel: "Opções",
    listLabels: ["Configurações"],
    icon: <Tune style={iconStyle} />,
  },
];

interface DefaultProps {
  children?: React.ReactNode;
}

const Default = ({ children }: DefaultProps) => {
  return (
    <Wrapper>
      <MenuLateral listItems={listItems} />
      <Content>{children}</Content>
    </Wrapper>
  );
};

export default Default;
