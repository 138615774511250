import PrimaryButton from "BaseComponents/Buttons/PrimaryButton";
import SecondaryButton from "BaseComponents/Buttons/SecondaryButton";
import styled from "styled-components";

export const Wrapper = styled.form`
  background: white 0% 0% no-repeat padding-box;
  border-radius: 8px;
  box-shadow: 0px 0px 20px ${(props) => props.theme.colors.layout.shadow};
  max-width: 60vw;
  &:focus {
    outline: none;
  }
`;

export const Header = styled.div`
  padding: 24px;
  font-weight: bold;
  font-size: 24px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  border-bottom: 1px solid ${(props) => props.theme.colors.layout.whiteTertiary};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;

  height: 40vh;
  max-height: 40vh;
  padding: 24px;
  overflow: hidden;
`;

export const Footer = styled.div`
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: row-reverse;
  border-top: 1px solid ${(props) => props.theme.colors.layout.whiteTertiary};
`;

export const ButtonSubmit = styled(PrimaryButton)`
  width: 140px;
  height: 40px;
`;

export const ButtonCancel = styled(SecondaryButton)`
  width: 140px;
  height: 40px;
`;

export const InfoAccordion = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const WrapperAccordion = styled.div`
  height: 100%;
`;

export const WrapperInfos = styled.div``;
