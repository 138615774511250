import dayjs from "dayjs";
import { Geofence, GeofenceWithMachines } from "declarations";
import {
  LatLngExpression,
  LatLngBoundsExpression,
  latLngBounds,
} from "leaflet";

export const formatCoordinates = (coordinates: number[][][]) => {
  const format = coordinates[0].map((item) => {
    return {
      lat: item[1],
      lng: item[0],
    } as LatLngExpression;
  });

  return format;
};

export const formatCoordinatesToBounds = (coordinates: number[][][]) => {
  const format = coordinates[0].map((item) => {
    return {
      lat: item[1],
      lng: item[0],
    } as LatLngExpression;
  });

  const middle = format.length / 2;

  const formatBounds = latLngBounds(format[middle], format[middle - 1]);

  return formatBounds;
};

export const center = (coordinates: number[][][]) => {
  let calc = coordinates[0].reduce(
    (prev, curr) => [prev[0] + curr[0], prev[1] + curr[1]],
    [0, 0]
  );

  calc = [calc[0] / coordinates[0].length, calc[1] / coordinates[0].length];

  return {
    lat: calc[1],
    lng: calc[0],
  } as LatLngExpression;
};

export const toMilliseconds = (time: string) => {
  const arrayTimes = time.split(":").map((value) => Number(value));

  let seconds = (arrayTimes[0] * 60 + arrayTimes[1]) * 60;

  if (arrayTimes.length === 3) {
    seconds += arrayTimes[2];
  }

  return seconds * 1000;
};

export const fromMilliseconds = (time: number) => {
  let total = time / 1000;
  let seconds = 0;
  let minutes = 0;

  if (total > 59) {
    seconds = total % 60;
  } else {
    return `0m`;
  }

  total = Math.trunc(total / 60);

  if (total > 59) {
    minutes = total % 60;
  } else {
    return `${total}m`;
  }

  total = Math.trunc(total / 60);

  return `${total}H${minutes}m`;
};

export const fromMillisecondsToHour = (time: number) => {
  let total = time / 1000;
  let seconds = 0;
  let secondsString = "0";
  let minutes = 0;
  let minutesString = "0";

  if (total > 59) {
    seconds = Math.trunc(total % 60);

    secondsString = seconds.toString();

    if (secondsString.length === 1) {
      secondsString = `0${secondsString}`;
    }
  } else {
    let totalString = Math.trunc(total).toString();

    if (totalString.length === 1) {
      totalString = `0${totalString}`;
    }

    return `00:00:${totalString}`;
  }

  total = Math.trunc(total / 60);

  if (total > 59) {
    minutes = Math.trunc(total % 60);

    minutesString = minutes.toString();

    if (minutesString.length === 1) {
      minutesString = `0${minutesString}`;
    }
  } else {
    let totalString = Math.trunc(total).toString();

    if (totalString.length === 1) {
      totalString = `0${totalString}`;
    }

    return `00:${totalString}:${secondsString}`;
  }

  total = Math.trunc(total / 60);

  let totalString = Math.trunc(total).toString();

  if (totalString.length === 1) {
    totalString = `0${totalString}`;
  }

  return `${totalString}:${minutesString}:${secondsString}`;
};

export const diffTime = (time1: number, time2: number) => {
  return time1 - time2;
};

export const diffTimeToString = (time1: number, time2: number) => {
  return fromMilliseconds(diffTime(time1, time2));
};

export const downloadFile = async (file: any, nameFile: string) => {
  const blobAux = await file.blob();
  const blob = new Blob([blobAux]);

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = nameFile;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export const downloadStreamedFile = async (url: string) => {
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

export function isGeofenceWithMachines(
  geofence: Geofence | GeofenceWithMachines
): geofence is GeofenceWithMachines {
  return (geofence as GeofenceWithMachines).machines !== undefined;
}
