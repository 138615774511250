import feathers from "Api/Feathers";
import { ResponseMachineLocation } from "declarations";

export const getMachineLocations = (
  skip: number,
  plaque: string,
  dateIn: Date,
  dateEnd: Date
): Promise<ResponseMachineLocation> =>
  feathers.service("machine-locations").find({
    query: {
      $skip: skip,
      $sort: {
        locationDate: 1,
      },
      plaque,
      $and: [
        {
          locationDate: {
            $gte: dateIn,
          },
        },
        {
          locationDate: {
            $lte: dateEnd,
          },
        },
      ],
    },
  });
