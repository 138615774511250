import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "BaseComponents/Inputs/Input";
import { FormControlLabel, Switch } from "@material-ui/core";

import { createUser, editUser } from "Api/Users";
import { User } from "declarations";
import toasts from "Shared/toasts";
import {
  StyledModal,
  Content,
  Header,
  Footer,
  Body,
  ButtonSubmit,
  ButtonCancel,
} from "./styles";
import { UserValidation } from "./Validation";

interface FormShape {
  email: string;
  senha: string;
  confirmSenha: string;
  isAdmin: boolean;
}

interface PropCrudUsuarios {
  open: boolean;
  onClose: () => void;
  title: string;
  type: "edit" | "new";
  user?: User;
  onSuccess?: () => void;
}

const CrudUsuarios = ({
  open,
  onClose,
  title,
  user,
  type,
  onSuccess = () => {},
}: PropCrudUsuarios) => {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors, reset, watch, setValue } =
    useForm<FormShape>({
      resolver: yupResolver(UserValidation),
    });
  const watchIsAdmin = watch("isAdmin");

  useEffect(() => {
    user &&
      reset({
        email: user.email,
        isAdmin: !!user.roles.find((value) => value === "admin"),
      });
  }, [user, reset]);

  const onSubmit = handleSubmit(async (data) => {
    if (isLoading) return;
    if (type === "new") {
      try {
        setIsLoading(true);
        await createUser(data.email, data.senha, data.isAdmin);
        toasts.success("Usuário adicionado com sucesso!");
        onClose();
        onSuccess();
      } catch (error) {
        toasts.error((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    } else if (user) {
      try {
        setIsLoading(true);
        // eslint-disable-next-line no-underscore-dangle
        await editUser(user._id, data.email, data.senha, data.isAdmin);
        toasts.success("Usuário editado com sucesso!");
        onClose();
        onSuccess();
      } catch (error) {
        toasts.error((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <StyledModal open={open} onClose={onClose}>
      <Content onSubmit={onSubmit}>
        <Header>{title}</Header>
        <Body>
          <Input
            label="Email"
            type="text"
            name="email"
            errorText={errors.email?.message || ""}
            ref={register()}
          />
          <Input
            label="Senha"
            type="password"
            name="senha"
            errorText={errors.senha?.message || ""}
            ref={register()}
          />
          <Input
            label="Confirme a senha"
            type="password"
            name="confirmSenha"
            errorText={errors.confirmSenha?.message || ""}
            ref={register()}
          />
          <FormControlLabel
            control={
              <Switch
                inputRef={register}
                name="isAdmin"
                id="name"
                checked={watchIsAdmin}
                onChange={(_, checked) => setValue("isAdmin", checked)}
              />
            }
            label="É administrador?"
          />
        </Body>
        <Footer>
          <ButtonSubmit type="submit" isLoading={isLoading}>
            Salvar
          </ButtonSubmit>
          <ButtonCancel onClick={onClose}>Cancelar</ButtonCancel>
        </Footer>
      </Content>
    </StyledModal>
  );
};

export default CrudUsuarios;
