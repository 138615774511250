import { Card, CardActions, PopperPlacementType } from "@material-ui/core";
import styled, { css } from "styled-components";

export const StyledActions = styled(CardActions)`
  display: flex;
  flex-direction: row-reverse;
`;

export const Message = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin-top: 5px;
  color: ${(props) => props.theme.colors.layout.blackSecondary};
`;

export const Arrow = styled.p<{
  placement: "bottom" | "right" | "top" | "left";
}>`
  position: absolute;
  font-size: 7;

  &::before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }

  ${(props) => {
    switch (props.placement) {
      case "bottom":
        return css`
          margin-top: -0.9em;
          width: 3em;
          height: 1em;
          &::before {
            border-width: 0 1em 1em 1em;
            border-color: transparent transparent
              ${props.theme.colors.layout.whitePrimary} transparent;
          }
        `;

      case "left":
        return css`
          right: 0;
          margin-left: -0.3em;
          width: 1em;
          height: 3em;
          &::before {
            border-width: 1em 0 1em 1em;
            border-color: transparent transparent transparent
              ${props.theme.colors.layout.whitePrimary};
          }
        `;
      case "right":
        return css`
          left: 0;
          margin-left: -0.3em;
          width: 1em;
          height: 3em;
          &::before {
            border-width: 1em 1em 1em 0;
            border-color: transparent ${props.theme.colors.layout.whitePrimary}
              transparent transparent;
          }
        `;
      case "top":
        return css`
          bottom: 0;
          left: 0;
          margin-bottom: -0.3em;
          width: 3em;
          height: 1em;
          &::before {
            border-width: 1em 1em 0 1em;
            border-color: ${props.theme.colors.layout.whitePrimary} transparent
              transparent transparent;
          }
        `;

      default:
        return css`
          margin-top: -0.9em;
          width: 3em;
          height: 1em;
          &::before {
            border-width: 0 1em 1em 1em;
            border-color: transparent transparent
              ${props.theme.colors.layout.whitePrimary} transparent;
          }
        `;
    }
  }}
`;

export const StyledCard = styled(Card)`
  margin: 10px;
  max-width: 450px;
`;
