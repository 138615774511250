/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { MandatoryRoute, ResponseMandatoryRoutes } from "declarations";
import feathers from "./Feathers";

const mandatoryRoutesService = feathers.service("mandatory-route");

export const getRoutes = (
  skip: number,
  search?: string
): Promise<ResponseMandatoryRoutes> =>
  mandatoryRoutesService.find({
    query: {
      $limit: 10,
      $skip: skip,
      name: {
        $search: search,
      },
    },
  });

export const postRoute = (newRoute: MandatoryRoute) =>
  mandatoryRoutesService.create(newRoute);

export const updateRoute = (route: MandatoryRoute): Promise<MandatoryRoute> => {
  const id = route._id;

  delete route._id;
  const response = mandatoryRoutesService.update(id, { ...route });

  return response;
};

export const deleteRoute = (id: string) => mandatoryRoutesService.remove(id);
