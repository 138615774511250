import React from "react";
import Header from "Components/Header";
import { Content, ContentWrapper } from "./styles";

interface PageWrapperProps {
  children?: React.ReactNode;
  title: string;
  subtitle?: string;
}
const PageWrapper = ({ children, title, subtitle }: PageWrapperProps) => {
  return (
    <ContentWrapper>
      <Header title={title} subtitle={subtitle} />
      <Content>{children}</Content>
    </ContentWrapper>
  );
};

export default PageWrapper;
