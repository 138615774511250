/* eslint-disable no-underscore-dangle */
import React from "react";
import { Geofence, GeofenceWithMachines } from "declarations";
import { v4 as uuidv4 } from "uuid";
import ItemOrigin from "Components/Map/ForbiddenDestinations/ItemOrigin";
import InfinityScroll from "BaseComponents/InfinityScroll";
import { WrapperList } from "./styles";

interface ListOriginsProps {
  origins: Geofence[];
  loading?: boolean;
  onSelect?: (geofence: Geofence) => void;
  onRemove?: (geofence: Geofence) => void;
  onClick?: (geofence: Geofence) => void;
  onLoadMore?: () => void;
  title?: string;
  position: "Left" | "Right";
  hasNextPage?: boolean;
  className?: string;
}

const ListOrigins = ({
  origins,
  loading = false,
  onSelect,
  onRemove,
  title = "Alvos",
  onClick = () => {},
  onLoadMore = () => {},
  position,
  hasNextPage = false,
  className,
}: ListOriginsProps) => {
  return (
    <WrapperList
      className={className}
      title={title}
      loading={loading}
      position={position}
    >
      <InfinityScroll
        loading={loading}
        onLoadMore={onLoadMore}
        hasNextPage={hasNextPage}
      >
        {origins.map((geofence) => (
          <ItemOrigin
            key={uuidv4()}
            geofence={geofence}
            onClick={onClick}
            onRemove={onRemove}
            onSelect={onSelect}
          />
        ))}
      </InfinityScroll>
    </WrapperList>
  );
};

export default ListOrigins;
