/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from "react";
import {  Filters } from "Pages/Dashboard/styles";
import PageWrapper from "Components/Wrappers/PageWrapper";
import { IFilter } from "Components/TableOptions";
import { Item } from "Components/Filter";
import useSearchGeofences from "Components/TableOptions/SearchGeofences/useSearchGeofences";
import DefaultLayers from "Components/Map/DefaultLayers";
import { FeatureGroup, MapContainer } from "react-leaflet";
import useLiveMap from "Hooks/useLiveMap";
import Geofence from "Components/Map/Geofences/Geofence";
import MarkerTruck from "Components/Map/Marker/MarkerTruck";
import useInterLiveMap from "Hooks/useInternLiveMap";
import MarkerIntern from "Components/Map/Marker/MarkerIntern";
import { center } from "Shared/utils";
import { H3SemiBold } from "BaseComponents/Typographies";
import sharedSentences from "Shared/sentences";
import useMarkerFilter from "Components/Map/Marker/MarkerFilter/useMarkerFiler";
import UserContext from "Contexts/User";
import TextSpinner from 'BaseComponents/Spinners/TextSpinner';
import PinLocation from "Assets/Icons/PinLocation.svg";

import {
  MapMosaicContainer,
  MosaicWrapper,
  EmptyFilterWrapper,
  EmptyFilterText,
} from "./styles";

export const PontosDeInteresse = () => {
  const [isPageIsLoading, setIsPageLoading] = useState(true);
  const { user } = useContext(UserContext);

  const { MarkerFilters, setMarkerFilter, selectedMarkers } = useMarkerFilter({
    user,
  });
  const {
    fetchInternMachines,
    internMachines,
    loading: isLoadingInternMachines,
  } = useInterLiveMap();

  const {
    fetchGeofences,
    loading: isLoadingLiveMapData,
    geofences,
    machines,
    onFilterGeofences,
    onFilterMachines,
  } = useLiveMap();

  useEffect(() => {
    setIsPageLoading(true);
    const fetchData = () => {
      fetchGeofences();
      fetchInternMachines();
      setIsPageLoading(false);
    };

    fetchData();
    const interval = setInterval(() => fetchData(), 10000);

    return () => clearInterval(interval);
  }, [fetchGeofences, fetchInternMachines]);


  const mappedMachines = machines.map((machine) => (
    <MarkerTruck
      key={machine.machineId}
      position={[
        machine.location.coordinates[1],
        machine.location.coordinates[0],
      ]}
      popup
      machine={machine}
      filterMarker={selectedMarkers}
    />
  ));
  const mappedInternMachines = internMachines.map((intern) => (
    <MarkerIntern
      key={intern.machineId}
      position={[
        intern.location.coordinates[1],
        intern.location.coordinates[0],
      ]}
      popup
      filterMarker={selectedMarkers}
      machine={intern}
    />
  ));
  const showGeofences = geofences.map((geofence) => (
    <Geofence key={geofence._id} geofence={geofence} popup />
  ));

  const [filters, setFilters] = useState<IFilter[]>([]);

  const { SearchGeofences, setSearchGeofences, filtersGeofences } =
    useSearchGeofences({
      filters,
      setFilters,
      shouldPersist: true,
    });

  const items: Item[] = [
    {
      label: "Alvos",
      action: () => setSearchGeofences(true),
    },
    {
      label: `Mostrar ${sharedSentences.trucks.toLowerCase()}`,
      action: () => setMarkerFilter(true),
    },
  ];

  const emptyFilters = () => {
    return (
      <EmptyFilterWrapper>
        <img src={PinLocation} width="200px" alt="Buscar trajeto" />
        <EmptyFilterText>
          Utilize o filtro acima para selecionar as geocercas de interesse.
        </EmptyFilterText>
      </EmptyFilterWrapper>
    );
  };

  const filteredGeofences = geofences
    .filter((geofence) => filtersGeofences.includes(geofence.name))
  const mappedGeofences = filteredGeofences.map((geofence) => (
    <MapMosaicContainer
      key={geofence._id}
      pointsOfInterestLenght={filteredGeofences.length}
    >
      <H3SemiBold>{geofence.name}</H3SemiBold>
      <MapContainer
        zoom={13}
        center={center(geofence.coordinates.coordinates)}
        zoomControl={false}
        preferCanvas
        style={{
          minHeight: "250px",
        }}
        worldCopyJump
        wheelDebounceTime={400}
        wheelPxPerZoomLevel={300}
        inertiaMaxSpeed={10}
        trackResize
        rezise
        maxZoom={17}
      >
        <FeatureGroup>
          {mappedMachines}
          {mappedInternMachines}
          {showGeofences}
        </FeatureGroup>
        <DefaultLayers />
        <Geofence geofence={geofence} />{" "}
      </MapContainer>
    </MapMosaicContainer>
  ));

  if (isPageIsLoading) {
    return (
      <PageWrapper title="Pontos de Interesse">
        <Filters itemsFilter={items} />
        {SearchGeofences()}
        {MarkerFilters()}

        <TextSpinner />
      </PageWrapper>
    )
  }

  return (
    <PageWrapper title="Pontos de Interesse">
      <Filters itemsFilter={items} />
      {SearchGeofences()}
      {MarkerFilters()}

      {mappedGeofences && mappedGeofences.length ? (
        <MosaicWrapper>{mappedGeofences}</MosaicWrapper>
      ) : (
        emptyFilters()
      )}
    </PageWrapper>
  );
};
