import styled from "styled-components";
import Button from "BaseComponents/Buttons/PrimaryButton";
import ButtonMachine from "BaseComponents/Buttons/SecondaryButton";

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  z-index: 999;

  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px 60px;
  box-shadow: 0px -3px 6px #0c457d33;
  gap: 20px;
`;

export const StyledButton = styled(Button)`
  width: auto;
  align-items: center;
  max-height: 40px;
  min-width: 60px;
`;

export const StyledMachineButton = styled(ButtonMachine)`
  width: auto;

  align-items: center;

  max-height: 40px;
  min-width: 140px;

  > p {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
`;
