import Table, { Col as ColTable } from "BaseComponents/Table";
import Pagination from "BaseComponents/Table/Pagination";
import TableOptions from "Components/TableOptions";
import PageWrapper from "Components/Wrappers/PageWrapper";
import useCrud from "Hooks/useCrud";
import React, { useCallback, useEffect } from "react";
import toasts from "Shared/toasts";
import { getPlaqueAudition, getPlaqueAuditionCSV } from "Api/PlaqueAuditor";
import sharedSentences from "Shared/sentences";
import { downloadFile } from "Shared/utils";
import { AuditionPlaque } from "declarations";
import { ContentWrapper } from "./styles";

const optionsRowsPerPage = [10, 20, 30];

const fields = [
  [sharedSentences.truck, "plaque"],
  [sharedSentences.client, "client"],
  [sharedSentences.telemetry, "telemetry"],
  ["Data da ultima sincronizacao", "lastLocationDate"],
  ["Status", "status"],
];

const PlaqueAuditor = () => {
  const {
    listCrud,
    setListCrud,
    totalCrud,
    setTotalCrud,
    loading,
    setLoading,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
  } = useCrud<AuditionPlaque>();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getPlaqueAudition(rowsPerPage, page * rowsPerPage);
      setTotalCrud(response.total);
      setListCrud(response.data);
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [setLoading, setListCrud, setTotalCrud, page, rowsPerPage]);

  const fetchDataCSV = async () => {
    try {
      setLoading(true);
      const response = await getPlaqueAuditionCSV(fields);

      downloadFile(response, "AuditoriaPlacas.csv");
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const colsTable: ColTable[] = [
    { name: "plaque", label: sharedSentences.truck, align: "left" },
    { name: "client", label: sharedSentences.client, align: "left" },
    { name: "telemetry", label: sharedSentences.telemetry, align: "left" },
    {
      name: "lastLocationDate",
      label: "Data da última sincronização",
      align: "left",
    },
    {
      name: "status",
      label: "Status",
      align: "left",
    },
  ];

  const handlePage = (pageNumber: number) => {
    setPage(pageNumber - 1);
  };

  const handleRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
  };

  return (
    <PageWrapper title="Auditoria de Placas">
      <ContentWrapper>
        <TableOptions onExportToCSV={fetchDataCSV} />
        <Table loading={loading} cols={colsTable} data={listCrud} />
      </ContentWrapper>
      <Pagination
        count={totalCrud}
        onChangePage={handlePage}
        onChangeRowsPerPage={handleRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        optionsRowsPerPage={optionsRowsPerPage}
      />
    </PageWrapper>
  );
};

export default PlaqueAuditor;
