// @ts-nocheck
import feathers from "@feathersjs/feathers";
import rest from "@feathersjs/rest-client";
import auth from "@feathersjs/authentication-client";
// @ts-ignore
import Fetch from "@feathersjs/rest-client/lib/fetch";

class CustomFetch extends Fetch {
  request(options, params) {
    const fetchOptions = { ...options, ...params.connection };

    fetchOptions.headers = {
      Accept: "application/json",
      ...this.options.headers,
      ...fetchOptions.headers,
    };

    if (options.body) {
      fetchOptions.body = JSON.stringify(options.body);
    }

    const fetch = this.connection;

    return fetch(options.url, fetchOptions)
      .then(this.checkStatus)
      .then((response) => {
        if (response.status === 204) {
          return null;
        }

        if (response.headers.get("Content-type").includes("application/json")) {
          return response.json();
        }

        return response;
      });
  }
}

const app = feathers();
const restClient = rest(process.env.REACT_APP_API_URL);
app.configure(restClient.fetch(window.fetch, CustomFetch));
app.configure(auth());

export default app;
