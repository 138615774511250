import feathers from "Api/Feathers";
import { ResponsePermanence } from "declarations";

const dashboardService = feathers.service("dashboard-data");

interface PermanenceAverageProps {
  geofences: string[];
  dateIn: Date;
  dateOut: Date;
}

interface PermanenceAverageResponse {
  average: number;
}

interface PermanenceAverageByDayResponse {
  day: number;
  month: number;
  year: number;
  avg: number;
}

export const getAveragePermanence = ({
  geofences,
  dateIn,
  dateOut,
}: PermanenceAverageProps): Promise<PermanenceAverageResponse> =>
  dashboardService.find({
    query: {
      dataRequest: "averagePermanence",
      dateIn,
      dateOut,
      geofences,
    },
  });

export const getAveragePermanenceByDay = ({
  geofences,
  dateIn,
  dateOut,
}: PermanenceAverageProps): Promise<PermanenceAverageByDayResponse[]> =>
  dashboardService.find({
    query: {
      dataRequest: "averagePermanenceByDay",
      dateIn,
      dateOut,
      geofences,
    },
  });
