import React from "react";
import ptBr from "date-fns/locale/pt-BR";
import { StyledRange } from "./styles";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

interface DateRangeProps {
  range: any;
  setRange: (value: any) => void;
}

const DateRange = ({ range, setRange }: DateRangeProps) => {
  return (
    <StyledRange
      editableDateInputs={false}
      // @ts-ignore
      onChange={(item) => setRange(item.selection as any)}
      moveRangeOnFirstSelection={true}
      ranges={range}
      months={2}
      direction="horizontal"
      showDateDisplay={false}
      showMonthAndYearPickers={false}
      dayDisplayFormat="dd"
      weekdayDisplayFormat="EEEEE"
      monthDisplayFormat="MMMM yyyy"
      locale={ptBr}
    />
  );
};

export default DateRange;
