import React, { useState } from "react";
import dayjsUtils from "@date-io/dayjs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StyledDateTimePicker } from "./styles";

interface DateTimePickerProps {
  date: Date | null;
  setDate: (value: Date) => void;
  label?: string;
  disabled?: boolean;
  minDate?: Date;
  minDateMessage?: string;
  placeholder?: string;
}

const DateTimePicker = ({
  setDate,
  date,
  label,
  disabled,
  minDate,
  minDateMessage,
  placeholder,
}: DateTimePickerProps) => {
  return (
    <MuiPickersUtilsProvider utils={dayjsUtils}>
      <StyledDateTimePicker
        value={date}
        onChange={(value) => value && setDate(value.toDate())}
        ampm={false}
        variant="inline"
        disabled={disabled}
        minDate={minDate}
        strictCompareDates
        minDateMessage={minDateMessage}
        placeholder={placeholder}
        InputProps={{
          "aria-label": placeholder,
          disableUnderline: true,
        }}
        format="DD [de] MMMM HH:mm"
      />
    </MuiPickersUtilsProvider>
  );
};
export default DateTimePicker;
