import React, { useState, useEffect, useCallback } from "react";
import sharedSentences from "Shared/sentences";
import Search from ".";
import { IFilter } from "..";

interface useSearchTelemetriesProps {
  filters: IFilter[];
  setFilters: React.Dispatch<React.SetStateAction<IFilter[]>>;
}

const useSearchTelemetries = ({
  filters,
  setFilters,
}: useSearchTelemetriesProps) => {
  const [openSearchTelemetries, setSearchTelemetries] = useState(false);
  const [filtersTelemetries, setFiltersTelemetries] = useState<string[]>([]);

  const clearFilters = () => {
    setFiltersTelemetries([]);
  };

  const handleOpen = () => {
    setSearchTelemetries(true);
  };

  const tagTelemetries = useCallback(() => {
    const index = filters.findIndex((value) => value.name === "telemetries");

    if (index === -1) {
      if (filtersTelemetries.length > 0) {
        const filterGeofence: IFilter = {
          label: sharedSentences.telemetries,
          name: "telemetries",
          clear: clearFilters,
          open: handleOpen,
        };

        setFilters([filterGeofence, ...filters]);
      }
    } else if (filtersTelemetries.length === 0) {
      const listFilters = [...filters];
      listFilters.splice(index, 1);
      setFilters(listFilters);
    }
  }, [filtersTelemetries]);

  const SearchTelemetries = () => (
    <Search
      open={openSearchTelemetries}
      onClose={() => setSearchTelemetries(false)}
      onApplyFilters={(value) => setFiltersTelemetries(value)}
    />
  );

  return {
    SearchTelemetries,
    filtersTelemetries,
    tagTelemetries,
    setSearchTelemetries,
    openSearchTelemetries,
  };
};

export default useSearchTelemetries;
