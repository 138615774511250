import { StepperProps } from "@material-ui/core";
import React from "react";
import { CustomStepper } from "./styles";

const Stepper = ({ activeStep, children, alternativeLabel }: StepperProps) => {
  return (
    <CustomStepper activeStep={activeStep} alternativeLabel={alternativeLabel}>
      {children}
    </CustomStepper>
  );
};

export default Stepper;
