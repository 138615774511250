import styled from "styled-components";

export const Wrapper = styled.div<{ visible: boolean }>`
  display: ${(props) => (!props.visible ? "none" : "flex")};
  flex-direction: column;

  border-radius: 18px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 12px;
  background-color: ${(props) => props.theme.colors.layout.whitePrimary};
`;
