import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import Modal from "Components/Modal";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Accordion,
  AccordionDetails,
  Collapse,
  AccordionSummary,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { H4Bold, H4Regular } from "BaseComponents/Typographies";
import Input from "BaseComponents/Inputs/Input";
import Confirmation from "BaseComponents/Popper/Confirmation";
import UserContext from "Contexts/User";
import {
  Wrapper,
  Footer,
  Body,
  Header,
  InfoAccordion,
  WrapperAccordion,
  WrapperInfos,
  ButtonCancel,
  ButtonSubmit,
} from "./styles";
import { RemoveValidation } from "./validation";

export interface IDetails {
  key: string;
  name: string;
  format?: (value: any) => string;
}

export interface FormShape {
  user: string;
  date: Date;
  cause: string;
}

interface RemoveRegisterProps<T> {
  registers?: T[];
  open: boolean;
  onClose: () => void;
  onApply: (data: FormShape) => void;
  details: Array<IDetails>;
  title: string;
  loading?: boolean;
}

const messageConfirm =
  "Confira corretamente os dados, após a confirmação, a ação não poderá ser desfeita!";

const RemoveRegister = <T,>({
  registers = [],
  open,
  onClose,
  details,
  title,
  loading = false,
  onApply,
}: RemoveRegisterProps<T>) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { user } = useContext(UserContext);
  const { register, handleSubmit, errors, setValue, getValues } =
    useForm<FormShape>({
      resolver: yupResolver(RemoveValidation),
    });

  const initializeData = useCallback(() => {
    register("user");
    register("date");

    if (user) {
      setValue("user", user.email);
      setValue("date", new Date());
    }
  }, [user]);

  const getValue = (value: T, key: string) => {
    const arrKeys = key.split(".");

    const returnValue = arrKeys.reduce((prev: any, curr) => {
      return prev[curr];
    }, value);

    return returnValue;
  };

  const getValueDetails = (value: T, detail: IDetails) => {
    const returnValue = getValue(value, detail.key);

    if (detail.format) return detail.format(returnValue);

    return returnValue;
  };

  useEffect(() => {
    initializeData();
  }, [initializeData]);

  const renderRegisters = () => {
    return registers.map((value) => (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          {getValue(value, title)}
        </AccordionSummary>
        <AccordionDetails>
          <WrapperInfos>
            {details.map((detail) => (
              <InfoAccordion>
                <H4Bold>{detail.name}</H4Bold>
                <H4Regular>{` : ${getValueDetails(value, detail)}`}</H4Regular>
              </InfoAccordion>
            ))}
          </WrapperInfos>
        </AccordionDetails>
      </Accordion>
    ));
  };

  const onOpenConfirm = () => {
    setAnchorEl(buttonRef.current);
  };

  const onCloseConfirm = () => {
    setAnchorEl(null);
  };

  const openConfirm = Boolean(anchorEl);

  const submit = handleSubmit(async (data, e) => {
    if (loading) return;

    if (buttonRef.current) onOpenConfirm();
  });

  const applyData = () => {
    onCloseConfirm();
    setValue("date", new Date());
    onApply(getValues());
  };

  const onCloseModal = () => {
    onCloseConfirm();
    onClose();
  };

  return (
    <Modal open={open} onClose={onCloseModal}>
      <Wrapper onSubmit={submit}>
        <Header>Remover registros</Header>
        <Body>
          <H4Bold>
            Você tem certeza que deseja remover os registros selecionados?
          </H4Bold>
          <WrapperAccordion>{renderRegisters()}</WrapperAccordion>
          <Input
            type="text"
            label="Digite o motivo da remoção"
            name="cause"
            errorText={errors.cause?.message || ""}
            ref={register()}
          />
        </Body>
        <Footer>
          <ButtonSubmit ref={buttonRef} type="submit">
            Aplicar
          </ButtonSubmit>
          <ButtonCancel onClick={onCloseModal}>Cancelar</ButtonCancel>
          <Confirmation
            open={openConfirm}
            anchorEl={anchorEl}
            onCancel={onCloseConfirm}
            onConfirm={applyData}
            message={messageConfirm}
          />
        </Footer>
      </Wrapper>
    </Modal>
  );
};

export default RemoveRegister;
