import { ResponseUsers } from "declarations";
import feathers from "Api/Feathers";

interface ISort {
  field: string;
  order: -1 | 1;
}

export const getUsers = (
  limit: number,
  skip: number,
  sort?: ISort,
  filter?: string[],
  search?: string
): Promise<ResponseUsers> =>
  feathers.service("users").find({
    query: {
      $limit: limit,
      $skip: skip,
      $sort: sort && {
        [sort.field]: sort.order,
      },
      roles: {
        $in: filter,
      },
      email: {
        $search: search,
      },
    },
  });

export const createUser = (
  email: string,
  password: string,
  isAdmin: boolean = false
): Promise<void> =>
  feathers
    .service("users")
    .create({ email, password, roles: [isAdmin ? "admin" : "regularUser"] });

export const editUser = (
  id: string,
  email: string,
  password: string,
  isAdmin: boolean = false
): Promise<void> =>
  feathers
    .service("users")
    .update(
      id,
      { email, password, roles: [isAdmin ? "admin" : "regularUser"] },
      {}
    );

export const deleteUser = (id: string): Promise<void> =>
  feathers.service("users").remove(id);

export const deleteManyUsers = (ids: string[]): Promise<void> =>
  feathers.service("users").remove(null, { query: { _id: { $in: ids } } });
