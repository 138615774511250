import React from "react";
import dayjs from "dayjs";

import {
  Wrapper,
  Label,
  StyledAvatar,
  WrapperLabels,
  Title,
  SubTitle,
} from "./styles";

interface ItemInternMachineProps {
  title: string;
  status?: string | null;
  statusDate?: string | null;
  statusReason?: string | null;
  statusReasonDate?: string | null;
  onClick?: () => void;
}

const ItemInternMachine = ({
  title,
  status,
  statusDate,
  statusReason,
  statusReasonDate,
  onClick = () => {},
}: ItemInternMachineProps) => {
  const fullStatus = `${status} ${statusReason}`;

  const isUnavailable =
    fullStatus?.includes("INDISPONÍVEL") ||
    fullStatus?.includes("INDISPONIVEL") ||
    fullStatus?.includes("INATIVO") ||
    fullStatus?.includes("INOPERANTE") ||
    fullStatus?.includes("PARADO") ||
    fullStatus?.includes("PARADA") ||
    false;

  const isAvailable =
    fullStatus?.includes("DISPONÍVEL") ||
    fullStatus?.includes("DISPONIVEL") ||
    fullStatus?.includes("ATIVO") ||
    fullStatus?.includes("OPERANTE") ||
    fullStatus?.includes("EM OPERAÇÃO") ||
    fullStatus?.includes("EM OPERACAO") ||
    false;

  let machineStatus: "available" | "unavailable" | "none" = "none";
  if (isAvailable) machineStatus = "available";
  if (isUnavailable) machineStatus = "unavailable";

  return (
    <Wrapper
      onClick={(e) => {
        onClick();
      }}
      onDoubleClickCapture={(e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.preventDefault();
        e.nativeEvent.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      <StyledAvatar status={machineStatus}>{title[0]}</StyledAvatar>
      <WrapperLabels>
        <Title status={machineStatus}>{title}</Title>
        <SubTitle>Status: {status || "-"}</SubTitle>
        <SubTitle>
          Data do status:{" "}
          {statusDate ? dayjs(statusDate).format("DD/MM/YYYY [às] HH:mm") : "-"}
        </SubTitle>
        <SubTitle>Justificativa: {statusReason || "-"}</SubTitle>
        <SubTitle>
          Data da justificativa:{" "}
          {statusReasonDate
            ? dayjs(statusReasonDate).format("DD/MM/YYYY [às] HH:mm")
            : "-"}
        </SubTitle>
      </WrapperLabels>
    </Wrapper>
  );
};

export default ItemInternMachine;
