import styled from "styled-components";

export const MenuItemWrapper = styled.div<{ active: string }>`
  ${(props) =>
    !props.active
      ? `display: flex;
  justify-content: center;
  align-items: center;`
      : ""}
`;

export const Bar = styled.div<{ active: string }>`
  width: 30px;
  height: 4px;
  background-color: ${(props) =>
    props.active === "open"
      ? props.theme.colors.layout.whitePrimary
      : props.theme.colors.layout.blackPrimary};
  margin: 6px 0;
`;
