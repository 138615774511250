/* eslint-disable no-underscore-dangle */
import Table, {
  Col as ColTable,
  Option as OptionTable,
} from "BaseComponents/Table";
import Pagination from "BaseComponents/Table/Pagination";
import { getExceedSpeed } from "Api/ExceedSpeed";
import TableOptions, { ICheck } from "Components/TableOptions";
import PageWrapper from "Components/Wrappers/PageWrapper";
import { Permanence } from "declarations";
import useCrud from "Hooks/useCrud";
import React, { useCallback, useEffect } from "react";
import toasts from "Shared/toasts";
import useSearchGeofences from "Components/TableOptions/SearchGeofences/useSearchGeofences";
import useSearchMachines from "Components/TableOptions/SearchMachines/useSearchMachines";
import useSearchDate from "Components/TableOptions/SearchDate/useSearchDate";
import { getPermanence, getPermanenceCSV } from "Api/Permanence";
import { downloadFile, fromMilliseconds } from "Shared/utils";
import useSearchClients from "Components/TableOptions/SearchClients/useSearchClients";
import sharedSentences from "Shared/sentences";
import useTruckPath from "Components/Map/TruckPath/useTruckPath";
import {
  DeleteOutline,
  FilterCenterFocusTwoTone,
  MapOutlined,
} from "@material-ui/icons";
import useRemoveRegister from "Components/RemoveRegister/useRemoveRegister";
import dayjs from "dayjs";
import theme from "Styles/theme";
import { DateRangeType } from "Components/TableOptions/SearchDate";
import { ContentWrapper } from "./styles";

const fields = [
  [sharedSentences.client, "machine.client"],
  [sharedSentences.telemetry, "machine.telemetry"],
  [sharedSentences.truck, "machine.plaque"],
  ["Alvo", "geofence.name"],
  ["Data de Entrada", "dateIn"],
  ["Data de Saída", "dateOut"],
  ["Tempo de Permanência", "dateDifference", "timestamp"],
];

const formatDifference = (value: any) => {
  return fromMilliseconds(value);
};

const optionsRowsPerPage = [10, 20, 30];

const Permanencia = () => {
  const {
    listCrud,
    setListCrud,
    totalCrud,
    setTotalCrud,
    loading,
    setLoading,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    filters,
    setFilters,
    showRemoved,
    setShowRemoved,
  } = useCrud<Permanence>();

  const { TruckPathModal, onSearchOnModal } = useTruckPath({});

  const { SearchGeofences, filtersGeofences, tagGeofence, setSearchGeofences } =
    useSearchGeofences({ filters, setFilters });

  const {
    SearchMachines,
    filtersMachines,
    setSearchMachines,
    tagMachine,
    clearFilters,
  } = useSearchMachines({ filters, setFilters });

  const { SearchClients, filtersClients, setSearchClients, tagClients } =
    useSearchClients({ filters, setFilters });

  const DateIn = useSearchDate({
    filters,
    setFilters,
    name: "dateIn",
  });

  const DateOut = useSearchDate({
    filters,
    setFilters,
    name: "dateOut",
    defaultRange: {
      startDate: dayjs(new Date()).subtract(1, "week").startOf("day").toDate(),
      endDate: dayjs(new Date()).endOf("day").toDate(),
      color: theme.colors.interactive.primary,
      key: "selection",
      showDateDisplay: false,
    },
  });

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const rangeIn = DateIn.dateRange
        ? [
            DateIn.dateRange.startDate.toISOString(),
            DateIn.dateRange.endDate.toISOString(),
          ]
        : undefined;
      const rangeOut = DateOut.dateRange
        ? [
            DateOut.dateRange.startDate.toISOString(),
            DateOut.dateRange.endDate.toISOString(),
          ]
        : undefined;
      const response = await getPermanence({
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        plaques: filtersMachines,
        clients: filtersClients,
        geofences: filtersGeofences,
        dateIn: rangeIn,
        dateOut: rangeOut,
        showRemoved,
      });
      setListCrud(response.data);
      setTotalCrud(response.total);
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    DateIn.dateRange,
    DateOut.dateRange,
    rowsPerPage,
    page,
    filtersGeofences,
    filtersMachines,
    filtersClients,
    setListCrud,
    setTotalCrud,
    showRemoved,
  ]);

  const { ModalRemoveRegister, removeRegisters } =
    useRemoveRegister<Permanence>({
      details: [
        {
          key: "geofence.name",
          name: "Alvo",
        },
        { key: "machine.client", name: sharedSentences.client },
        {
          key: "machine.telemetry",
          name: sharedSentences.telemetry,
        },
        {
          key: "dateDifference",
          name: "Tempo de permanência",
          format: formatDifference,
        },
      ],
      title: "machine.name",
      idKey: "_id",
      removeFunction: "Permanence",
      onRemoved: fetchData,
    });

  const fetchDataCSV = async () => {
    try {
      setLoading(true);

      const rangeIn = DateIn.dateRange
        ? [
            DateIn.dateRange.startDate.toISOString(),
            DateIn.dateRange.endDate.toISOString(),
          ]
        : undefined;
      const rangeOut = DateOut.dateRange
        ? [
            DateOut.dateRange.startDate.toISOString(),
            DateOut.dateRange.endDate.toISOString(),
          ]
        : undefined;

      toasts.success(
        "Baixando arquivo CSV...Essa operação pode demorar um pouco."
      );

      const response = await getPermanenceCSV(
        fields,
        rowsPerPage,
        page * rowsPerPage,
        filtersMachines,
        filtersGeofences,
        filtersClients,
        rangeIn,
        rangeOut
      );

      downloadFile(response, "Permanencia.csv");
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    tagMachine();
  }, [tagMachine]);

  useEffect(() => {
    tagGeofence();
  }, [tagGeofence]);

  useEffect(() => {
    DateIn.tagDate();
  }, [DateIn.tagDate]);

  useEffect(() => {
    DateOut.tagDate();
  }, [DateOut.tagDate]);

  useEffect(() => {
    tagClients();
  }, [filtersClients, tagClients]);

  const colsTable: ColTable[] = [
    { name: "machine.client", label: sharedSentences.client, align: "left" },
    {
      name: "machine.telemetry",
      label: sharedSentences.telemetry,
      align: "left",
    },
    { name: "machine.name", label: sharedSentences.truck, align: "left" },
    { name: "geofence.name", label: "Alvo", align: "left" },
    { name: "dateIn", label: "Data de entrada", align: "left" },
    { name: "dateOut", label: "Data de saída", align: "left" },
    {
      name: "dateDifference",
      label: "Tempo de permanência",
      align: "left",
      format: formatDifference,
    },
    {
      name: "removed",
      label: "Status",
      align: "left",
      removed: true,
    },
  ];

  const itemsFilter = [
    {
      label: sharedSentences.trucks,
      action: () => setSearchMachines(true),
    },
    {
      label: "Alvos",
      action: () => setSearchGeofences(true),
    },
    {
      label: "Data de Entrada",
      action: () => DateIn.setSearchDate(true),
    },
    {
      label: "Data de Saída",
      action: () => DateOut.setSearchDate(true),
    },
    {
      label: sharedSentences.clients,
      action: () => setSearchClients(true),
    },
  ];

  const searchPath = async (data: any) => {
    const dateIn = new Date(data.dateIn);
    const dateEnd = new Date(data.dateOut);
    await onSearchOnModal(data.machine.plaque, dateIn, dateEnd);
  };

  const optionsTable: OptionTable[] = [
    {
      label: "Ver trajeto",
      action: (itemData) => searchPath(itemData),
      Icon: <MapOutlined />,
    },
    {
      label: "Remover registro",
      action: (itemData) => removeRegisters([itemData as Permanence]),
      Icon: <DeleteOutline />,
    },
  ];

  const handlePage = (pageNumber: number) => {
    setPage(pageNumber - 1);
  };

  const handleRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
  };

  const removeMany = (listToRemove: Permanence[]) => {
    removeRegisters([...listToRemove]);
  };

  const itensCheck: ICheck[] = [
    {
      check: showRemoved,
      label: "Mostrar registros removidos",
      onCheck: (check) => setShowRemoved(check),
    },
  ];

  return (
    <PageWrapper title="Relatório de Permanência">
      <ContentWrapper>
        <TableOptions
          itemsCheck={itensCheck}
          itemsFilter={itemsFilter}
          filtersTags={filters}
          onExportToCSV={fetchDataCSV}
        />
        <Table<Permanence>
          loading={loading}
          cols={colsTable}
          data={listCrud}
          options={optionsTable}
          onDeleteAll={removeMany}
        />
      </ContentWrapper>
      <Pagination
        count={totalCrud}
        onChangePage={handlePage}
        onChangeRowsPerPage={handleRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        optionsRowsPerPage={optionsRowsPerPage}
      />
      {DateIn.SearchDateRange()}
      {DateOut.SearchDateRange()}
      {SearchMachines()}
      {SearchGeofences()}
      {SearchClients()}
      {TruckPathModal()}
      {ModalRemoveRegister()}
    </PageWrapper>
  );
};

export default Permanencia;
