import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import {
  H2Bold,
  H3SemiBold,
  H4Bold,
  H4SemiBold,
  H5Bold,
  H5Regular,
  H5SemiBold,
} from "BaseComponents/Typographies";

export const StyledAvatar = styled(Avatar)`
  width: 40px !important;
  height: 40px !important;
`;

export const Wrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 2px 0;
  background: none;
  border: none;
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
  transition: background-color 400ms;
  position: relative;
  &:focus {
    outline: none;
  }
  &:active,
  &:hover {
    background: ${(props) => props.theme.colors.layout.whiteTertiary};
  }
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform-style: flat;
    transform: translate3d(-50%, -50%, 0);
    background: #ffffff1a;
    border-radius: 100%;
    transition: width 0.3s ease, height 0.3s ease;
  }
  &:active {
    &:after {
      width: 200px;
      height: 200px;
    }
  }
`;

export const Label = styled.p`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  font-size: 14px;
`;

export const WrapperNumTrips = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Qtd = styled(H2Bold)`
  color: ${(props) => props.theme.colors.interactive.secondary};
  margin: 0 5px;
`;

export const LabelQtd = styled(H5Bold)`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
`;
export const WrapperAverage = styled.div`
  margin-bottom: 1px;
`;

export const LabelAverage = styled(H4Bold)`
  color: ${(props) => props.theme.colors.interactive.secondary};
`;

export const WrapperTrip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export const LabelGeofence = styled(H3SemiBold)`
  width: 40%;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
`;
