import styled from "styled-components";
import { Popup } from "react-leaflet";
import { H4Regular, H4SemiBold, H5SemiBold } from "BaseComponents/Typographies";

export const Container = styled.div``;

export const PopupMarker = styled(Popup)`
  .leaflet-popup-content {
    margin: 0;
    padding: 12px;
    display: flex;
    flex-direction: column;
  }
`;

export const TitleWrapper = styled.div`
  border-bottom: 1px solid #f2f4f7;
  padding-bottom: 12px;
`;

export const Title = styled.span`
  text-align: left;
  font: normal normal bold 14px Open Sans;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  opacity: 1;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin: 5px 5px 5px 0;
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TitleInfo = styled(H4SemiBold)`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  margin-left: 5px;
`;
export const Info = styled(H4Regular)`
  margin-left: 5px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
`;
