/* eslint-disable no-underscore-dangle */
import { ClientInfo } from "declarations";
import feathers from "./Feathers";

const clientsSevice = feathers.service("clients");

export const getClients = (search: string): Promise<ClientInfo[]> =>
  clientsSevice.find({
    query: {
      name: search,
    },
  });
