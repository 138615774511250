import styled from "styled-components";

export const Button = styled.button`
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border-radius: 6px;
  padding: 0 6px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.layout.whitePrimary};
  font-weight: 600;

  &:hover {
    background: ${(props) => props.theme.colors.layout.whitePrimary};
    color: ${(props) => props.theme.colors.layout.blackPrimary};
  }
`;
