/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import {
  ForbiddenDestination,
  ResponseForbiddenDestination,
} from "declarations";
import feathers from "./Feathers";

const forbiddenDestinationService = feathers.service("forbidden-destination");

export const getForbidden = (
  skip: number,
  search?: string
): Promise<ResponseForbiddenDestination> =>
  forbiddenDestinationService.find({
    query: {
      $limit: 10,
      $skip: skip,
      name: {
        $search: search,
      },
    },
  });

export const postForbidden = (newForbidden: ForbiddenDestination) =>
  forbiddenDestinationService.create(newForbidden);

export const updateForbidden = (
  forbidden: ForbiddenDestination
): Promise<ForbiddenDestination> => {
  const id = forbidden._id;

  delete forbidden._id;
  const response = forbiddenDestinationService.update(id, { ...forbidden });

  return response;
};

export const deleteForbidden = (id: string) =>
  forbiddenDestinationService.remove(id);
