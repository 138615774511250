import { LatLngExpression } from "leaflet";
import React, { useState, useEffect, useCallback } from "react";
import { getMachineLocations } from "Api/MachineLocations";
import toasts from "Shared/toasts";
import { MachineLocation } from "declarations";
import Modal from "Components/Modal";
import sharedSentences from "Shared/sentences";
import { WrapperMap } from "./styles";
import TruckPathScreen from "./index";

interface useTruckPathProps {}

const useTruckPath = ({}: useTruckPathProps) => {
  const [openTruckPath, setModalTruckPath] = useState(false);
  const [selected, setSelected] = useState(0);
  const [dateIn, setDateIn] = useState<Date | null>(null);
  const [dateEnd, setDateEnd] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);
  const [plaque, setPlaque] = useState<string | null>(null);
  const [path, setPath] = useState<MachineLocation[]>([]);

  const fetchPostions = useCallback(async () => {
    if (plaque && dateIn && dateEnd) {
      try {
        setLoading(true);

        let skip = 0;
        let total = 0;
        let arrayLocations: MachineLocation[] = [];

        do {
          // eslint-disable-next-line no-await-in-loop
          const response = await getMachineLocations(
            skip,
            plaque,
            dateIn,
            dateEnd
          );

          arrayLocations = [...arrayLocations, ...response.data];

          total = response.total;
          skip = arrayLocations.length;
          setPath(arrayLocations);
        } while (skip < total);
      } catch (error) {
        toasts.error(
          `Erro ao carregar posições da ${sharedSentences.truck} nesse intervalo de tempo!`
        );
      } finally {
        setLoading(false);
      }
    }
  }, [dateEnd, dateIn, plaque]);

  useEffect(() => {
    fetchPostions();
  }, []);

  const setCloseTruckPath = () => {
    setModalTruckPath(false);
    setSelected(0);
    setPlaque(null);
    setDateIn(null);
    setDateEnd(null);
  };

  const setOpenTruckPath = () => {
    setModalTruckPath(true);
  };

  const onChangeSelected = (value: number) => {
    setSelected(value);
  };

  const onSearchDateRange = async () => {
    setSelected(0);
    await fetchPostions();
  };

  const onSearchOnModal = async (
    plaqueMachine: string,
    dateInitial: Date,
    dateFinal: Date
  ) => {
    setSelected(0);
    setPlaque(plaqueMachine);
    setDateIn(dateInitial);
    setDateEnd(dateFinal);
    setOpenTruckPath();
    await fetchPostions();
  };

  const onSearch = async (
    plaqueMachine: string,
    dateInitial: Date,
    dateFinal: Date
  ) => {
    setSelected(0);
    setPlaque(plaqueMachine);
    setDateIn(dateInitial);
    setDateEnd(dateFinal);
    await fetchPostions();
  };

  const onNext = () => {
    if (selected < path.length - 1) setSelected((prev) => prev + 1);
  };

  const onPrev = () => {
    if (selected > 0) onChangeSelected(selected - 1);
  };

  const getBeginHour = (): Date | undefined => {
    if (path[selected]) {
      return path[selected].locationDate;
    }

    return undefined;
  };

  const TruckPath = () => (
    <TruckPathScreen
      loading={loading}
      zoom={13}
      center={[-20.54213711944774, -43.74270918881854]}
      path={path}
      selected={selected}
      onChangeSelected={onChangeSelected}
      dateEnd={dateEnd}
      dateIn={dateIn}
      setDateIn={setDateIn}
      setDateEnd={setDateEnd}
      onSearch={onSearchDateRange}
      onNext={onNext}
      onPrev={onPrev}
      setMachine={setPlaque}
      machine={plaque || undefined}
      beginHour={getBeginHour()}
    />
  );

  const TruckPathModal = () => (
    <Modal open={openTruckPath} onClose={setCloseTruckPath}>
      <WrapperMap>{TruckPath()}</WrapperMap>
    </Modal>
  );

  return {
    TruckPath,
    TruckPathModal,
    openTruckPath,
    setCloseTruckPath,
    setOpenTruckPath,
    onSearch,
    onSearchOnModal,
  };
};

export default useTruckPath;
