import { FormControlLabel } from "@material-ui/core";
import styled from "styled-components";

export const StyledForm = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 16px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.layout.blackPrimary};
  }
`;
