import Table, {
  Col as ColTable,
  Option as OptionTable,
} from "BaseComponents/Table";
import Pagination from "BaseComponents/Table/Pagination";
import TableOptions from "Components/TableOptions";
import PageWrapper from "Components/Wrappers/PageWrapper";
import { Machine } from "declarations";
import useCrud from "Hooks/useCrud";
import React, { useCallback, useEffect } from "react";
import toasts from "Shared/toasts";
import useSearchMachines from "Components/TableOptions/SearchMachines/useSearchMachines";
import { downloadFile } from "Shared/utils";
import useSearchClients from "Components/TableOptions/SearchClients/useSearchClients";
import sharedSentences from "Shared/sentences";
import { getMachines, getMachinesCSV } from "Api/MachinesData";
import { ContentWrapper } from "./styles";

const fields = [
  [sharedSentences.client, "client"],
  [sharedSentences.telemetry, "telemetry"],
  [sharedSentences.truck, "plaque"],
  ["Ultima Data no raio", "locationDate"],
];

const optionsRowsPerPage = [10, 20, 30];

const Maquinas = () => {
  const {
    listCrud,
    setListCrud,
    totalCrud,
    setTotalCrud,
    loading,
    setLoading,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    filters,
    setFilters,
  } = useCrud<Machine>();

  const {
    SearchMachines,
    filtersMachines,
    setSearchMachines,
    tagMachine,
    clearFilters,
  } = useSearchMachines({ filters, setFilters });

  const { SearchClients, filtersClients, setSearchClients, tagClients } =
    useSearchClients({ filters, setFilters });

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const response = await getMachines(
        rowsPerPage,
        page * rowsPerPage,
        filtersMachines,
        filtersClients
      );
      setListCrud(response.data);
      setTotalCrud(response.total);
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    rowsPerPage,
    page,
    filtersMachines,
    filtersClients,
    setListCrud,
    setTotalCrud,
  ]);

  const fetchDataCSV = async () => {
    try {
      setLoading(true);

      const response = await getMachinesCSV(
        fields,
        rowsPerPage,
        page * rowsPerPage,
        filtersMachines,
        filtersClients
      );

      downloadFile(response, "Placas.csv");
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    tagMachine();
  }, [tagMachine]);

  useEffect(() => {
    tagClients();
  }, [filtersClients, tagClients]);

  const colsTable: ColTable[] = [
    { name: "client", label: sharedSentences.client, align: "left" },
    {
      name: "telemetry",
      label: sharedSentences.telemetry,
      align: "left",
    },
    { name: "name", label: sharedSentences.truck, align: "left" },
    { name: "locationDate", label: "Ultima data no raio", align: "left" },
  ];

  const itemsFilter = [
    {
      label: sharedSentences.trucks,
      action: () => setSearchMachines(true),
    },
    {
      label: sharedSentences.clients,
      action: () => setSearchClients(true),
    },
  ];

  const handlePage = (pageNumber: number) => {
    setPage(pageNumber - 1);
  };

  const handleRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
  };

  return (
    <PageWrapper title="Relatório de Placas">
      <ContentWrapper>
        <TableOptions
          itemsFilter={itemsFilter}
          filtersTags={filters}
          onExportToCSV={fetchDataCSV}
        />
        <Table<Machine> loading={loading} cols={colsTable} data={listCrud} />
      </ContentWrapper>
      <Pagination
        count={totalCrud}
        onChangePage={handlePage}
        onChangeRowsPerPage={handleRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        optionsRowsPerPage={optionsRowsPerPage}
      />
      {SearchMachines()}
      {SearchClients()}
    </PageWrapper>
  );
};

export default Maquinas;
