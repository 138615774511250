import { LocalShipping, TimerOutlined } from "@material-ui/icons";
import { getAveragePermanence } from "Api/DashboardData";
import { getPermanence } from "Api/Permanence";
import { getTrips } from "Api/Trip";
import { H3SemiBold, H5SemiBold } from "BaseComponents/Typographies";
import dayjs from "dayjs";
import { TripInfo, Unit } from "declarations";
import React, { useState, useCallback, useEffect } from "react";
import toasts from "Shared/toasts";
import { fromMillisecondsToHour } from "Shared/utils";
import theme from "Styles/theme";
import DefaultWrapper from "../DefaultWrapper";
import { LineWrapper, InfoLabel, WrapperInfo } from "./styles";

const ONE_MINUTE = 60 * 1000;

interface ShiftBoardProps {
  name: string;
  beg: Date;
  end: Date;
  unit: Unit;
  timeToUpdate?: number;
}

const ShiftBoard = ({
  name,
  beg,
  end,
  unit,
  timeToUpdate = ONE_MINUTE,
}: ShiftBoardProps) => {
  const [visible, setVisible] = useState(false);
  const [averagePermanence, setAveragePermanence] = useState<null | number>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [numTrips, setNumTrips] = useState(0);

  const labelTime = averagePermanence
    ? fromMillisecondsToHour(averagePermanence)
    : "-";

  const calcNumTrips = (trips: TripInfo[]) => {
    const count = trips.reduce((prev, curr) => prev + curr.qtd, 0);
    setNumTrips(count);
  };

  const fetchPermanence = async (rangeDate: Date[], geofences: string[]) => {
    try {
      setLoading(true);

      // eslint-disable-next-line no-await-in-loop
      const response = await getAveragePermanence({
        geofences,
        dateIn: rangeDate[0],
        dateOut: rangeDate[1],
      });

      setAveragePermanence(response.average);
    } catch (error) {
      toasts.error(`Erro ao buscar informações do quadro '${name}`);
    } finally {
      setLoading(false);
    }
  };

  const processData = useCallback(async () => {
    const newBeg = dayjs(beg);
    const newEnd = dayjs(end);

    const afterBeg = dayjs().isAfter(newBeg);
    const afterEnd = dayjs().isAfter(newEnd);

    setVisible(afterBeg);
    const rangeDate = [
      newBeg.toDate().toISOString(),
      newEnd.toDate().toISOString(),
    ];

    const geofences = [unit.owner.name];

    if (!averagePermanence) {
      await fetchPermanence([newBeg.toDate(), newEnd.toDate()], geofences);
    } else if (!afterEnd && afterBeg) {
      await fetchPermanence([newBeg.toDate(), newEnd.toDate()], geofences);
    }
  }, [beg, unit]);

  useEffect(() => {
    processData();
    const interval = setInterval(() => processData(), timeToUpdate);

    return () => clearInterval(interval);
  }, [processData]);

  return (
    <DefaultWrapper
      visible={visible}
      style={{ width: "30%", minWidth: "140px" }}
    >
      <H5SemiBold>{name}</H5SemiBold>
      <LineWrapper>
        <InfoLabel>{labelTime}</InfoLabel>{" "}
        <TimerOutlined htmlColor={theme.colors.layout.blackPrimary} />
      </LineWrapper>
      <LineWrapper />
    </DefaultWrapper>
  );
};

export default ShiftBoard;
