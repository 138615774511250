import React from "react";

import { CancelRounded } from "@material-ui/icons";
import theme from "Styles/theme";
import { Button, Content, TextButton } from "./styles";

interface TagProps {
  label: string;
  onClose?: () => void;
  onClick?: () => void;
}

const Tag = ({ label, onClick = () => {}, onClose = () => {} }: TagProps) => {
  const handleClick = (e: any) => {
    e.stopPropagation();
    onClick();
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <Content onClick={handleClick}>
      <TextButton>{label}</TextButton>
      <Button onClick={handleClose}>
        <CancelRounded htmlColor={theme.colors.interactive.primary} />
      </Button>
    </Content>
  );
};

export default Tag;
