import styled from "styled-components";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { Select } from "@material-ui/core";

export const StyledPagination = styled(Pagination)`
  margin-top: 3px;
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  margin-right: 10px;
`;

export const StyledPaginationItem = styled(PaginationItem)``;

export const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  background-color: white;
  height: 30px;
  width: 70px;
  margin: 0 10px;
  font-size: 14px !important;
  font-weight: bold !important;
  color: ${(props) => props.theme.colors.layout.blackPrimary};

  > fieldset {
    border: 1px solid ${(props) => props.theme.colors.layout.disabled};
    border-radius: 8px;
  }

  > svg {
    fill: ${(props) => props.theme.colors.layout.blackPrimary};
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props.theme.colors.layout.blackPrimary} !important;
  }
`;
