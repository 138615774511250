import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MarkerProps, Marker, useMap } from "react-leaflet";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ptBr from "dayjs/locale/pt-br";
import { diffTimeToString } from "Shared/utils";
import { InternMachine, MachineWithGeoInfo } from "declarations";
import { LatLngExpression } from "leaflet";
import sharedSentences from "Shared/sentences";
import IconIntern from "./IconIntern";
import PopupMachine from "../PopupMachine";

dayjs.extend(relativeTime);
dayjs.locale(ptBr);

interface MarkerInternProps extends MarkerProps {
  popup?: boolean;
  machine: InternMachine;
  position: LatLngExpression;
  subtitleOption?: string;
  filterMarker?: string[];
}

const MarkerIntern = ({
  popup,
  machine,
  position,
  subtitleOption = "Tranportadora",
  filterMarker = [],
}: MarkerInternProps) => {
  const map = useMap();
  const handleClick = () => {
    map.flyTo(position);
  };

  const lat = position.toString().split(",")[0];
  const lng = position.toString().split(",")[1];

  const title = useMemo(() => {
    switch (subtitleOption) {
      case sharedSentences.client: {
        const transport = machine.client
          ? machine.client.split(" ")
          : undefined;
        return !transport
          ? machine.telemetry
          : transport
              .filter((value, index) => index < 2)
              .toString()
              .replace(",", " ");
      }
      case sharedSentences.telemetry: {
        return machine.telemetry;
      }
      case sharedSentences.truck: {
        return machine.plaque;
      }
      case "Nenhum": {
        return undefined;
      }
      default:
        return machine.telemetry;
    }
  }, [machine.client, machine.plaque, machine.telemetry, subtitleOption]);

  const icon = useMemo(() => {
    return IconIntern(machine.type, title);
  }, [machine.type, title]);

  const showMarker = useMemo(() => {
    if (filterMarker.length === 0) return true;

    if (!filterMarker.includes(sharedSentences.markerSituations.internMachines))
      return false;

    return true;
  }, [filterMarker]);

  return showMarker ? (
    <Marker
      position={position}
      title={title}
      icon={icon}
      eventHandlers={{ click: handleClick }}
    >
      {popup && (
        <PopupMachine
          latlngLabel={`Lat: ${lat} / Lng: ${lng}`}
          telemetry={machine.telemetry}
          title={machine.plaque}
          lastUpdate={machine.locationDate}
          client={machine.client}
        />
      )}
    </Marker>
  ) : null;
};

export default MarkerIntern;
