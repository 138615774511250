import { getLiveMap } from "Api/LiveMap";
import { getSettings } from "Api/Settings";
import {
  GeofenceWithMachines,
  Machine,
  MachineWithGeoInfo,
  Settings,
} from "declarations";
import { useCallback, useState } from "react";
import toasts from "Shared/toasts";

const useLiveMap = () => {
  const [geofences, setGeofences] = useState<GeofenceWithMachines[]>([]);
  const [machines, setMachines] = useState<MachineWithGeoInfo[]>([]);
  const [settings, setSettings] = useState<Settings>();
  const [loading, setLoading] = useState(false);

  const allGeofenceNames = geofences.map((value) => value.name);

  const fetchGeofences = useCallback(async () => {
    if (!loading) {
      try {
        setLoading(true);

        const responseSettings = await getSettings();

        const settingsAux =
          responseSettings.data[responseSettings.data.length - 1];

        setSettings(settingsAux);

        const response = await getLiveMap();

        const newGeofences: GeofenceWithMachines[] = [];
        let newMachines: MachineWithGeoInfo[] = [];

        const geofenceNames = Object.keys(response);
        geofenceNames.forEach((geofenceName) => {
          let numMachinesOnAverage: number;

          if (geofenceName !== "no-geofence") {
            numMachinesOnAverage = response[geofenceName].machines.length;

            let average = response[geofenceName].machines
              ?.map((machine, index) => {
                const diffLocationDate =
                  new Date().getTime() -
                  new Date(machine.locationDate).getTime();

                if (diffLocationDate >= settingsAux.maxUpdateTime) {
                  numMachinesOnAverage -= 1;
                  return 0;
                }

                if (machine.dateIn) {
                  const timeIn = new Date(machine.dateIn).getTime();
                  return timeIn;
                }
                return 0;
              })
              .reduce((prev, next) => prev + next, 0);

            if (average) average /= numMachinesOnAverage || 0;

            newGeofences.push({
              ...response[geofenceName],
              averageTimeMachineNow: average,
            });
          }

          if (response[geofenceName]?.machines?.length) {
            const geofenceMachines = response[geofenceName].machines;

            const newGeofeceMachines: MachineWithGeoInfo[] = geofenceMachines
              .map((machine) => {
                const id = newMachines.findIndex(
                  (newMachine) => newMachine.machineId === machine.machineId
                );

                const diffLocationDate =
                  new Date().getTime() -
                  new Date(machine.locationDate).getTime();

                const longTimeUpdate =
                  diffLocationDate >= settingsAux.maxUpdateTime;

                if (id === -1) {
                  let newMachine: MachineWithGeoInfo = {
                    ...machine,
                    longTimeUpdate,
                    geofenceInfo: {},
                  };

                  if (
                    machine.dateIn &&
                    machine.situation &&
                    machine.situationSpeed &&
                    response[geofenceName].averageTime
                  ) {
                    newMachine = {
                      ...machine,
                      longTimeUpdate,
                      geofenceInfo: {
                        [geofenceName]: {
                          dateIn: machine.dateIn,
                          averageTime: response[geofenceName].averageTime,
                          situation: machine.situation,
                          situationSpeed: machine.situationSpeed,
                        },
                      },
                    };
                  }
                  return newMachine;
                }

                if (
                  machine.dateIn &&
                  machine.situation &&
                  machine.situationSpeed &&
                  response[geofenceName].averageTime
                )
                  newMachines[id] = {
                    ...newMachines[id],
                    longTimeUpdate,
                    geofenceInfo: {
                      ...newMachines[id].geofenceInfo,
                      [geofenceName]: {
                        dateIn: machine.dateIn,
                        averageTime: response[geofenceName].averageTime,
                        situation: machine.situation,
                        situationSpeed: machine.situationSpeed,
                      },
                    },
                  };

                return null;
              })
              .reduce((acc: MachineWithGeoInfo[], curr) => {
                if (curr === null) {
                  return acc;
                }

                const id = acc.findIndex(
                  (newMachine) => newMachine.machineId === curr.machineId
                );
                if (id === -1) return [...acc, curr];

                return acc;
              }, []);

            newMachines = [...newGeofeceMachines, ...newMachines];
          }
        });

        setGeofences([...newGeofences]);

        setMachines(newMachines);
      } catch (error) {
        toasts.error((error as Error).message);
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const onFilterGeofences = (filters: string[]) => {
    const arrayGeofences = geofences.reduce(
      (arr: GeofenceWithMachines[], geofence) => {
        if (filters.length > 0) {
          const index = filters.findIndex((value) => value === geofence.name);

          if (index >= 0) {
            return [...arr, geofence];
          }
        } else {
          return [...arr, geofence];
        }

        return [...arr];
      },
      []
    );

    return arrayGeofences;
  };

  const onFilterMachines = (filters: string[], clients?: string[]) => {
    const arrayMachines = machines.reduce(
      (arr: MachineWithGeoInfo[], machine) => {
        if (filters.length > 0) {
          const index = filters.findIndex((value) => value === machine.plaque);

          if (index >= 0) {
            if (!clients || clients.length === 0) {
              return [...arr, machine];
            }
            if (clients.includes(machine.client)) {
              return [...arr, machine];
            }
          }
        } else if (!clients || clients.length === 0) {
          return [...arr, machine];
        } else if (clients.includes(machine.client)) {
          return [...arr, machine];
        }

        return [...arr];
      },
      []
    );

    return arrayMachines;
  };

  return {
    fetchGeofences,
    geofences,
    loading,
    machines,
    geofenceNames: allGeofenceNames,
    onFilterGeofences,
    onFilterMachines,
    settings,
  };
};

export default useLiveMap;
