/* eslint-disable no-underscore-dangle */
import { User } from "declarations";
import React, { useState } from "react";
import MarkerOption from ".";

interface useMarkerOptionsProps {
  user: User | null;
  values: string[];
}

const useMarkerOptions = ({ user, values }: useMarkerOptionsProps) => {
  const [openMarkerOptions, setMarkerOptions] = useState(false);
  const [selected, setSelected] = useState<string>(() => {
    try {
      if (!user) return "none";

      const storageString = localStorage.getItem(user._id);

      if (storageString) {
        const storageObject = JSON.parse(storageString);

        if (storageObject.markerOption) {
          return storageObject.markerOption;
        }

        return "none";
      }
      return "none";
    } catch (error) {
      return "none";
    }
  });

  const setValue = (value: string) => {
    if (!user) return;

    const storageString = localStorage.getItem(user._id);

    if (storageString) {
      const storageObject = JSON.parse(storageString);

      const newStorageObject = {
        ...storageObject,
        markerOption: value,
      };

      localStorage.setItem(user._id, JSON.stringify(newStorageObject));
    } else {
      const newStorageObject = {
        markerOption: value,
      };

      localStorage.setItem(user._id, JSON.stringify(newStorageObject));
    }

    setSelected(value);
  };

  const MarkerOptions = () => (
    <MarkerOption
      open={openMarkerOptions}
      onClose={() => setMarkerOptions(false)}
      onApplyOption={setValue}
      selectedValue={selected}
      values={values}
    />
  );

  return {
    MarkerOptions,
    selected,
    setMarkerOptions,
    openMarkerOptions,
  };
};

export default useMarkerOptions;
