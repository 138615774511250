import React from "react";
import { HashRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import GlobalStyle from "Styles/global";
import Theme, { themeMui } from "Styles/theme";
import Routes from "Routes";
import Startup from "ConnectedComponents/Startup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserProvider } from "Contexts/User";

const App = () => {
  return (
    <HashRouter>
      <ThemeProvider theme={Theme}>
        <MuiThemeProvider theme={themeMui}>
          <UserProvider>
            <Routes />
            <ToastContainer />
            <GlobalStyle />
          </UserProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </HashRouter>
  );
};

export default App;
