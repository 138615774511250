import feathers from "Api/Feathers";
import { ResponsePermanence } from "declarations";

interface PermancenceRequestProps {
  limit: number;
  skip: number;
  plaques?: string[];
  geofences?: string[];
  clients?: string[];
  dateIn?: string[];
  dateOut?: string[];
  showRemoved?: boolean;
  geofenceAllowed?: boolean;
  groupBy?: string | null;
}

export const getPermanence = ({
  limit,
  skip,
  plaques,
  geofences,
  clients,
  dateIn,
  dateOut,
  showRemoved,
  geofenceAllowed,
  groupBy
}: PermancenceRequestProps): Promise<ResponsePermanence> =>
  feathers.service("machine-events").find({
    query: {
      $limit: limit,
      $skip: skip,
      showRemoved,
      "geofence.allowed": geofenceAllowed,
      "machine.plaque": {
        $in: plaques,
      },
      "machine.client": {
        $in: clients,
      },
      $sort: {
        dateOut: -1,
      },
      minTimeOnGeofence: {
        $ne: false,
      },
      "geofence.name": {
        $in: geofences,
      },
      "dateIn.$gte": dateIn && dateIn[0],
      "dateIn.$lte": dateIn && dateIn[1],
      "dateOut.$gte": dateOut && dateOut[0],
      "dateOut.$lte": dateOut && dateOut[1],
      groupBy: groupBy ?? undefined, 
    },
  });

export const getPermanenceCSV = (
  fields: string[][],
  limit: number,
  skip: number,
  plaques?: string[],
  geofences?: string[],
  clients?: string[],
  dateIn?: string[],
  dateOut?: string[],
  geofenceAllowed?: boolean,
  groupBy?: string | null
): Promise<ResponsePermanence> =>
  feathers.service("machine-events").find({
    query: {
      csv: true,
      fields,
      $limit: limit,
      $skip: skip,
      "geofence.allowed": geofenceAllowed,
      "machine.plaque": {
        $in: plaques,
      },
      "machine.client": {
        $in: clients,
      },
      $sort: {
        dateOut: -1,
      },
      minTimeOnGeofence: {
        $ne: false,
      },
      "geofence.name": {
        $in: geofences,
      },
      "dateIn.$gte": dateIn && dateIn[0],
      "dateIn.$lte": dateIn && dateIn[1],
      "dateOut.$gte": dateOut && dateOut[0],
      "dateOut.$lte": dateOut && dateOut[1],
      groupBy: groupBy ?? undefined, 
    },
  });

interface RemoveProps {
  ids: string[];
  removed: {
    date: Date;
    cause: string;
    user: string;
  };
}

export const removePermanence = ({
  ids,
  removed,
}: RemoveProps): Promise<ResponsePermanence> =>
  feathers.service("machine-events").remove(ids, {
    query: {
      ...removed,
    },
  });
