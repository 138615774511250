import { createTheme } from "@material-ui/core/styles";

const theme = {
  colors: {
    interactive: {
      primary: "#FD331C",
      secondary: "#FD9926",
    },
    denotative: {
      medium: "#1B6DDD",
      mediumLight: "#DBEDFF",
      success: "#188FA7",
      successLight: "#E6FBFF",
      error: "#e60000",
      errorLight: "#FAEBF4",
    },
    layout: {
      whitePrimary: "#ffffff",
      whiteSecondary: "#FAFBFC",
      whiteTertiary: "#F2F4F7",
      disabled: "#CED2D5",
      blackPrimary: "#17202E",
      blackSecondary: "#3D557B",
      blackTertiary: "#6F88AF",
      blackQuaternary: "#AEBCD1",
      shadow: "#0c457d33",
      background: "#F3F5F8",
    },
  },
};

export const themeMui = createTheme({
  palette: {
    primary: {
      main: theme.colors.interactive.primary,
    },
    secondary: {
      main: theme.colors.interactive.secondary,
    },
    error: {
      main: theme.colors.denotative.error,
      light: theme.colors.denotative.errorLight,
    },
    success: {
      main: theme.colors.denotative.success,
      light: theme.colors.denotative.successLight,
    },
  },
});

export default theme;
