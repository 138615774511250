/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Icon from "Assets/Icons/IconTruck.svg";
import IconExceeded from "Assets/Icons/IconTruckExceeded.svg";
import IconExceededAndSpeed from "Assets/Icons/IconTruckExceededAndSpeed.svg";
import IconLongTimeUpdate from "Assets/Icons/IconTruckLongTimeUpdate.svg";
import IconSpeed from "Assets/Icons/IconTruckSpeed.svg";
import MapContainer from "Components/Map";
import Geofence from "Components/Map/Geofences/Geofence";
import useMarkerFilter from "Components/Map/Marker/MarkerFilter/useMarkerFiler";
import MarkerIntern from "Components/Map/Marker/MarkerIntern";
import useMarkerOptions from "Components/Map/Marker/MarkerOption/useMarkerOptions";
import MarkerTruck from "Components/Map/Marker/MarkerTruck";
import { IFilter } from "Components/TableOptions";
import useSearchClients from "Components/TableOptions/SearchClients/useSearchClients";
import useSearchGeofences from "Components/TableOptions/SearchGeofences/useSearchGeofences";
import useSearchMachines from "Components/TableOptions/SearchMachines/useSearchMachines";
import UserContext from "Contexts/User";
import useInterLiveMap from "Hooks/useInternLiveMap";
import useLiveMap from "Hooks/useLiveMap";
import sharedSentences from "Shared/sentences";
import { center } from "Shared/utils";
import { InternMachine } from "declarations";
import { LatLngExpression, Map } from "leaflet";
import { FeatureGroup } from "react-leaflet";
import {
  Filters,
  ListagemContainer,
  ListagemGeocercas,
  ListagemPaCarregadeira,
} from "./styles";

const TEN_SECCONDS = 10000;

const MARKER_OPTIONS = [
  sharedSentences.telemetry,
  sharedSentences.client,
  sharedSentences.truck,
  "Nenhum",
];

const ITEMS_CAPTION = [
  {
    src: Icon,
    title: sharedSentences.truck,
  },
  {
    src: IconExceeded,
    title: `${sharedSentences.truck} com ${sharedSentences.markerSituations.exceeded}`,
  },
  {
    src: IconSpeed,
    title: `${sharedSentences.truck} com ${sharedSentences.markerSituations.exceededSpeed}`,
  },
  {
    src: IconExceededAndSpeed,
    title: `${sharedSentences.truck} com ${sharedSentences.markerSituations.exceededTimeAndSpeed}`,
  },
  {
    src: IconLongTimeUpdate,
    title: `${sharedSentences.truck} ${sharedSentences.markerSituations.longTimeUpdate}`,
  },
];

const LiveMap = () => {
  const { user } = useContext(UserContext);
  const mapRef = useRef<Map>();
  const currentZoom = useRef<number>(13);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.on("zoomend", () => {
        if (mapRef.current) {
          currentZoom.current = mapRef.current.getZoom();
        }
      });
    }
  }, [mapRef.current]);

  const { MarkerOptions, selected, setMarkerOptions } = useMarkerOptions({
    user,
    values: MARKER_OPTIONS,
  });

  const { MarkerFilters, setMarkerFilter, selectedMarkers } = useMarkerFilter({
    user,
  });

  const {
    fetchGeofences,
    loading,
    geofences,
    machines,
    onFilterGeofences,
    onFilterMachines,
  } = useLiveMap();

  const {
    fetchInternMachines,
    loading: internLiveMapLoading,
    internMachines,
  } = useInterLiveMap();

  const [filters, setFilters] = useState<IFilter[]>([]);

  const { SearchGeofences, filtersGeofences, tagGeofence, setSearchGeofences } =
    useSearchGeofences({ filters, setFilters });

  const { SearchClients, filtersClients, tagClients, setSearchClients } =
    useSearchClients({ filters, setFilters });

  const { SearchMachines, filtersMachines, tagMachine, setSearchMachines } =
    useSearchMachines({ filters, setFilters });

  const filteredGeofences = onFilterGeofences(filtersGeofences);
  const filteredMachines = onFilterMachines(filtersMachines, filtersClients);

  useEffect(() => {
    const fetchData = () => {
      if (!loading) {
        fetchGeofences();
        fetchInternMachines();
      }
    };

    fetchData();
    const interval = setInterval(fetchData, TEN_SECCONDS);

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    tagGeofence();
  }, [tagGeofence]);

  useEffect(() => {
    tagMachine();
  }, [tagMachine]);

  useEffect(() => {
    tagClients();
  }, [tagClients]);

  const subtitleScreen =
    machines.length > 1
      ? `(${machines.length} ${sharedSentences.trucks})`
      : `(${machines.length} ${sharedSentences.truck})`;

  const itemsFilter = [
    {
      label: "Placas",
      action: () => setSearchMachines(true),
    },
    {
      label: "Alvos",
      action: () => setSearchGeofences(true),
    },
    {
      label: "Transportadoras",
      action: () => setSearchClients(true),
    },
    {
      label: `Legenda ${sharedSentences.trucks.toLowerCase()}`,
      action: () => setMarkerOptions(true),
    },
    {
      label: `Mostrar ${sharedSentences.trucks.toLowerCase()}`,
      action: () => setMarkerFilter(true),
    },
  ];

  const removeFilter = (index: number) => {
    if (filters[index].name === "geofences") {
      setSearchGeofences(true);
    }
    if (filters[index].name === "machines") {
      setSearchMachines(true);
    }
  };

  const mappedGeofences = useMemo(
    () =>
      filteredGeofences.map((geofence) => (
        <Geofence key={geofence._id} geofence={geofence} popup />
      )),
    [filteredGeofences]
  );

  const mappedInternMachines = useMemo(
    () =>
      internMachines.map((intern) => (
        <MarkerIntern
          key={intern.machineId}
          position={[
            intern.location.coordinates[1],
            intern.location.coordinates[0],
          ]}
          popup
          machine={intern}
          filterMarker={selectedMarkers}
        />
      )),
    [internMachines, selectedMarkers]
  );

  const mappedMachines = useMemo(
    () =>
      filteredMachines.map((machine) => (
        <MarkerTruck
          key={machine.machineId}
          position={[
            machine.location.coordinates[1],
            machine.location.coordinates[0],
          ]}
          subtitleOption={selected}
          popup
          machine={machine}
          filterMarker={selectedMarkers}
          currentZoom={currentZoom.current}
        />
      )),
    [filteredMachines, selected, selectedMarkers, currentZoom]
  );

  const setMapRef = (map: Map) => {
    mapRef.current = map;
  };

  const onClickInternMachine = (internMachine: InternMachine) => {
    const point: LatLngExpression = {
      lng: internMachine.location.coordinates[0],
      lat: internMachine.location.coordinates[1],
    };
    mapRef.current?.flyTo(point, 16);
  };

  const onClickGeofence = (geofence: {
    coordinates: {
      coordinates: number[][][];
    };
  }) => {
    const point = center(geofence.coordinates.coordinates);
    mapRef.current?.flyTo(point, 15);
  };

  const internMachinesSorted = internMachines.sort((a, b) =>
    a.plaque.localeCompare(b.plaque)
  );

  return (
    <>
      <MapContainer
        title="Monitoramento - Mapa"
        subtitle={subtitleScreen}
        center={[-20.54213711944774, -43.74270918881854]}
        zoom={13}
        positionZoomControl="bottomleft"
        captionItems={ITEMS_CAPTION}
        resize
        whenCreated={setMapRef}
      >
        <Filters
          itemsFilter={itemsFilter}
          removeTag={removeFilter}
          filtersTags={filters}
        />
        <FeatureGroup>
          {mappedMachines}
          {mappedInternMachines}
          {mappedGeofences}
        </FeatureGroup>

        {SearchGeofences()}
        {MarkerOptions()}
        {SearchMachines()}
        {MarkerFilters()}
        {SearchClients()}
      </MapContainer>
      <ListagemContainer>
        <ListagemGeocercas
          resize
          geofences={filteredGeofences}
          loading={loading && geofences.length === 0}
          clickGeofence={onClickGeofence}
        />
        <ListagemPaCarregadeira
          resize
          list={internMachinesSorted}
          loading={internLiveMapLoading && internMachinesSorted.length === 0}
          onClick={onClickInternMachine}
        />
      </ListagemContainer>
    </>
  );
};

export default LiveMap;
