import Button from "BaseComponents/Buttons/HeaderButton";
import { Geofence } from "declarations";
import React from "react";
import {
  AddIcon,
  DeleteIcon,
  StyledAvatar,
  Title,
  Wrapper,
  WrapperLabels,
} from "./styles";

interface ItemOrignProps {
  geofence: Geofence;
  onSelect?: (geofence: Geofence) => void;
  onRemove?: (geofence: Geofence) => void;
  onClick?: (geofence: Geofence) => void;
}

const ItemOrigin = ({
  geofence,
  onClick = () => {},
  onRemove,
  onSelect,
}: ItemOrignProps) => {
  return (
    <Wrapper
      onClick={(e) => {
        onClick(geofence);
      }}
      onDoubleClickCapture={(e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.preventDefault();
        e.nativeEvent.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      <StyledAvatar>{geofence.name[0]}</StyledAvatar>
      <WrapperLabels>
        <Title>{geofence.name}</Title>
      </WrapperLabels>
      {onSelect && (
        <Button onClick={() => onSelect(geofence)}>
          <AddIcon />
        </Button>
      )}
      {onRemove && (
        <Button onClick={() => onRemove(geofence)}>
          <DeleteIcon />
        </Button>
      )}
    </Wrapper>
  );
};

export default ItemOrigin;
