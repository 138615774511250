import styled from "styled-components";
import PrimaryButton from "BaseComponents/Buttons/PrimaryButton";
import SecondaryButton from "BaseComponents/Buttons/SecondaryButton";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: fit-content;
  margin-bottom: 10px;
  margin-left: 10px;
`;

export const ButtonNew = styled(PrimaryButton)`
  max-height: 40px;
  max-width: 80px;
`;

export const ButttonExport = styled(SecondaryButton)`
  width: auto;

  align-items: center;

  max-height: 40px;
  min-width: 140px;

  > p {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
`;
