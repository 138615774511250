import styled from "styled-components";

export const Content = styled.div`
  flex: 1;
`;

export const ContentWrapper = styled.div<{ resized: boolean }>`
  background-color: ${(props) => props.theme.colors.layout.background};
  height: 100%;
  display: flex;
  position: ${(props) => (props.resized ? "fixed" : "")};
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  > div:first-child {
    display: ${(props) => (props.resized ? "none" : "")};
  }
`;
