import React, { useState } from "react";
import { removePermanence } from "Api/Permanence";
import toasts from "Shared/toasts";
import Modal, { IDetails, FormShape } from "./index";

const RemoveFunctions = {
  Permanence: removePermanence,
};

type RemoveFunctionsType = keyof typeof RemoveFunctions;

interface RemoveRegisterProps<T> {
  details: Array<IDetails>;
  title: string;
  idKey: keyof T;
  removeFunction: RemoveFunctionsType;
  onRemoved?: () => void;
}

const useRemoveRegister = <T,>({
  details,
  title,
  idKey,
  removeFunction,
  onRemoved = () => {},
}: RemoveRegisterProps<T>) => {
  const [ids, setIds] = useState<T[]>();
  const [loading, setLoading] = useState(false);

  const isOpen = !!ids;

  const removeRegisters = (idsToRemove: T[]) => {
    setIds([...idsToRemove]);
  };

  const onCloseModal = () => {
    setIds(undefined);
  };

  const onApply = async (data: FormShape) => {
    try {
      setLoading(true);

      if (ids) {
        const idsArr = ids.map((value) => value[idKey] as unknown as string);

        await RemoveFunctions[removeFunction]({
          ids: idsArr,
          removed: data,
        });
      }
      setLoading(false);
      onRemoved();
      onCloseModal();
    } catch (error) {
      toasts.error("Erro ao remover registros");
    } finally {
      setLoading(false);
    }
  };

  const ModalRemoveRegister = () => (
    <Modal<T>
      onClose={onCloseModal}
      loading={loading}
      open={isOpen}
      details={details}
      title={title}
      registers={ids}
      onApply={onApply}
    />
  );

  return {
    removeRegisters,
    ModalRemoveRegister,
  };
};

export default useRemoveRegister;
