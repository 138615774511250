import { CardContent, CardHeader, Chip, Popper } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import { InfoWrapper } from "Components/Map/Geofences/DetailGeofences/styles";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import {
  Arrow,
  InfoTitle,
  LabelInfo,
  Title,
  TitleWrapper,
  Wrapper,
} from "./styles";

interface InfoItem<T> {
  title: string;
  info: keyof T;
}

interface InfoHoverProps<T> {
  label: string;
  titleInfo?: string;
  item?: T;
  itensInfo?: InfoItem<T>[];
  placement?: "bottom" | "right" | "top" | "left";
  color?: "default" | "primary" | "secondary";
  icon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

const InfoHover = <T,>({
  label,
  titleInfo,
  item,
  itensInfo,
  placement = "bottom",
  icon,
  color,
}: InfoHoverProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const arrowRef = useRef<HTMLParagraphElement | null>(null);

  const handlerAnchor = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const stopPropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
  };

  const showInfo = (value: any) => {
    const test = String(value).match(
      /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z/gm
    );

    if (test && typeof value !== "number") {
      return dayjs(value).format("DD-MM-YYYY [às] HH:mm");
    }

    return value;
  };

  return (
    <div>
      <Chip
        clickable
        onClick={handlerAnchor}
        label={label}
        color={color}
        icon={icon}
      />
      {itensInfo && item && (
        <Popper
          placement={placement}
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          style={{ zIndex: 10000 }}
          modifiers={{
            flip: {
              enabled: true,
            },
            arrow: {
              enabled: !!arrowRef.current,
              element: arrowRef.current,
            },
          }}
        >
          <Wrapper onClick={stopPropagation}>
            <Arrow ref={arrowRef} placement={placement} />
            <CardContent>
              <TitleWrapper>
                <Title>{titleInfo}</Title>
              </TitleWrapper>
              {itensInfo.map((value) => (
                <InfoWrapper>
                  <InfoTitle>{value.title}</InfoTitle>
                  <LabelInfo>{showInfo(item[value.info])}</LabelInfo>
                </InfoWrapper>
              ))}
            </CardContent>
          </Wrapper>
        </Popper>
      )}
    </div>
  );
};

export default InfoHover;
