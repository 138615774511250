import { Geofence } from "declarations";
import * as yup from "yup";

export const DestinationValidation = yup.object({
  name: yup.string().required("Por favor, insira um nome para o alvo!"),
  origins: yup
    .array()
    .min(1, "Selecione pelo menos uma origem")
    .required("Selecione pelo menos uma origem"),
});
