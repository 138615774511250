import React, { useRef } from "react";
import { TextField, IconButton } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import theme from "Styles/theme";
import { SearchDiv } from "./styles";

interface SearchBarProps {
  onChange?: (value: string) => void;
  placeholder?: string;
  onSearch?: (value: string) => void;
  className?: string;
}

const SearchBar = ({
  onChange,
  onSearch,
  placeholder,
  className,
}: SearchBarProps) => {
  const TextRef = useRef<any>(null);

  const handleClickSearch = () => {
    onSearch && onSearch(TextRef.current.value);
  };

  const handleChangeSearch = (event: any) => {
    onChange && onChange(event.target.value);
  };

  return (
    <SearchDiv className={className}>
      <TextField
        fullWidth
        onChange={handleChangeSearch}
        placeholder={placeholder}
        inputRef={TextRef}
        InputProps={{
          "aria-label": placeholder,
          disableUnderline: true,
        }}
      />
      <IconButton
        onClick={handleClickSearch}
        type="button"
        aria-label="search"
        disableFocusRipple
        disableRipple
      >
        <SearchIcon />
      </IconButton>
    </SearchDiv>
  );
};

export default SearchBar;
