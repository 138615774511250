import styled from "styled-components";
import { Lista } from "BaseComponents/Typographies";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.layout.blackSecondary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  height: 20px;
  z-index: 999;

  bottom: 80px;

  margin-bottom: 0;
  margin-top: auto;

  padding: 15px 60px;
  box-shadow: 0px -3px 6px #0c457d33;
  gap: 20px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 5px 5px 0;
`;

export const LabelInfo = styled(Lista)`
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.layout.whiteTertiary};
  opacity: 1;
  margin-left: 7px;
`;

export const InfoTitle = styled.span`
  font: normal normal 600 12px Open Sans;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  opacity: 1;
  margin-left: 7px;
`;
