import {
  Category,
  Room,
  Speed,
  ScheduleOutlined,
  LocalShipping,
} from "@material-ui/icons";
import dayjs from "dayjs";
import { GeoInfoMachine } from "declarations";
import React, { memo } from "react";
import sharedSentences from "Shared/sentences";
import {
  PopupMarker,
  InfoWrapper,
  LabelInfo,
  Title,
  TitleWrapper,
  InfoTitle,
} from "./styles";

export interface TimeGeofence {
  geofenceName: string;
  time: string;
}

interface PopupTruckProps {
  title?: string;
  telemetry?: string;
  latlngLabel: string;
  speed?: number;
  geofenceInfo?: {
    [geofenceName: string]: GeoInfoMachine;
  };
  listTimes?: TimeGeofence[];
  lastUpdate?: string;
  client?: string;
}

const PopupTruck = ({
  title,
  telemetry,
  latlngLabel,
  speed,
  geofenceInfo,
  listTimes,
  lastUpdate,
  client,
}: PopupTruckProps) => {
  const showLastUpdate = dayjs(lastUpdate).format("DD [de] MMMM [às] HH:mm");

  return (
    <PopupMarker>
      <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>
      <InfoWrapper>
        <Category htmlColor="#AEBCD1" fontSize="small" />
        <InfoTitle>{`${sharedSentences.telemetry}:`} </InfoTitle>
        <LabelInfo>{telemetry}</LabelInfo>
      </InfoWrapper>
      <InfoWrapper>
        <Room htmlColor="#AEBCD1" fontSize="small" />
        <LabelInfo>{latlngLabel}</LabelInfo>
      </InfoWrapper>
      {speed && (
        <InfoWrapper>
          <Speed htmlColor="#AEBCD1" fontSize="small" />
          <LabelInfo>{`${speed} Km/h`}</LabelInfo>
        </InfoWrapper>
      )}
      {geofenceInfo &&
        listTimes &&
        listTimes.map((value) => (
          <InfoWrapper key={value.geofenceName}>
            <ScheduleOutlined htmlColor="#AEBCD1" fontSize="small" />
            <InfoTitle>{`Tempo no alvo: ${value.geofenceName}`}</InfoTitle>
            <LabelInfo>{value.time}</LabelInfo>
          </InfoWrapper>
        ))}

      {client && (
        <InfoWrapper>
          <LocalShipping htmlColor="#AEBCD1" fontSize="small" />
          <InfoTitle>{`${sharedSentences.client}:`} </InfoTitle>
          <LabelInfo>{client}</LabelInfo>
        </InfoWrapper>
      )}

      <InfoWrapper>
        <Category htmlColor="#AEBCD1" fontSize="small" />
        <InfoTitle>Última atualização às </InfoTitle>
        <LabelInfo>{showLastUpdate}</LabelInfo>
      </InfoWrapper>
    </PopupMarker>
  );
};

export default PopupTruck;
