import styled from "styled-components";
import { H2Bold, H5SemiBold } from "BaseComponents/Typographies";
import { ArrowDropDown, NotificationsOutlined } from "@material-ui/icons";

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 64px;
  align-items: center;
  padding: 0 32px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const Title = styled(H2Bold)`
  color: ${(props) => props.theme.colors.layout.blackPrimary};
`;

export const Subtitle = styled(H5SemiBold)`
  margin-left: 19px;
  color: ${(props) => props.theme.colors.layout.blackTertiary};
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Notification = styled(NotificationsOutlined)``;

export const Arrow = styled(ArrowDropDown)`
  color: ${(props) => props.theme.colors.layout.blackPrimary};
`;
