import { GeofenceWithMachines, Machine } from "declarations";
import React, { useCallback, useEffect, useState } from "react";
import CardInfo from "Components/Map/Geofences/CardInfo";
import SecondaryButton from "BaseComponents/Buttons/SecondaryButton";
import { diffTimeToString, fromMilliseconds } from "Shared/utils";
import sharedSentences from "Shared/sentences";
import {
  Wrapper,
  Content,
  Header,
  Body,
  Footer,
  ListMachines,
  ItemMachine,
  InfoWrapper,
  InfoContent,
  StyledAvatar,
  TitleItem,
  WrapperPapers,
  TitleInfo,
  WrapperMachines,
  ButtonClose,
} from "./styles";

interface DetailGeofencesProps {
  open: boolean;
  onClose: () => void;
  geofence: GeofenceWithMachines;
}

const DetailGeofences = ({ open, onClose, geofence }: DetailGeofencesProps) => {
  const [selectedMachine, setSelectedMachine] = useState<Machine | null>(null);
  const [geofenceAverageTimeMachines, setGeofenceAverageTimeMachines] =
    useState("");
  const [machineTimeInGeofence, setMachineTimeInGeofence] = useState("");

  const geofenceAverageTime = fromMilliseconds(geofence.averageTime || 0);

  const timeToShow = useCallback(() => {
    const averageTimeMachines = geofence.averageTimeMachineNow
      ? diffTimeToString(new Date().getTime(), geofence.averageTimeMachineNow)
      : diffTimeToString(0, 0);

    const machineTime =
      selectedMachine && selectedMachine.dateIn
        ? diffTimeToString(
            new Date().getTime(),
            new Date(selectedMachine.dateIn).getTime()
          )
        : diffTimeToString(0, 0);

    setGeofenceAverageTimeMachines(averageTimeMachines);
    setMachineTimeInGeofence(machineTime);
  }, [geofence.averageTimeMachineNow, selectedMachine]);

  useEffect(() => {
    timeToShow();
    const interval = setInterval(() => timeToShow(), 1000);

    return () => clearInterval(interval);
  }, [timeToShow]);

  return (
    <Wrapper open={open} onClose={onClose}>
      <Content>
        <Header>{`Detalhes do alvo - ${geofence.name}`}</Header>
        <Body>
          <WrapperMachines>
            <TitleInfo>{`Lista de ${sharedSentences.trucks}`}</TitleInfo>
            <ListMachines>
              {geofence.machines?.map((machine) => (
                <ItemMachine
                  key={machine.machineId}
                  onClick={() => setSelectedMachine(machine)}
                >
                  <StyledAvatar>{machine.name[0].toUpperCase()}</StyledAvatar>
                  <TitleItem>{machine.name}</TitleItem>
                </ItemMachine>
              ))}
            </ListMachines>
          </WrapperMachines>

          <InfoWrapper>
            <InfoContent>
              <TitleInfo>Informações do alvo:</TitleInfo>
              <WrapperPapers>
                <CardInfo
                  title="Tempo médio esperado"
                  subtitle={geofenceAverageTime}
                />
                <CardInfo
                  title={`Tempo médio ${sharedSentences.trucks}`}
                  subtitle={geofenceAverageTimeMachines}
                />
              </WrapperPapers>
            </InfoContent>
            <InfoContent>
              <TitleInfo>{`Informações da ${sharedSentences.truck}:`}</TitleInfo>
              {selectedMachine && (
                <WrapperPapers>
                  <CardInfo
                    title="Tempo no alvo"
                    subtitle={machineTimeInGeofence}
                  />
                  <CardInfo
                    title="Última velocidade registrada"
                    subtitle={`${selectedMachine.speed} Km/h`}
                  />
                </WrapperPapers>
              )}
            </InfoContent>
          </InfoWrapper>
        </Body>
        <Footer>
          <ButtonClose onClick={onClose}>Sair</ButtonClose>
        </Footer>
      </Content>
    </Wrapper>
  );
};

export default DetailGeofences;
