import styled from "styled-components";

export const Content = styled.div`
  flex: 1;
  margin: 0 32px 32px 32px;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.layout.background};
`;
