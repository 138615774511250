import React from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import SimpleSpinner from "BaseComponents/Spinners/SimpleSpinner";
import { Wrapper } from "./styles";

interface InfinityScrollProps {
  loading: boolean;
  hasNextPage: boolean;
  onLoadMore: () => void;
  children?: React.ReactNode;
}

const InfinityScroll = ({
  loading,
  onLoadMore,
  hasNextPage,
  children,
}: InfinityScrollProps) => {
  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <Wrapper ref={rootRef}>
      <ul>
        {children}
        {loading || (hasNextPage && <SimpleSpinner ref={infiniteRef} />)}
      </ul>
    </Wrapper>
  );
};

export default InfinityScroll;
