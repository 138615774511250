import feathers from "./Feathers";

const plaqueAuditorService = feathers.service("plaque-auditor");

export const getPlaqueAudition = async (limit: number, skip: number) =>
  plaqueAuditorService.find({
    query: {
      $limit: limit,
      $skip: skip,
    },
  });

export const getPlaqueAuditionCSV = (fields: string[][]) =>
  feathers.service("plaque-auditor").find({ query: { csv: true, fields } });
