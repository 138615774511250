import styled from "styled-components";

export const RegularText = styled.p`
  color: ${(props) => props.color || "#000"};
  font-size: 18.6px;
`;

export const H1Regular = styled.h1``;

export const H1SemiBold = styled.h1`
  font-weight: 600;
`;
export const H1Bold = styled.h1`
  font-weight: bold;
`;

export const H2Regular = styled.h2``;

export const H2SemiBold = styled.h2`
  font-weight: 600;
`;
export const H2Bold = styled.h2`
  font-weight: bold;
`;

export const H3Regular = styled.h3``;

export const H3SemiBold = styled.h3`
  font-weight: 600;
`;
export const H3Bold = styled.h3`
  font-weight: bold;
`;

export const H4Regular = styled.h4``;

export const H4SemiBold = styled.h4`
  font-weight: 600;
`;
export const H4Bold = styled.h4`
  font-weight: bold;
`;

export const H5Regular = styled.h5``;

export const H5SemiBold = styled.h5`
  font-weight: 600;
`;
export const H5Bold = styled.h5`
  font-weight: bold;
`;

export const Lista = styled.p`
  font-size: 14px;
`;

export const LabelLista = styled.p`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const Tabs = styled.p`
  font-size: 14px;
  font-weight: 600;
`;
