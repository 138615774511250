/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { Geofence, ResponseGeofences } from "declarations";
import feathers from "./Feathers";

const geofenceService = feathers.service("geofences");

export const getGeofences = (
  skip: number,
  search?: string
): Promise<ResponseGeofences> =>
  geofenceService.find({
    query: {
      $sort: {
        name: 1,
      },
      $limit: 10,
      $skip: skip,
      name: {
        $search: search,
      },
    },
  });

export const postGeofences = (newGeofence: Geofence) =>
  geofenceService.create(newGeofence);

export const updateGeofences = (geofence: Geofence): Promise<Geofence> => {
  const id = geofence._id;

  delete geofence._id;
  const response = geofenceService.update(id, { ...geofence });

  return response;
};

export const deleteGeofences = (id: string) => geofenceService.remove(id);
