/* eslint-disable no-underscore-dangle */
import { MachineStale } from "declarations";
import feathers from "./Feathers";

const machineStaleService = feathers.service("machine-stale");

export const getStaleMachines = (date: string[]): Promise<MachineStale[]> =>
  machineStaleService.find({
    query: {
      "machine.locationDate": {
        $gte:  date[0],
        $lte: date[1],
      },
    },
  });
