import { H3SemiBold } from "BaseComponents/Typographies";
import React, { useRef } from "react";
import { InputName } from "../../styles";
import { WrapperContent } from "./styles";

interface NameProps {
  name?: string;
  changeName: (value: string) => void;
}

const Name = ({ name, changeName }: NameProps) => {
  const refInput = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    changeName(event.target.value);
  };

  return (
    <WrapperContent>
      <H3SemiBold>Primeiro, defina um nome para a unidade:</H3SemiBold>
      <InputName
        type="text"
        label="Nome da unidade"
        clearWebkit
        defaultValue={name}
        ref={refInput}
        onChange={handleChange}
      />
    </WrapperContent>
  );
};

export default Name;
