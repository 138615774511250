import { Room, Speed, Category } from "@material-ui/icons";
import dayjs from "dayjs";
import { MachineLocation } from "declarations";
import React from "react";
import { InfoWrapper, LabelInfo, InfoTitle, Wrapper } from "./styles";

interface InfoPointProps {
  machine: MachineLocation;
}

const InfoPoint = ({ machine }: InfoPointProps) => {
  const showLastUpdate = dayjs(machine.locationDate).format(
    "DD [de] MMMM [às] HH:mm"
  );

  const latlngLabel = `Lat: ${machine.location.coordinates[1]} / Lng: ${machine.location.coordinates[0]}`;

  return (
    <Wrapper>
      <InfoWrapper>
        <Room htmlColor="#AEBCD1" fontSize="small" />
        <LabelInfo>{latlngLabel}</LabelInfo>
      </InfoWrapper>
      <InfoWrapper>
        <Speed htmlColor="#AEBCD1" fontSize="small" />
        <LabelInfo>{`${machine.speed} Km/h`}</LabelInfo>
      </InfoWrapper>

      <InfoWrapper>
        <Category htmlColor="#AEBCD1" fontSize="small" />

        <LabelInfo>{showLastUpdate}</LabelInfo>
      </InfoWrapper>
    </Wrapper>
  );
};

export default InfoPoint;
