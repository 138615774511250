import styled from "styled-components";
import { DateTimePicker } from "@material-ui/pickers";

export const StyledDateTimePicker = styled(DateTimePicker)`
  background-color: white;
  display: flex;
  justify-content: left;
  align-items: center;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.layout.background};
  height: 40px;

  width: 300px;

  .MuiInput-root,
  .MuiOutlinedInput-input {
    height: 100%;
    color: ${(props) => props.theme.colors.layout.blackPrimary};
    font: normal normal 600 14px Open Sans;
  }

  .MuiFormHelperText-root.Mui-error {
    color: ${(props) => props.theme.colors.interactive.secondary};
    font-weight: 600;
    font-size: 14px;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 10px) scale(1);
  }

  .MuiInputBase-input {
    color: ${(props) => props.theme.colors.layout.blackTertiary};
    font: normal normal 600 18px Open Sans;
    text-align: center;
  }

  .MuiFormControl-root:focus {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
    outline: none;

    box-shadow: 0px 3px 6px ${(props) => props.theme.colors.layout.shadow};
  }
`;
