import styled from "styled-components";

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 10%;
  text-align: center;
  gap: 15%;
`;
