import Input, { InputProps } from "BaseComponents/Inputs/Input";
import ModalWithConfirmation, {
  IInput,
} from "Components/ModalWithConfirmation";
import React from "react";
import { timeMask } from "Shared/masks";
import { fromMillisecondsToHour } from "Shared/utils";

interface EditPermanenceGoalProps {
  open: boolean;
  onClose: () => void;
  defaultValue?: number;
  onApply: (data: FormShape) => void;
}

export interface FormShape {
  monthGoalTTP: string;
}

const EditPermanenceGoal = ({
  open,
  onClose,
  defaultValue,
  onApply,
}: EditPermanenceGoalProps) => {
  const inputs: IInput<FormShape>[] = [
    {
      type: "text",
      label: "Objetivo mensal TTP",
      name: "monthGoalTTP",
      applyMask: (event) => timeMask(event.target.value),
    },
  ];

  return (
    <ModalWithConfirmation<FormShape>
      open={open}
      onClose={onClose}
      title="Editar objetivo mensal TTP"
      inputs={inputs}
      onApply={onApply}
      defaultValues={{
        monthGoalTTP: fromMillisecondsToHour(defaultValue || 0),
      }}
      messageConfirmation="Tem certeza que deseja realizar essa modificação?"
    />
  );
};

export default EditPermanenceGoal;
