import { string } from "prop-types";
import React, { forwardRef } from "react";
import { Button as ButtonStyle, ButtonText, StyledSpinner } from "./styles";

interface ButtonProps {
  ariaDescribedBy?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  type?: "button" | "submit" | "reset";
  form?: string;
  id?: string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      disabled,
      onClick,
      children,
      className,
      isLoading,
      type,
      form,
      ariaDescribedBy,
      id,
    },
    ref
  ) => {
    return (
      <ButtonStyle
        id={id}
        ref={ref}
        aria-describedby={ariaDescribedBy}
        disabled={disabled}
        onClick={isLoading ? () => {} : onClick}
        className={className}
        type={type}
        form={form}
      >
        {isLoading ? (
          <StyledSpinner size="20px" />
        ) : (
          <ButtonText>{children}</ButtonText>
        )}
      </ButtonStyle>
    );
  }
);

export default Button;
