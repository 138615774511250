import React, { useCallback, useEffect, useState } from "react";
import Modal from "Components/Modal";
import { Checkbox } from "@material-ui/core";
import SearchBar from "BaseComponents/SearchBar";
import toasts from "Shared/toasts";
import { getMachines } from "Api/Machines";
import InfiniteScroll from "BaseComponents/InfinityScroll";
import { v4 as uuidv4 } from "uuid";
import {
  Body,
  ButtonCancel,
  ButtonSubmit,
  Footer,
  Header,
  Wrapper,
  ItemList,
  ChipsWrapper,
  StyledChip,
  LabelList,
} from "./styles";

interface SearchMachinesProps {
  open: boolean;
  onClose: () => void;
  onApplyFilters: (values: string[]) => void;
  singleSearch?: boolean;
}

const SearchMachines = ({
  onClose,
  open,
  onApplyFilters,
  singleSearch = false,
}: SearchMachinesProps) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [listFilters, setListFilters] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const removeSelected = (filter: string) => {
    const filters = [...selected];

    filters.splice(
      filters.findIndex((value) => value === filter),
      1
    );
    setSelected(filters);
  };

  const changeCheckBox = (value: string, checked: boolean) => {
    if (checked) {
      setSelected([...selected, value]);
    } else {
      removeSelected(value);
    }
  };

  const isSelected = (filter: string) =>
    !!selected.find((item) => item === filter);

  const findMachines = useCallback(async () => {
    setLoading(true);

    try {
      const response = await getMachines(page * 10, search);

      const filters = response.data.map((value) => value.plaque);

      setTotal(response.total);
      if (page === 0) {
        setListFilters([...filters]);
      } else {
        setListFilters(listFilters.concat([...filters]));
      }
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [search, page]);

  useEffect(() => {
    findMachines();
  }, [findMachines]);

  useEffect(() => {
    setPage(0);
  }, [search]);

  const applyFilters = () => {
    onApplyFilters(selected);
    onClose();
  };

  const applySingleFilters = (value: string) => {
    onApplyFilters([value]);
    onClose();
  };

  const clearFilters = () => {
    setSelected([]);
    onApplyFilters([]);
  };

  const hasNextPage = total > listFilters.length;

  const loadMore = () => {
    if (!loading) setPage(page + 1);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Wrapper>
        <Header>
          <SearchBar
            placeholder="Buscar"
            onChange={(value) => setSearch(value)}
          />
          <ChipsWrapper>
            {selected.map((value) => (
              <StyledChip
                key={uuidv4()}
                clickable
                label={value}
                onDelete={() => removeSelected(value)}
                color="primary"
              />
            ))}
          </ChipsWrapper>
        </Header>
        <Body>
          {listFilters && (
            <InfiniteScroll
              loading={loading}
              hasNextPage={hasNextPage}
              onLoadMore={loadMore}
            >
              {listFilters.map((value) => (
                <ItemList
                  key={uuidv4()}
                  onClick={() => {
                    singleSearch
                      ? applySingleFilters(value)
                      : changeCheckBox(value, !isSelected(value));
                  }}
                >
                  {!singleSearch && (
                    <Checkbox
                      key={value}
                      checked={isSelected(value)}
                      value={value}
                      onChange={(e, _) => {
                        changeCheckBox(e.target.value, e.target.checked);
                      }}
                      color="primary"
                    />
                  )}
                  <LabelList>{value}</LabelList>
                </ItemList>
              ))}
            </InfiniteScroll>
          )}
        </Body>

        <Footer>
          {!singleSearch && (
            <>
              <ButtonSubmit onClick={applyFilters}>Aplicar</ButtonSubmit>
              <ButtonCancel onClick={clearFilters}>Limpar</ButtonCancel>
            </>
          )}

          <ButtonCancel onClick={onClose}>Cancelar</ButtonCancel>
        </Footer>
      </Wrapper>
    </Modal>
  );
};

export default SearchMachines;
