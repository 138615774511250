import React, { useState, useEffect, useCallback } from "react";
import sharedSentences from "Shared/sentences";
import Search from ".";
import { IFilter } from "..";

interface useSearchClientsProps {
  filters: IFilter[];
  setFilters: React.Dispatch<React.SetStateAction<IFilter[]>>;
  defaultClients?: string[] 
}

const useSearchClients = ({ filters, setFilters, defaultClients = [] }: useSearchClientsProps) => {
  const [openSearchClients, setSearchClients] = useState(false);
  const [filtersClients, setFiltersClients] = useState<string[]>(defaultClients);

  const clearFilters = () => {
    setFiltersClients([]);
  };

  const handleOpen = () => {
    setSearchClients(true);
  };

  const tagClients = useCallback(() => {
    const index = filters.findIndex((value) => value.name === "clients");

    if (index === -1) {
      if (filtersClients.length > 0) {
        const filterClient: IFilter = {
          label: sharedSentences.clients,
          name: "clients",
          clear: clearFilters,
          open: handleOpen,
        };

        setFilters(state => [filterClient, ...state]);
      }
    } else if (filtersClients.length === 0) {
      setFilters((state) => {
        const listFilters = [...state];
        listFilters.splice(index, 1);
        return listFilters;
      })
    }
  }, [filters, filtersClients, setFilters]);

  const SearchClients = () => (
    <Search
      open={openSearchClients}
      onClose={() => setSearchClients(false)}
      onApplyFilters={(value) => setFiltersClients(value)}
    />
  );

  return {
    SearchClients,
    filtersClients,
    tagClients,
    setSearchClients,
    openSearchClients,
  };
};

export default useSearchClients;
