import * as yup from "yup";

export const UserValidation = yup.object({
  email: yup
    .string()
    .email("Entre com um email válido!")
    .required("Por favor, insira um email!"),
  senha: yup.string().required("Entre com uma senha!"),
  confirmSenha: yup
    .string()
    .oneOf([yup.ref("senha"), null], "Senhas devem ser iguais!"),
  isAdmin: yup.boolean(),
});
