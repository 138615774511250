import { IData } from 'BaseComponents/Charts/BarWithLine';
import FullPageSpinner from 'BaseComponents/Spinners/SimpleSpinner';
import TableOptions from 'Components/TableOptions';
import useSearchClients from 'Components/TableOptions/SearchClients/useSearchClients';
import { DateRangeType } from 'Components/TableOptions/SearchDate';
import useSearchDate from 'Components/TableOptions/SearchDate/useSearchDate';
import PageWrapper from 'Components/Wrappers/PageWrapper';
import useCrud from 'Hooks/useCrud';
import useExceedSpeed from 'Hooks/useExceedSpeed';
import sharedSentences from 'Shared/sentences';
import theme from 'Styles/theme';
import dayjs from 'dayjs';
import { useHistory } from 'react-router';
import { ExceedSpeed, OcurrenciesPerPlaque } from 'declarations';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts';
import { EmptyText } from './styles';

const initDateRange: DateRangeType = {
  startDate: dayjs().subtract(2, 'weeks').toDate(),
  endDate: dayjs().endOf("day").toDate(),
  color: theme.colors.interactive.primary,
  key: "selection",
  showDateDisplay: false,
};

const ExcessoDeVelocidadeDashboard = () => {
  const {
    listCrud,
    loading,
    setListCrud,
    setTotalCrud,
    setLoading,
    filters,
    setFilters,
  } = useCrud<ExceedSpeed>();

  const history = useHistory();

  const { SearchDateRange, dateRange, setSearchDate, tagDate } = useSearchDate({
    filters,
    setFilters,
    defaultRange: initDateRange,
  });

  const { SearchClients, filtersClients, setSearchClients, tagClients } =
    useSearchClients({ filters, setFilters });

  const { fetchAllData } = useExceedSpeed({
    dateRange,
    filtersClients,
    setListCrud,
    setLoading,
    setTotalCrud,
  });

  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  useEffect(() => {
    tagDate();
  }, [tagDate]);

  useEffect(() => {
    tagClients();
  }, [tagClients]);
  
  const getItems = useCallback(() => listCrud.reduce<[IData[], OcurrenciesPerPlaque[]]>(
    (acc, exceedSpeed) => {
      const { client, plaque } = exceedSpeed.machine;
      const [perTransport, perPlaque] = acc;

      const clientIndex = perTransport.findIndex(item => item.name === client);
      const plaqueIndex = perPlaque.findIndex(item => item.placa === plaque);

      if (clientIndex === -1) {
        perTransport.push({ name: client, barValue: 1 });
      } else {
        perTransport[clientIndex].barValue += 1;
      }

      if (plaqueIndex === -1) {
        perPlaque.push({ placa: plaque, ocorrencias: 1 });
      } else {
        perPlaque[plaqueIndex].ocorrencias += 1;
      }

      return acc;
    }, [[], []]), [listCrud]);

  const [dataPerTransport, dataPerPlaque] = useMemo(() => {
    const [perTransport, perPlaque] = getItems();

    perTransport.sort((a, b) => b.barValue - a.barValue)

    return [perTransport, perPlaque];
  }, [getItems]);

  const itemsFilter = [
    {
      label: "Data",
      action: () => setSearchDate(true),
    },
    {
      label: sharedSentences.clients,
      action: () => setSearchClients(true),
    },
  ];

  const getDateSearch = () => {
    const search = new URLSearchParams();

    if (dateRange) {
      search.append('startDate', dateRange.startDate.toISOString());
      search.append('endDate', dateRange.endDate.toISOString());
    }

    return search;
  }

  const handleBarClick = (data: IData) => {
    const search = getDateSearch();

    search.append('clients', data.name);
    history.push(`/relatorios/excesso_de_velocidade?${search}`);
  }

  const handleScatterClick = (data: OcurrenciesPerPlaque) => {
    const search = getDateSearch();

    search.append('machines', data.placa);
    history.push(`/relatorios/excesso_de_velocidade?${search}`);
  }

  const charts = listCrud.length === 0 ? null : (
    <>
      <ResponsiveContainer width="90%" height={700}>
        <BarChart
          layout="vertical"
          data={dataPerTransport}
          margin={{
            top: 20,
            left: 100,
            bottom: 20,
          }}
        >
          <CartesianGrid stroke={theme.colors.layout.whiteTertiary} strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis dataKey="name" type="category" />
          <Tooltip />
          <Legend verticalAlign="top" height={36} />        
          <Bar
            dataKey="barValue"
            name="Número de ocorrencias por transportadoras"
            fill={theme.colors.layout.blackSecondary}
            onClick={handleBarClick}
          />
        </BarChart>
      </ResponsiveContainer>
      <ResponsiveContainer width="90%" height={400}>
        <ScatterChart
          data={dataPerPlaque}
          margin={{
            top: 20,
            left: 100,
            bottom: 20,
          }}
        >
          <CartesianGrid stroke={theme.colors.layout.blackTertiary} strokeDasharray="3 3" />
          <XAxis dataKey="placa" hide />
          <YAxis dataKey="ocorrencias" />
          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          <Scatter
            data={dataPerPlaque}
            name="Número de ocorrencias por placas (passe o mouse para ver a placa)"
            fill={theme.colors.layout.blackSecondary}
            onClick={handleScatterClick}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </>
  )

  return (
    <PageWrapper subtitle="Excesso de velocidade" title="Dashboard">
      <TableOptions
        itemsFilter={itemsFilter}
        filtersTags={filters}
      />
      {loading || listCrud.length !== 0 ? null : (
        <EmptyText>Nenhuma ocorrência encontrada, selecione uma data diferente.</EmptyText>
      )}
      {loading ? <FullPageSpinner /> : charts}
      {SearchDateRange()}
      {SearchClients()}
    </PageWrapper>
  );
}

export default ExcessoDeVelocidadeDashboard;
