import React, { useState } from "react";
import SimpleSpinner from "BaseComponents/Spinners/SimpleSpinner";

import { Maximize, Minimize } from "@material-ui/icons";
import { Wrapper, ListWrapper, Header, Button } from "./styles";

interface BoardListProps {
  loading?: boolean;
  className?: string;
  children?: React.ReactNode;
  title: string;
  resize?: boolean;
  shouldStartMinimized?: boolean;
}

const BoardList = ({
  loading,
  children,
  className,
  title,
  resize = false,
  shouldStartMinimized = false,
}: BoardListProps) => {
  const [minimize, setMinimize] = useState(shouldStartMinimized);

  const toggleMinimize = () => {
    if (resize) setMinimize(!minimize);
  };

  return (
    <Wrapper className={className} minimize={minimize}>
      <Header>
        {title}
        {resize && (
          <Button onClick={toggleMinimize}>
            {minimize ? <Maximize /> : <Minimize />}
          </Button>
        )}
      </Header>
      {loading ? (
        <SimpleSpinner />
      ) : (
        <>
          <ListWrapper minimize={minimize}>{children}</ListWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default BoardList;
