import feathers from "Api/Feathers";
import { ResponseExceedSpeed } from "declarations";

export const getExceedSpeedCSV = (
  fields: string[][],
  limit?: number,
  skip?: number,
  plaques?: string[],
  geofences?: string[],
  clients?: string[],
  dateRange?: string[],
  groupBy?: string | null
) =>
  feathers.service("exceed-speed").find({
    query: {
      fields,
      csv: true,
      $limit: limit,
      $skip: skip,
      "machine.plaque": {
        $in: plaques,
      },
      "machine.client": {
        $in: clients,
      },
      $sort: {
        date: -1,
      },
      "geofence.name": {
        $in: geofences,
      },
      "date.$gte": dateRange && dateRange[0],
      "date.$lte": dateRange && dateRange[1],
      groupBy: groupBy ?? undefined,
    },
  });

export const getExceedSpeed = (
  limit?: number,
  skip?: number,
  plaques?: string[],
  geofences?: string[],
  clients?: string[],
  dateRange?: string[],
  groupBy?: string | null
): Promise<ResponseExceedSpeed> =>
  feathers.service("exceed-speed").find({
    query: {
      $limit: limit,
      $skip: skip,
      "machine.plaque": {
        $in: plaques,
      },
      $sort: {
        date: -1,
      },
      "machine.client": {
        $in: clients,
      },
      "geofence.name": {
        $in: geofences,
      },
      "date.$gte": dateRange && dateRange[0],
      "date.$lte": dateRange && dateRange[1],
      groupBy: groupBy ?? undefined, 
    },
  });
