import { DateRangeType } from "Components/TableOptions/SearchDate";
import { useCallback, useState } from "react";
import { getExceedSpeed, getExceedSpeedCSV } from "Api/ExceedSpeed";
import toasts from "Shared/toasts";
import { ExceedSpeed } from "declarations";
import { downloadFile } from "Shared/utils";
import sharedSentences from "Shared/sentences";
import { PER_PAGE_LIMIT } from "Shared/constants";

const fields = [
  [sharedSentences.truck, "machine.plaque"],
  ["Alvo", "geofence.name"],
  ["L. Vel. Alvo", "geofence.maxSpeed"],
  ["Velocidade", "speed"],
  ["Data", "date"],
  [sharedSentences.client, "machine.client"],
];

interface useExceedSpeedProps {
  setLoading: (value: React.SetStateAction<boolean>) => void;
  dateRange?: DateRangeType | null;
  rowsPerPage?: number;
  page?: number;
  filtersMachines?: string[];
  filtersGeofences?: string[];
  filtersClients?: string[];
  setListCrud: (value: React.SetStateAction<ExceedSpeed[]>) => void;
  setTotalCrud?: (value: React.SetStateAction<number>) => void;
}

const useExceedSpeed = ({
  setLoading,
  dateRange,
  rowsPerPage,
  page,
  filtersMachines,
  filtersClients,
  filtersGeofences,
  setListCrud,
  setTotalCrud = () => {},
}: useExceedSpeedProps) => {
  const fetchAllData = useCallback(async () => {
    try {
      setLoading(true);

      const range = dateRange
        ? [dateRange.startDate.toISOString(), dateRange.endDate.toISOString()]
        : undefined;

      let skip = 0;
      let total = 0;
      let arrayEvents: ExceedSpeed[] = [];

      do {
        // eslint-disable-next-line no-await-in-loop
        const response = await getExceedSpeed(
          PER_PAGE_LIMIT,
          skip,
          filtersMachines,
          filtersGeofences,
          filtersClients,
          range
        );

        arrayEvents = [...arrayEvents, ...response.data];

        total = response.total;
        skip = arrayEvents.length;
        setListCrud(arrayEvents);
      } while (skip < total);

      setTotalCrud(arrayEvents.length);
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    dateRange,
    filtersGeofences,
    filtersMachines,
    filtersClients,
    setListCrud,
    setTotalCrud,
  ]);

  const fetchData = useCallback(async (groupBy?: string | null) => {
    try {
      setLoading(true);

      const range = dateRange
        ? [dateRange.startDate.toISOString(), dateRange.endDate.toISOString()]
        : undefined;

      const skip = page && rowsPerPage ? page * rowsPerPage : 0;
      const limit = rowsPerPage || 500;

      const response = await getExceedSpeed(
        limit,
        skip,
        filtersMachines,
        filtersGeofences,
        filtersClients,
        range,
        groupBy
      );
      setListCrud(response.data);
      setTotalCrud(response.total);
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    dateRange,
    rowsPerPage,
    page,
    filtersGeofences,
    filtersMachines,
    filtersClients,
    setListCrud,
    setTotalCrud,
  ]);

  const fetchDataCSV = async (groupBy?: string | null) => {
    try {
      setLoading(true);

      const range = dateRange
        ? [dateRange.startDate.toISOString(), dateRange.endDate.toISOString()]
        : undefined;

      const skip = page && rowsPerPage ? page * rowsPerPage : undefined;

      const response = await getExceedSpeedCSV(
        fields,
        PER_PAGE_LIMIT,
        skip,
        filtersMachines,
        filtersGeofences,
        filtersClients,
        range,
        groupBy
      );

      await downloadFile(response, "ExcessoDeVelocidade.csv");
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchData,
    fetchDataCSV,
    fetchAllData,
  };
};

export default useExceedSpeed;
