import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import UserContext from "Contexts/User";

import Login from "Pages/Login";

import NovoDashboard from "Pages/NovoDashboard/Unidades";
import ExcessoDeVelocidadeDashboard from "Pages/NovoDashboard/ExcessoDeVelocidade";
import LocalProibidoDashboard from "Pages/NovoDashboard/LocalProibido";

import Geocercas from "Pages/Gerenciamento/Geocercas";
import Usuarios from "Pages/Gerenciamento/Usuarios";
import DestinosProibidos from "Pages/Gerenciamento/DestinosProibidos";
import RotasObrigatorias from "Pages/Gerenciamento/RotasObrigatorias";
import Unidades from "Pages/Gerenciamento/Unidades";

import LiveMap from "Pages/Monitoramento/Mapa";
import Trajeto from "Pages/Monitoramento/Trajeto";
import HeatMap from "Pages/Monitoramento/MapaDeCalor";
import PlacasParadas from "Pages/Monitoramento/PlacasParadas";

import ExcessoDeVelocidade from "Pages/Relatorios/ExcessoDeVelocidade";
import LocalProibido from "Pages/Relatorios/LocalProibido";
import Permanencia from "Pages/Relatorios/Permanencia";
import Viagens from "Pages/Relatorios/Viagens";
import RotasProibidas from "Pages/Relatorios/RotaProibida";
import Maquinas from "Pages/Relatorios/Maquinas";

import Configuracoes from "Pages/Opcoes/Configuracoes";

import { PontosDeInteresse } from "Pages/Monitoramento/PontosDeInteresse";
import PlaqueAuditor from "Pages/Relatorios/AuditoriaPlacas";
import Route from "./Route";

const Routes = () => {
  const { isSigned } = useContext(UserContext);
  return (
    <div>
      <Route
        path="/"
        exact
        component={() => <Redirect to="/dashboard/unidades" />}
        isPrivate={false}
        signed={isSigned}
      />
      <Route
        path="/dashboard"
        exact
        component={() => <Redirect to="/dashboard/unidades" />}
        isPrivate={false}
        signed={isSigned}
      />
      <Route
        path="/login"
        exact
        component={Login}
        isPrivate={false}
        signed={isSigned}
      />
      <Route
        path="/dashboard/unidades"
        exact
        component={NovoDashboard}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/dashboard/excesso_de_velocidade"
        exact
        component={ExcessoDeVelocidadeDashboard}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/dashboard/local_proibido"
        exact
        component={LocalProibidoDashboard}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/gerenciamento/alvos"
        exact
        component={Geocercas}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/gerenciamento/destinos_proibidos"
        exact
        component={DestinosProibidos}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/gerenciamento/rotas_obrigatorias"
        exact
        component={RotasObrigatorias}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/gerenciamento/usuarios"
        exact
        component={Usuarios}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/gerenciamento/unidades"
        exact
        component={Unidades}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/monitoramento/mapa"
        exact
        component={LiveMap}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/monitoramento/mapa_de_calor"
        exact
        component={HeatMap}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/monitoramento/veiculos_parados"
        exact
        component={PlacasParadas}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/monitoramento/trajeto"
        exact
        component={Trajeto}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/monitoramento/pontos_de_interesse"
        exact
        component={PontosDeInteresse}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/relatorios/excesso_de_velocidade"
        exact
        component={ExcessoDeVelocidade}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/relatorios/local_proibido"
        exact
        component={LocalProibido}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/relatorios/permanencia"
        exact
        component={Permanencia}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/relatorios/viagens"
        exact
        component={Viagens}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/relatorios/rotas_proibidas"
        exact
        component={RotasProibidas}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/relatorios/placas"
        exact
        component={Maquinas}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/relatorios/auditoria_placas"
        exact
        component={PlaqueAuditor}
        isPrivate={true}
        signed={isSigned}
      />
      <Route
        path="/opcoes/configuracoes"
        exact
        component={Configuracoes}
        isPrivate={true}
        signed={isSigned}
      />
      <Route component={Login} signed={isSigned} isPrivate={false} />
    </div>
  );
};

export default Routes;
