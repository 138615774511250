import { Category, Room, Speed, ScheduleOutlined } from "@material-ui/icons";
import dayjs from "dayjs";
import { GeoInfoMachine, MachineLocation } from "declarations";
import React from "react";
import {
  StyledPopup,
  InfoWrapper,
  LabelInfo,
  Title,
  TitleWrapper,
  InfoTitle,
} from "./styles";

export interface TimeGeofence {
  geofenceName: string;
  time: string;
}

interface PopupMarkerProps {
  location: MachineLocation;
}

const PopupMarker = ({ location }: PopupMarkerProps) => {
  const showLastUpdate = dayjs(location.locationDate).format(
    "DD [de] MMMM [às] HH:mm"
  );

  const latlngLabel = `Lat: ${location.location.coordinates[1]} / Lng: ${location.location.coordinates[0]}`;

  return (
    <StyledPopup>
      <InfoWrapper>
        <Room htmlColor="#AEBCD1" fontSize="small" />
        <LabelInfo>{latlngLabel}</LabelInfo>
      </InfoWrapper>
      <InfoWrapper>
        <Speed htmlColor="#AEBCD1" fontSize="small" />
        <LabelInfo>{`${location.speed} Km/h`}</LabelInfo>
      </InfoWrapper>

      <InfoWrapper>
        <Category htmlColor="#AEBCD1" fontSize="small" />
        <InfoTitle>Atualização às </InfoTitle>
        <LabelInfo>{showLastUpdate}</LabelInfo>
      </InfoWrapper>
    </StyledPopup>
  );
};

export default PopupMarker;
