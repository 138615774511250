import styled from "styled-components";
import { Tabs } from "BaseComponents/Typographies";

export const MenuLateralContainer = styled.div<{ active: string }>`
  height: 100vh;
  width: ${(props) => (props.active === "open" ? "254px" : "52px")};
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.active === "open"
      ? props.theme.colors.layout.blackPrimary
      : props.theme.colors.layout.background};
  transition: width linear 0.3s, background-color linear 0.2s;
`;
export const SideBarWrapper = styled.div`
  width: 100%;
  padding: 8px;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    background: none;
    width: 16px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ced2d5 0% 0% no-repeat padding-box;
    border-radius: 20px;
    border: 3px solid ${(props) => props.theme.colors.layout.blackPrimary};
  }
`;
export const SideBar = styled.div<{ active: string }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  overflow: hidden;
  visibility: ${(props) => (props.active === "open" ? "visible" : "hidden")};
  opacity: ${(props) => (props.active === "open" ? "1" : "0")};
  transition: visibility linear 0.5s, opacity linear 0.2s;
`;
export const TopImage = styled.img`
  width: 200px;
`;
export const BottomImage = styled.img`
  width: 100px;
`;
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px;
`;
export const BottomImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 36px;
  margin-bottom: 0;
  margin-top: auto;
`;
export const Nav = styled.div<{ active: string }>`
  padding: 8px 0 8px 12px;
  height: 64px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const SideBarFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const LogoutLabel = styled(Tabs)`
  color: ${(props) => props.theme.colors.layout.blackQuaternary};
`;
export const LogoutButton = styled.button`
  align-self: center;
  margin-top: 20px;
  border: none;
  background-color: none;
  background: none;
  cursor: pointer;
  outline: none;
`;
