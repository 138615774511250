import { ResponseTripDetails, TripInfo } from "declarations";
import feathers from "./Feathers";

const tripService = feathers.service("trip-info");

interface TripsRequestProps {
  dateOut: Date;
  dateIn: Date;
  unitName?: string;
  plaques?: string[];
  telemetries?: string[];
  clients?: string[];
  geofenceIn?: string[];
  geofenceOut?: string[];
}

export const getTripsDetails = (
  dateOut: Date,
  dateIn: Date,
  plaques: string[],
  telemetries: string[],
  clients: string[],
  geofenceIn: string[],
  geofenceOut: string[],
  skip?: number,
  limit?: number
): Promise<ResponseTripDetails> =>
  tripService.find({
    query: {
      details: true,
      plaques,
      telemetries,
      clients,
      geofenceIn,
      geofenceOut,
      dateIn,
      dateOut,
      skip,
      limit,
    },
  });

export const getTripsDetailsCSV = (
  fields: string[][],
  dateOut: Date,
  dateIn: Date,
  plaques: string[],
  telemetries: string[],
  clients: string[],
  geofenceIn: string[],
  geofenceOut: string[],
  skip?: number,
  limit?: number
): Promise<ResponseTripDetails> =>
  tripService.find({
    query: {
      fields,
      csv: true,
      details: true,
      plaques,
      telemetries,
      clients,
      geofenceIn,
      geofenceOut,
      dateIn,
      dateOut,
      skip,
      limit,
    },
  });

export const getTrips = ({
  dateIn,
  dateOut,
  clients,
  geofenceIn,
  geofenceOut,
  plaques,
  telemetries,
  unitName,
}: TripsRequestProps): Promise<TripInfo[]> =>
  tripService.find({
    query: {
      unitName,
      plaques,
      telemetries,
      clients,
      dateIn,
      dateOut,
      geofenceIn,
      geofenceOut,
    },
  });
