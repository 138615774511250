import { useState } from "react";
import { IFilter } from "Components/TableOptions";

interface ISort {
  field: string;
  order: -1 | 1;
}

const useCrud = <T>() => {
  const [listCrud, setListCrud] = useState<T[]>([]);
  const [totalCrud, setTotalCrud] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<ISort>();
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [loading, setLoading] = useState(false);
  const [showRemoved, setShowRemoved] = useState(false);
  const [search, setSearch] = useState<string>();

  return {
    listCrud,
    setListCrud,
    totalCrud,
    setTotalCrud,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    sort,
    setSort,
    filters,
    setFilters,
    loading,
    setLoading,
    search,
    setSearch,
    showRemoved,
    setShowRemoved,
  };
};

export default useCrud;
