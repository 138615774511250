import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "Components/Modal";
import {
  MapContainer,
  ZoomControl,
  TileLayer,
  Polyline,
  MapConsumer,
} from "react-leaflet";

import {
  GridLayer,
  LatLng,
  LatLngExpression,
  LatLngLiteral,
  LatLngTuple,
} from "leaflet";
import { v4 as uuidv4 } from "uuid";
import { JsxElement } from "typescript";
import { Backdrop, CircularProgress } from "@material-ui/core";
import dayjs from "dayjs";
import theme from "Styles/theme";
import { MachineLocation } from "declarations";
import SearchTruck from "Assets/Icons/SearchTruck.svg";
import {
  Wrapper,
  WrapperMessage,
  LabelMessage,
  WrapperControls,
} from "./styles";
import MarkerPath, { StatusType } from "./MarkerPath";
import ControlPath from "./ControlPath";
import SearchPath from "./SearchPath";
import { InfoWrapper } from "../Geofences/DetailGeofences/styles";
import InfoPoint from "./InfoPoint";
import DefaultLayers from "../DefaultLayers";

interface TruckPathProps {
  center: LatLng | LatLngLiteral | LatLngTuple;
  zoom?: number;
  path: MachineLocation[];
  selected: number;
  onChangeSelected: (value: number) => void;
  dateIn: Date | null;
  dateEnd: Date | null;
  setDateIn: (value: Date) => void;
  setDateEnd: (value: Date) => void;
  onSearch: () => void;
  loading: boolean;
  onNext: () => void;
  onPrev: () => void;
  setMachine?: (value: string) => void;
  machine?: string;
  beginHour?: Date;
}

const lineOptions = { color: theme.colors.interactive.secondary };

const TruckPath = ({
  center,
  zoom,
  path,
  selected,
  onChangeSelected,
  dateIn,
  dateEnd,
  setDateIn,
  setDateEnd,
  onSearch,
  loading,
  onNext,
  onPrev,
  setMachine,
  machine,
  beginHour,
}: TruckPathProps) => {
  const [play, setPlay] = useState<number | null>(null);

  const positions = path.map((value) => {
    const pos: LatLngExpression = {
      lat: value.location.coordinates[1],
      lng: value.location.coordinates[0],
    };

    return pos;
  });

  const renderPositions = () => {
    const arrayPositions: JSX.Element[] = [];

    path.map((value, index) => {
      let status: StatusType = "default";

      if (index === 0) {
        status = "initial";
      }
      if (index === path.length - 1) {
        status = "final";
      }
      if (index === selected) {
        status = "active";
      }
      if (index === selected && index === 0) {
        status = "activeInitial";
      }
      if (index === selected && index === path.length - 1) {
        status = "activeFinal";
      }

      arrayPositions.push(
        <MarkerPath value={value} status={status} key={uuidv4()} />
      );
    });

    return arrayPositions;
  };

  const dateInHour = dateIn ? dayjs(dateIn).format("HH:mm") : "";

  const initialHour = beginHour ? dayjs(beginHour).format("HH:mm") : dateInHour;
  const endHour = dateEnd ? dayjs(dateEnd).format("HH:mm") : "";

  const handlePlay = () => {
    const interval = window.setInterval(onNext, 1000);
    setPlay(interval);
  };

  const handlePause = useCallback(() => {
    play && clearInterval(play);
    setPlay(null);
  }, [play]);

  useEffect(() => {
    if (selected === path.length - 1) {
      handlePause();
    }
  }, [handlePause, path.length, selected]);

  const isPath = path.length > 0;

  return (
    <Wrapper>
      <Backdrop open={loading} style={{ zIndex: 800 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!isPath && (
        <WrapperMessage>
          <img src={SearchTruck} width="200px" alt="Buscar trajeto" />
          <LabelMessage>
            Utilize a busca acima para visualizar um trajeto.
          </LabelMessage>
        </WrapperMessage>
      )}

      <MapContainer
        zoom={zoom}
        center={center}
        zoomControl={false}
        style={{ width: "100%", height: "100%", position: "relative" }}
        preferCanvas
        worldCopyJump
        wheelDebounceTime={400}
        wheelPxPerZoomLevel={30}
        inertiaMaxSpeed={10}
        trackResize
        zoomDelta={0.2}
        maxZoom={17}
      >
        <DefaultLayers />
        {path.length > 0 && (
          <>
            <Polyline
              pathOptions={lineOptions}
              positions={positions}
              smoothFactor={10}
            />
            <MapConsumer>
              {(map) => {
                map.setView(positions[selected], map.getZoom(), {
                  noMoveStart: false,
                  animate: true,
                  easeLinearity: 10,
                });
                return null;
              }}
            </MapConsumer>
          </>
        )}

        {renderPositions()}
      </MapContainer>
      <WrapperControls>
        <SearchPath
          dateIn={dateIn}
          dateEnd={dateEnd}
          onClick={onSearch}
          setDateEnd={setDateEnd}
          setDateIn={setDateIn}
          setMachine={setMachine}
          machine={machine}
        />
        {isPath && <InfoPoint machine={path[selected]} />}
        {isPath && (
          <ControlPath
            beginHour={initialHour}
            endHour={endHour}
            max={path.length - 1}
            min={0}
            onChangePicker={onChangeSelected}
            onNext={onNext}
            onPrev={onPrev}
            step={1}
            valueSlider={selected}
            isPlay={!!play}
            onPause={handlePause}
            onPlay={handlePlay}
          />
        )}
      </WrapperControls>
    </Wrapper>
  );
};

export default TruckPath;
