import PrimaryButton from "BaseComponents/Buttons/PrimaryButton";
import SecondaryButton from "BaseComponents/Buttons/SecondaryButton";
import styled from "styled-components";
import { H3SemiBold, H4SemiBold } from "BaseComponents/Typographies";

export const Wrapper = styled.form`
  background: ${(props) => props.theme.colors.layout.whitePrimary};
  border: 1px solid ${(props) => props.theme.colors.layout.disabled};
  border-radius: 8px;
  height: 100%;
  opacity: 1;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;

  flex-wrap: wrap;
`;

export const Footer = styled.div`
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: row-reverse;
  border-top: 1px solid ${(props) => props.theme.colors.layout.whiteTertiary};
`;

export const ButtonSubmit = styled(PrimaryButton)`
  width: 140px;
  height: 40px;
`;

export const ButtonCancel = styled(SecondaryButton)`
  width: 140px;
  height: 40px;
`;

export const Option = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px;

  border-bottom: 1px solid ${(props) => props.theme.colors.layout.whiteTertiary};
`;

export const OptionLabel = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
`;

export const OptionChangeValue = styled.div`
  width: 40%;
`;

export const Title = styled(H3SemiBold)`
  color: ${(props) => props.theme.colors.layout.blackPrimary};
`;

export const SubTitle = styled(H4SemiBold)`
  color: ${(props) => props.theme.colors.layout.blackSecondary};
`;
