import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { FormControlLabel, Step, StepLabel, Switch } from "@material-ui/core";
import Stepper from "BaseComponents/Stepper";
import { Geofence, MandatoryRoute, Unit } from "declarations";
import Modal from "Components/Modal";
import { postUnit } from "Api/Units";
import toasts from "Shared/toasts";
import { ButtonAction, ButtonsWrapper } from "../styles";
import { MandatoryRoutesValidation } from "../Validation";
import Name from "./Name";
import Owner from "./Owner";
import Attendees from "./Attendees";
import Shifts from "./Shifts";
import Routes from "./Routes";
import { Wrapper } from "./styles";

type shiftsType = Array<Array<Date | null>>;

interface FormShape {
  name: string;
  owner: Geofence;
  attendees: Geofence[];

  shifts: shiftsType;
}

interface CreateProps {
  handleSuccess: () => void;
  open: boolean;
  onClose: () => void;
}

const Create = ({ open, onClose, handleSuccess }: CreateProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
    watch,
    getValues,
    formState,
  } = useForm<FormShape>({
    resolver: yupResolver(MandatoryRoutesValidation),
  });
  const [loading, setLoading] = useState(false);
  const attendees = watch("attendees", []);

  const owner = watch("owner");
  const name = watch("name");
  const shifts = watch("shifts", []);

  const createUnit = async (value: Unit) => {
    setLoading(true);
    try {
      await postUnit(value);

      toasts.success("Unidade cadastrada com sucesso!", () => {
        reset();
        handleReset();
        handleSuccess();
      });
    } catch (error) {
      setLoading(false);
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    register("attendees");

    register("owner");
    register("name");
    register("shifts");
  }, [register]);

  const setOwner = (value: Geofence) => {
    setValue("owner", value);
  };

  const setName = (value: string) => {
    setValue("name", value);
  };

  const setGeofences = (value: Geofence[]) => {
    setValue("attendees", [...value]);
  };

  const setShifts = (value: shiftsType) => {
    setValue("shifts", [...value]);
  };

  const addGeofence = (geofence: Geofence) => {
    const findIndex = attendees.findIndex(
      (value) => value.name === geofence.name
    );

    if (findIndex < 0) {
      setGeofences([...attendees, geofence]);
    }
  };

  const removeOrigin = (geofence: Geofence) => {
    const findIndex = attendees.findIndex(
      (value) => value.name === geofence.name
    );

    if (findIndex >= 0) {
      const newArray = [...attendees];

      newArray.splice(findIndex, 1);

      setGeofences(newArray);
    }
  };

  const submit = handleSubmit(
    async (data) => {
      createUnit({ ...data });
    },
    (error) => {
      console.log(error);
    }
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const allGeofencesUnit = owner ? [owner, ...attendees] : [...attendees];

  const steps = [
    "Defina um nome",
    "Escolha o alvo principal",
    "Defina todas os alvos participantes",

    "Defina os turnos de trabalho",
  ];

  const ContentStep = () => {
    switch (activeStep) {
      case 0:
        return <Name name={name} changeName={setName} />;
      case 1:
        return <Owner owner={owner} selectOwner={setOwner} />;

      case 2:
        return (
          <Attendees
            owner={owner}
            addOrigin={addGeofence}
            removeOrigin={removeOrigin}
            originsSelected={attendees}
          />
        );

      case 3:
        return <Shifts shifts={shifts} onChange={setShifts} />;

      default:
        return null;
    }
  };

  const handleClose = () => {
    reset();
    handleReset();
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Wrapper>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {ContentStep()}
        <ButtonsWrapper onSubmit={submit}>
          <ButtonAction
            type="button"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Voltar
          </ButtonAction>
          {activeStep < steps.length - 1 && (
            <ButtonAction type="button" onClick={handleNext}>
              Próximo
            </ButtonAction>
          )}
          {activeStep === steps.length - 1 && (
            <ButtonAction type="submit" isLoading={loading}>
              Salvar
            </ButtonAction>
          )}
        </ButtonsWrapper>
      </Wrapper>
    </Modal>
  );
};

export default Create;
