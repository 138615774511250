import React, { useState } from "react";
import MapWrapper from "Components/Wrappers/MapWrapper";
import {
  MapContainer,
  ZoomControl,
  TileLayer,
  WMSTileLayer,
} from "react-leaflet";
import { LatLng, LatLngLiteral, LatLngTuple, Map as MapType } from "leaflet";

import { CaptionItem } from "./Caption";
import ResizeMap from "./ResizeMap";
import DefaultLayers from "./DefaultLayers";

interface MapProps {
  title: string;
  subtitle?: string;
  center: LatLng | LatLngLiteral | LatLngTuple;
  zoom?: number;
  positionZoomControl?: "bottomright" | "topright" | "bottomleft" | "topleft";
  children?: React.ReactNode;
  captionItems?: CaptionItem[];
  resize?: boolean;
  whenCreated?: (map: MapType) => void;
}

const Map = ({
  title,
  subtitle,
  center,
  zoom,
  positionZoomControl,
  children,
  captionItems,
  resize,
  whenCreated,
}: MapProps) => {
  const [resized, setResized] = useState(false);

  const toggleResize = () => {
    if (resize) setResized(!resized);
  };

  return (
    <MapWrapper
      title={title}
      subtitle={subtitle}
      captionItems={captionItems}
      resized={resized}
    >
      <MapContainer
        center={center}
        zoom={zoom}
        zoomControl={false}
        style={{ width: "100%", height: "100%", position: "relative" }}
        whenCreated={whenCreated}
        preferCanvas
        worldCopyJump
        wheelDebounceTime={400}
        wheelPxPerZoomLevel={300}
        inertiaMaxSpeed={10}
        trackResize
        maxZoom={18}
      >
        <DefaultLayers />
        <ZoomControl position={positionZoomControl}  />
        {resize && <ResizeMap resized={resized} onClick={toggleResize} />}
        {children}
      </MapContainer>
    </MapWrapper>
  );
};

export default Map;
