import React from "react";
import Button from "BaseComponents/Buttons/HeaderButton";
import {
  Content,
  TitleWrapper,
  Title,
  Subtitle,
  OptionsWrapper,
  Notification,
  Arrow,
} from "./styles";

interface HeaderProps {
  title: string;
  subtitle?: string;
}

const index = ({ title, subtitle }: HeaderProps) => {
  return (
    <Content>
      <TitleWrapper>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TitleWrapper>
      <OptionsWrapper>
        <Button>
          <Notification />
        </Button>
      </OptionsWrapper>
    </Content>
  );
};

export default index;
