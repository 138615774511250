import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Label, StyledInput, Form, ErrorText } from "./styles";

export interface InputProps {
  label: string;
  type: string;
  errorText?: string;
  placeholder?: string;
  name?: string;
  step?: string | number;
  defaultValue?: string | number | readonly string[] | undefined;
  min?: string | number | undefined;
  max?: string | number | undefined;
  className?: string;
  pattern?: string;
  clearWebkit?: boolean;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      type,
      errorText,
      placeholder,
      name,
      step,
      defaultValue,
      min,
      max,
      pattern,
      onChange,
      className,
      clearWebkit = false,
    },
    ref
  ) => {
    const [id] = useState<string>(uuidv4());

    return (
      <Form className={className}>
        <Label htmlFor={id}>{label}</Label>
        <StyledInput
          clearWebkit={clearWebkit}
          placeholder={placeholder}
          id={id}
          step={step}
          name={name || label}
          type={type}
          error={!!errorText}
          ref={ref}
          pattern={pattern}
          defaultValue={defaultValue}
          min={min}
          max={max}
          onChange={onChange}
        />
        {errorText && <ErrorText>{errorText}</ErrorText>}
      </Form>
    );
  }
);

export default Input;
