import React, { memo } from "react";
import Icon from "Assets/Icons/IconTruck.svg";
import IconLongTimeUpdate from "Assets/Icons/IconTruckLongTimeUpdate.svg";
import IconExceeded from "Assets/Icons/IconTruckExceeded.svg";
import IconSpeed from "Assets/Icons/IconTruckSpeed.svg";
import IconExceededAndSpeed from "Assets/Icons/IconTruckExceededAndSpeed.svg";
import L from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";

const IconTruck = (
  label: string | undefined,
  exceeded: boolean,
  exceededSpeed: boolean,
  longTimeUpdate?: boolean,
  size?: number
) => {
  let icon = Icon;

  if (exceeded) {
    icon = IconExceeded;
  }

  if (exceededSpeed) {
    icon = IconSpeed;
  }

  if (exceeded && exceededSpeed) {
    icon = IconExceededAndSpeed;
  }

  if (longTimeUpdate) {
    icon = IconLongTimeUpdate;
  }

  const html = renderToStaticMarkup(
    <>
      <img
        className="my-div-image"
        src={icon}
        width={size ? `${size}px` : "40px"}
        height="auto"
        alt={label}
        loading="lazy"
      />
      {label && <span className="my-div-span">{label}</span>}
    </>
  );

  return L.divIcon({
    popupAnchor: [15, 0],
    className: "my-div-icon",
    html,
  });
};

export default IconTruck;
