/* eslint-disable no-underscore-dangle */
import React, { useContext, useState, useEffect } from "react";
import {
  ForbiddenDestination,
  Geofence,
  MandatoryRoute,
  Unit,
} from "declarations";
import { v4 as uuidv4 } from "uuid";
import Map from "Components/Map";
import UserContext from "Contexts/User";

import { FeatureGroup } from "react-leaflet";

import EditControl from "Components/Map/EditControl";

import CrudUnit from "Components/Map/Unit/CrudUnit";
import DestinationPolygon from "Components/Map/ForbiddenDestinations/Destination";
import GeofencePolygon from "Components/Map/Geofences/Geofence";

import {
  deleteRoute,
  getRoutes,
  postRoute,
  updateRoute,
} from "Api/MandatoryRoutes";
import toasts from "Shared/toasts";
import { Layer } from "leaflet";
import theme from "Styles/theme";
import { getUnits } from "Api/Units";

const Unidades = () => {
  const { isAdmin } = useContext(UserContext);
  const [units, setUnits] = useState<Unit[]>([]);
  const [layer, setLayer] = useState<Layer>();
  const [loading, setLoading] = useState(false);
  const [totalUnits, setTotalUnits] = useState(0);
  const [unitSelected, setUnitSelected] = useState<Unit>();

  const fetchUnits = async (skip: number) => {
    try {
      setLoading(true);
      const response = await getUnits(skip);
      setTotalUnits(response.total);
      if (skip === 0) {
        setUnits([...response.data]);
      } else {
        setUnits([...units, ...response.data]);
      }
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUnits(0);
  }, []);

  const centralizeGeofence = (latlng: L.LatLngExpression) => {};

  const loadMore = async () => {
    if (totalUnits > units.length) await fetchUnits(units.length);
  };

  const showUnit = (value: Unit) => {
    setUnitSelected(value);
  };

  const hasNextPage = totalUnits > 0 || units.length < totalUnits;

  const geofencesPerPage = 10;

  const pages = totalUnits / geofencesPerPage - 1;

  const showUnitSelected = () => {
    if (!unitSelected) return [];

    const owner = (
      // eslint-disable-next-line react/no-array-index-key
      <GeofencePolygon
        key={unitSelected.owner?._id || uuidv4()}
        geofence={unitSelected.owner}
        colorPolygon={theme.colors.interactive.secondary}
      />
    );

    const attendees = unitSelected.attendees.map((value) => (
      // eslint-disable-next-line react/no-array-index-key
      <GeofencePolygon
        key={value?._id || uuidv4()}
        geofence={value}
        colorPolygon={theme.colors.denotative.success}
      />
    ));

    return [owner, ...attendees];
  };

  return (
    <Map
      title="Unidades"
      subtitle={`(${totalUnits} cadastros)`}
      center={[-20.54213711944774, -43.74270918881854]}
      zoom={13}
      positionZoomControl="bottomright"
    >
      <FeatureGroup>
        <CrudUnit
          units={units}
          layer={layer}
          loading={loading}
          pages={pages}
          loadMore={loadMore}
          hasNextPage={hasNextPage}
          fetchUnits={fetchUnits}
          selectedUnit={showUnit}
        />

        {showUnitSelected()}
      </FeatureGroup>
    </Map>
  );
};

export default Unidades;
