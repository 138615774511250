import { Chip } from "@material-ui/core";
import PrimaryButton from "BaseComponents/Buttons/PrimaryButton";
import SecondaryButton from "BaseComponents/Buttons/SecondaryButton";
import { LabelLista } from "BaseComponents/Typographies";
import styled from "styled-components";

export const Wrapper = styled.div`
  background: white 0% 0% no-repeat padding-box;
  border-radius: 8px;
  box-shadow: 0px 0px 20px ${(props) => props.theme.colors.layout.shadow};
  width: 40vw;
  &:focus {
    outline: none;
  }
`;

export const Header = styled.div`
  padding: 24px;
  font-weight: bold;
  font-size: 24px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  border-bottom: 1px solid ${(props) => props.theme.colors.layout.whiteTertiary};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 500px;
  flex-wrap: wrap;
  padding: 20px;
  gap: 24px;
  max-height: 40vh;
  overflow: hidden;
`;

export const Footer = styled.div`
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: row-reverse;
  border-top: 1px solid ${(props) => props.theme.colors.layout.whiteTertiary};
`;

export const ButtonSubmit = styled(PrimaryButton)`
  width: 140px;
  height: 40px;
`;

export const ButtonCancel = styled(SecondaryButton)`
  width: 140px;
  height: 40px;
`;

export const ListWrapper = styled.div`
  max-height: 40vh;
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: unset;
`;

export const ItemList = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const LabelList = styled(LabelLista)`
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  margin-left: 10px;
`;

export const ChipsWrapper = styled.div`
  padding-top: 8px;
`;

export const StyledChip = styled(Chip)`
  margin: 0px 6px !important;
`;
