import styled from "styled-components";
import { Modal } from "@material-ui/core";
import PrimaryButton from "BaseComponents/Buttons/PrimaryButton";
import SecondaryButton from "BaseComponents/Buttons/SecondaryButton";

export const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.form`
  background: white 0% 0% no-repeat padding-box;
  border-radius: 8px;
  box-shadow: 0px 0px 20px ${(props) => props.theme.colors.layout.shadow};

  &:focus {
    outline: none;
  }
`;

export const Header = styled.div`
  padding: 24px;
  font-weight: bold;
  font-size: 24px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  border-bottom: 1px solid ${(props) => props.theme.colors.layout.whiteTertiary};
`;

export const Body = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: row;
  max-width: 500px;
  flex-wrap: wrap;
  gap: 24px;
`;

export const Footer = styled.div`
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: row-reverse;
  border-top: 1px solid ${(props) => props.theme.colors.layout.whiteTertiary};
`;

export const ButtonSubmit = styled(PrimaryButton)`
  width: 140px;
  height: 40px;
`;

export const ButtonCancel = styled(SecondaryButton)`
  width: 140px;
  height: 40px;
`;
