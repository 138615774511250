import React from "react";
import { StyledCheckBox } from "./styles";

interface CheckBoxProps {
  checked: boolean;
  indeterminate?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

const CheckBox = ({ checked, indeterminate, onChange }: CheckBoxProps) => {
  return (
    <StyledCheckBox
      onChange={onChange}
      checked={checked}
      indeterminate={indeterminate}
    />
  );
};

export default CheckBox;
