/* eslint-disable no-underscore-dangle */
import React, { useCallback, useContext, useState } from "react";
import UserContext from "Contexts/User";
import dayjs from "dayjs";
import { Geofence } from "declarations";
import { useMap } from "react-leaflet";
import { LatLngExpression, Layer } from "leaflet";
import Button from "BaseComponents/Buttons/HeaderButton";
import { center, toMilliseconds } from "Shared/utils";
import SimpleSpinner from "BaseComponents/Spinners/SimpleSpinner";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { v4 as uuidv4 } from "uuid";
import { postGeofences, updateGeofences } from "Api/Geofences";
import toasts from "Shared/toasts";
import GeofencePolygon from "Components/Map/Geofences/Geofence";
import EditControl from "Components/Map/EditControl";
import { GeofenceValidation } from "./Validation";

import {
  Wrapper,
  ListWrapper,
  EditWrapper,
  Label,
  DeleteIcon,
  ItemWrapper,
  InputName,
  ButtonsWrapper,
  ButtonAction,
  Header,
  List,
  EditIcon,
  StyledAvatar,
} from "./styles";
import Create from "./Create";
import Edit from "./Edit";

interface ListGeofencesProps {
  geofences: Geofence[];
  centralize?: (latlng: LatLngExpression) => void;
  remove?: (id: string) => void;

  loading: boolean;
  pages?: number;
  hasNextPage: boolean;
  fetchGeofences?: (skip: number) => void;
  loadMore: () => void;
}

const CrudGeofences = ({
  geofences,
  centralize,
  remove,
  loading,
  loadMore,
  pages,
  hasNextPage,
  fetchGeofences = () => {},
}: ListGeofencesProps) => {
  const map = useMap();
  const [points, setPoints] = useState<number[][]>();
  const [layer, setLayer] = useState<Layer>();
  const { isAdmin } = useContext(UserContext);
  const [geofenceEdit, setGeofenceEdit] = useState<Geofence>();

  const handleClick = (geofence: Geofence) => {
    const point = center(geofence.coordinates.coordinates);
    map.flyTo(point);
  };

  const initEdit = (geofence: Geofence) => {
    setGeofenceEdit(geofence);
    handleClick(geofence);
  };

  const createGeofence = (
    name: string,
    maxSpeed: number,
    averageTime: number,
    allowed: boolean,
    minStayTime?: number,
    maxStayTimeBeforeAlert?: number
  ) => {
    if (points) {
      const lastCoordinate = points[0];
      const coordinates = [
        [
          ...points.map(([latitude, longitude]) => [longitude, latitude]),
          [lastCoordinate[1], lastCoordinate[0]],
        ],
      ];
      const newGeofence: Geofence = {
        name,
        coordinates: {
          type: "Polygon",
          coordinates,
        },
        minStayTime,
        maxStayTimeBeforeAlert,
        averageTime,
        maxSpeed,
        allowed,
      };

      onSubmitCreateGeofence(newGeofence);
    }
  };

  const cancelCreate = () => {
    if (layer) {
      map.removeLayer(layer);
    }
    setPoints(undefined);
  };

  const cancelEdit = () => {
    setGeofenceEdit(undefined);
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    rootMargin: "0px 0px 400px 0px",
  });

  const onSubmit = (data: any) => {
    createGeofence(
      data.name,
      Number(data.maxSpeed),
      toMilliseconds(data.averageTime),
      data.allowed,
      data.minStayTime && toMilliseconds(data.minStayTime),
      data.maxStayTimeBeforeAlert && toMilliseconds(data.maxStayTimeBeforeAlert)
    );
  };

  const editSubmit = async (data: any) => {
    if (!geofenceEdit) return;

    const auxCoordinates = geofenceEdit.coordinates.coordinates[0];
    const lastCoordinate = auxCoordinates[0];
    const coordinates = [
      [...auxCoordinates, [lastCoordinate[0], lastCoordinate[1]]],
    ];

    let newGeofence: Geofence = {
      _id: geofenceEdit._id,
      name: data.name,
      coordinates: {
        type: "Polygon",
        coordinates,
      },
      averageTime: toMilliseconds(data.averageTime),
      maxSpeed: data.maxSpeed,
      allowed: data.allowed,
    };

    if (data.minStayTime) {
      newGeofence = {
        ...newGeofence,
        minStayTime: toMilliseconds(data.minStayTime),
      };
    }

    if (data.maxStayTimeBeforeAlert) {
      newGeofence = {
        ...newGeofence,
        maxStayTimeBeforeAlert: toMilliseconds(data.maxStayTimeBeforeAlert),
      };
    }

    try {
      await updateGeofences(newGeofence);
      await fetchGeofences(0);
      setGeofenceEdit(undefined);
    } catch (error) {
      toasts.error((error as Error).message);
    }
  };

  const onSubmitCreateGeofence = async (newGeofence?: Geofence) => {
    if (newGeofence) {
      try {
        await postGeofences(newGeofence);
        await fetchGeofences(geofences.length);
        setPoints(undefined);
      } catch (error) {
        toasts.error((error as Error).message);
      }
    }
  };

  const onCreateGeofence = (params: any) => {
    const coordinates = params.layer.editing.latlngs[0][0].map((item: any) => [
      item.lat as number,
      item.lng as number,
    ]);

    setPoints([...coordinates]);
    setLayer(params.layer);
  };

  const onEditPointsGeofence = (params: any) => {
    const key = Object.keys(params.layers._layers)[0];

    const coordinates = params.layers._layers[key].editing.latlngs[0][0].map(
      (item: any) => [item.lng as number, item.lat as number]
    );

    setGeofenceEdit((prev) => {
      return {
        ...prev,
        coordinates: {
          ...prev?.coordinates,
          coordinates: [coordinates],
        },
      } as Geofence;
    });
  };

  const options = () => {
    let optionsObject = {
      edit: { edit: false, remove: false },
      draw: {
        rectangle: false,
        polyline: false,
        circle: false,
        marker: false,
        circlemarker: false,
        polygon: true,
      },
    };

    if (geofenceEdit) {
      optionsObject = {
        edit: {
          ...optionsObject.edit,
          edit: true,
        },
        draw: {
          ...optionsObject.draw,
          polygon: false,
        },
      };
    }

    if (points) {
      optionsObject = {
        edit: {
          ...optionsObject.edit,
        },
        draw: {
          ...optionsObject.draw,
          polygon: false,
        },
      };
    }

    return optionsObject;
  };

  const content = () => {
    if (geofenceEdit) {
      return (
        <>
          <Wrapper>
            <Edit
              cancelEdit={cancelEdit}
              geofence={geofenceEdit}
              loading={loading}
              onSubmit={editSubmit}
            />
          </Wrapper>
          <GeofencePolygon geofence={geofenceEdit} />
        </>
      );
    }

    if (points) {
      return (
        <Wrapper>
          <Create
            creating={points}
            onSubmit={onSubmit}
            cancelCreate={cancelCreate}
            loading={loading}
          />
        </Wrapper>
      );
    }

    return (
      <>
        <Wrapper>
          <Header>Listagem</Header>

          <ListWrapper ref={rootRef}>
            <List>
              {geofences.map((geofence, index) => {
                return (
                  <ItemWrapper key={geofence._id || uuidv4()}>
                    <StyledAvatar exceeded={false}>
                      {geofence.name[0].toUpperCase()}
                    </StyledAvatar>
                    <Label onClick={() => handleClick(geofence)}>
                      {geofence.name}
                    </Label>
                    {isAdmin && (
                      <>
                        <Button onClick={() => initEdit(geofence)}>
                          <EditIcon onClick={() => initEdit(geofence)} />
                        </Button>
                        <Button
                          onClick={() => remove && remove(geofence._id || "")}
                        >
                          <DeleteIcon />
                        </Button>
                      </>
                    )}
                  </ItemWrapper>
                );
              })}
              {hasNextPage && <SimpleSpinner ref={sentryRef} />}
            </List>
          </ListWrapper>
        </Wrapper>
        {geofences.map((geofence, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <GeofencePolygon
              key={geofence?._id || uuidv4()}
              geofence={geofence}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      {isAdmin && (
        <EditControl
          position="topright"
          onCreated={onCreateGeofence}
          onEdited={onEditPointsGeofence}
          edit={options().edit}
          draw={options().draw}
        />
      )}
      {content()}
    </>
  );
};

export default CrudGeofences;
