import * as yup from "yup";

export const GeofenceValidation = yup.object({
  name: yup.string().required("Por favor, insira um nome para o alvo!"),
  averageTime: yup.string().required("Por favor, insira um tempo médio!"),
  minStayTime: yup.string(),
  maxStayTimeBeforeAlert: yup.string(),
  maxSpeed: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Por favor, insira uma velocidade máxima!")
    .positive("Velocidade deve ser maior que 0!")
    .integer(),
  allowed: yup.boolean().required(),
});
