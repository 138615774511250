import { Checkbox } from "@material-ui/core";
import styled from "styled-components";

export const StyledCheckBox = styled(Checkbox)`
  span {
    color: ${(props) => {
      if (props.indeterminate) return props.theme.colors.layout.blackPrimary;
      if (props.checked) return props.theme.colors.interactive.primary;
      return props.theme.colors.layout.blackQuaternary;
    }};
  }
`;
