/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useContext } from "react";
import ColorButton from "BaseComponents/Buttons/ColorButton";
import UserContext from "Contexts/User";
import theme from "Styles/theme";
import {
  DeleteOutline,
  DeleteOutlined,
  DonutSmallOutlined,
} from "@material-ui/icons";
import SimpleSpinner from "BaseComponents/Spinners/SimpleSpinner";
import CheckBox from "BaseComponents/Inputs/CheckBox";
import { v4 as uuidv4 } from "uuid";
import {
  TableContainer,
  TableCell,
  Table as MaterialTable,
  TableBody,
  TableRow,
  TableHead,
} from "@material-ui/core";

import dayjs from "dayjs";
import { Removed } from "declarations";
import InfoHover from "BaseComponents/Info/InfoHover";
import {
  CellHeaderSort,
  CellBody,
  CellHeader,
  MenuItem,
  StyledPopover,
  SpinnerWrapper,
} from "./styles";

export interface Option {
  label: string;
  action: (dataItem: any) => void;
  Icon?: React.ReactNode;
}

export interface Col {
  name: string;
  label: string;
  highlight?: boolean;
  scope?: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  format?: (value: any) => string;
  removed?: boolean;
}

interface IOrderBy {
  name: string;
  order: "asc" | "desc";
}

interface TableProps<T> {
  cols: Col[];
  data: T[];
  onOrder?: (result: IOrderBy) => void;
  options?: Option[];
  onDeleteAll?: (list: T[]) => void;
  loading?: boolean;
  idKey?: keyof T;
}

interface BaseT {
  _id?: string;
}

const Table = <T extends BaseT>({
  cols,
  data,
  onOrder,
  options,
  onDeleteAll,
  loading = false,
  idKey = "_id",
}: TableProps<T>) => {
  const { isAdmin } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<T[]>([]);
  const [orderBy, setOrderBy] = useState<IOrderBy>();
  const [itemData, setItemData] = useState<any>();

  const open = !!anchorEl;
  const idPopover = open ? "simple-popper" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkAll = () => {
    if (selected.length < data.length) {
      const arrayId = data.map((item: any) => item._id);
      setSelected([...arrayId]);
    } else {
      setSelected([]);
    }
  };

  const check = (item: T, checked: boolean) => {
    if (checked) {
      setSelected([...selected, item]);
    } else {
      const ids = [...selected];

      ids.splice(
        ids.findIndex((value) => value[idKey] === item[idKey]),
        1
      );
      setSelected(ids);
    }
  };
  const isSelected = (item: T) =>
    !!selected.find((value) => value[idKey] === item[idKey]);

  const selectOrder = (column: string) => {
    if (!orderBy) {
      setOrderBy({ name: column, order: "asc" });
    } else if (orderBy.name === column) {
      setOrderBy({
        name: column,
        order: orderBy.order === "asc" ? "desc" : "asc",
      });
    } else {
      setOrderBy({
        name: column,
        order: "asc",
      });
    }
  };

  useEffect(() => {
    onOrder && orderBy && onOrder(orderBy);
  }, [orderBy]);

  const handleDeleteAll = () => {
    onDeleteAll && onDeleteAll(selected);
    setSelected([]);
  };

  const handleClickCell = (event: any, item: any) => {
    if (isAdmin && options) {
      setAnchorEl(event.target);
      setItemData(item);
    }
  };

  const handleClickItemPopover = (item: Option) => {
    item.action(itemData);
    setAnchorEl(null);
  };

  const itemToShow = (
    item: any,
    name: string,
    removed?: boolean,
    format?: (value: any) => string
  ) => {
    const nameArray = name.split(".");
    let prop = item[nameArray[0]];

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < nameArray.length; i++) {
      prop = prop[nameArray[i]];
    }

    const test = String(prop).match(
      /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z/gm
    );

    if (test && typeof prop !== "number") {
      prop = dayjs(prop).format("DD-MM-YYYY [às] HH:mm");
    }

    if (removed) {
      if (item[name]) {
        return (
          <InfoHover<Removed>
            item={item[name]}
            label="Removido"
            color="primary"
            icon={<DeleteOutlined />}
            titleInfo="Dados da remoção"
            itensInfo={[
              {
                title: "Motivo",
                info: "cause",
              },
              {
                title: "Autor",
                info: "user",
              },
              {
                title: "Data",
                info: "date",
              },
            ]}
          />
        );
      }

      return (
        <InfoHover<Removed>
          icon={<DonutSmallOutlined />}
          label="Em contagem"
          color="secondary"
        />
      );
    }

    if (format) {
      return format(prop);
    }
    return prop;
  };

  return (
    <TableContainer>
      <MaterialTable style={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <CheckBox
                indeterminate={selected.length > 0}
                onChange={() => checkAll()}
                checked={false}
              />
            </TableCell>

            {selected.length > 0 ? (
              <CellHeader colSpan={cols.length}>
                {`${selected.length} item(s) selecionado(s)`}
                <ColorButton
                  style={{ marginLeft: "10px" }}
                  onClick={handleDeleteAll}
                >
                  Deletar{" "}
                  <DeleteOutline
                    style={{
                      height: "20px",
                      fill: theme.colors.layout.blackTertiary,
                      marginLeft: "3px",
                    }}
                  />
                </ColorButton>
              </CellHeader>
            ) : (
              cols.map((column) => (
                <TableCell key={column.name}>
                  <CellHeaderSort
                    active={orderBy && orderBy.name === column.name}
                    direction={
                      orderBy && orderBy.name === column.name
                        ? orderBy.order
                        : "asc"
                    }
                    hideSortIcon={true}
                    onClick={() => {}}
                  >
                    {column.label}
                  </CellHeaderSort>
                </TableCell>
              ))
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={cols.length + 1}>
                <SimpleSpinner />
              </TableCell>
            </TableRow>
          ) : (
            data.map((item) => (
              <TableRow key={item._id || uuidv4()}>
                <TableCell padding="checkbox">
                  <CheckBox
                    checked={isSelected(item)}
                    onChange={(event) => check(item, event.target.checked)}
                  />
                </TableCell>

                {cols.map((column) => (
                  <CellBody
                    key={`${item._id}${column.name}`}
                    onClick={(event) => handleClickCell(event, item)}
                    align={column.align}
                    scope={column.scope}
                    highlight={column.highlight}
                  >
                    {itemToShow(
                      item,
                      column.name,
                      column.removed,
                      column.format
                    )}
                  </CellBody>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </MaterialTable>
      <StyledPopover
        id={idPopover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {options &&
          itemData &&
          options.map((item) => (
            <MenuItem onClick={() => handleClickItemPopover(item)}>
              {item.Icon && item.Icon}
              {item.label}
            </MenuItem>
          ))}
      </StyledPopover>
    </TableContainer>
  );
};

export default Table;
