import styled from "styled-components";
import { DateRange } from "react-date-range";

export const StyledRange = styled(DateRange)`
  .rdrStartEdge,
  .rdrEndEdge,
  .rdrInRange,
  .rdrDayHovered,
  .rdrDayStartOfMonth,
  .rdrDayEndPreview,
  .rdrDayStartPreview,
  .rdrDayInPreview {
    color: ${(props) => props.theme.colors.interactive.primary} !important;
  }

  .rdrNextPrevButton:hover {
    background: ${(props) => props.theme.colors.interactive.secondary};
  }

  .rdrMonthAndYearWrapper {
    border-bottom: 1px solid
      ${(props) => props.theme.colors.layout.whiteTertiary};
  }

  .rdrMonthAndYearPickers,
  .rdrMonthName {
    text-transform: capitalize;
  }

  .rdrDayNumber span::after {
    background: ${(props) => props.theme.colors.interactive.primary} !important;
  }
  .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span::after {
    background: #fff !important;
  }
`;
