/* eslint-disable no-underscore-dangle */
import React, { useContext, useState, useEffect } from "react";
import { ForbiddenDestination, Geofence } from "declarations";
import { v4 as uuidv4 } from "uuid";
import Map from "Components/Map";
import UserContext from "Contexts/User";

import { FeatureGroup } from "react-leaflet";

import EditControl from "Components/Map/EditControl";

import CrudDestinations from "Components/Map/ForbiddenDestinations/CrudDestinations";
import DestinationPolygon from "Components/Map/ForbiddenDestinations/Destination";
import GeofencePolygon from "Components/Map/Geofences/Geofence";

import {
  getForbidden,
  postForbidden,
  deleteForbidden,
  updateForbidden,
} from "Api/ForbiddenDestination";
import toasts from "Shared/toasts";
import { Layer } from "leaflet";
import theme from "Styles/theme";

const Geocercas = () => {
  const { isAdmin } = useContext(UserContext);
  const [destinations, setDestinations] = useState<ForbiddenDestination[]>([]);
  const [points, setPoints] = useState<number[][]>();
  const [layer, setLayer] = useState<Layer>();
  const [loading, setLoading] = useState(false);
  const [totalDestinations, setTotalDestinations] = useState(0);
  const [originsOfDestination, setOriginsOfDestination] = useState<Geofence[]>(
    []
  );

  const fetchDestinations = async (skip: number) => {
    try {
      setLoading(true);
      const response = await getForbidden(skip);
      setTotalDestinations(response.total);
      if (skip === 0) {
        setDestinations([...response.data]);
      } else {
        setDestinations([...destinations, ...response.data]);
      }
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDestinations(0);
  }, []);

  const centralizeGeofence = (latlng: L.LatLngExpression) => {};

  const onCreateGeofence = (params: any) => {
    const coordinates = params.layer.editing.latlngs[0][0].map((item: any) => [
      item.lat as number,
      item.lng as number,
    ]);

    setPoints([...coordinates]);
    setLayer(params.layer);
  };

  const createDestination = async (destination?: ForbiddenDestination) => {
    if (destination) {
      try {
        await postForbidden(destination);
        await fetchDestinations(destinations.length);
        setPoints(undefined);
      } catch (error) {
        toasts.error((error as Error).message);
      }
    }
  };

  const removeDestination = async (id: string) => {
    try {
      await deleteForbidden(id);
      const newGeofences = destinations.filter(
        (destination) => destination._id !== id
      );
      setDestinations(newGeofences);
      setTotalDestinations(totalDestinations - 1);
      toasts.success("Destino proibido removido com sucesso!");
    } catch (error) {
      toasts.error((error as Error).message);
    }
  };

  const cancelCreate = () => {
    setPoints(undefined);
  };

  const loadMore = async () => {
    if (totalDestinations > destinations.length)
      await fetchDestinations(destinations.length);
  };

  const showOrigins = (value: Geofence[]) => {
    setOriginsOfDestination(value);
  };

  const hasNextPage =
    totalDestinations > 0 || destinations.length < totalDestinations;

  const geofencesPerPage = 10;

  const pages = totalDestinations / geofencesPerPage - 1;

  const drawOptions = {
    rectangle: false,
    polyline: false,
    circle: false,
    marker: false,
    circlemarker: false,
  };

  return (
    <Map
      title="Destinos proibidos"
      subtitle={`(${totalDestinations} cadastros)`}
      center={[-20.54213711944774, -43.74270918881854]}
      zoom={13}
      positionZoomControl="bottomright"
    >
      <FeatureGroup>
        {isAdmin && (
          <EditControl
            position="topright"
            onCreated={onCreateGeofence}
            draw={drawOptions}
          />
        )}
        <CrudDestinations
          destinations={destinations}
          centralize={centralizeGeofence}
          remove={removeDestination}
          creating={points}
          create={createDestination}
          cancel={cancelCreate}
          layer={layer}
          loading={loading}
          pages={pages}
          loadMore={loadMore}
          hasNextPage={hasNextPage}
          fetchDestinations={fetchDestinations}
          originsOfDestintion={showOrigins}
        />
        {originsOfDestination.map((geofence, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <GeofencePolygon
              key={geofence?._id || uuidv4()}
              geofence={geofence}
              colorPolygon={theme.colors.denotative.success}
            />
          );
        })}
        {destinations.map((destination, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <DestinationPolygon
              key={destination?._id || uuidv4()}
              destination={destination}
              colorPolygon={theme.colors.interactive.secondary}
            />
          );
        })}
      </FeatureGroup>
    </Map>
  );
};

export default Geocercas;
