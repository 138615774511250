/* eslint-disable no-underscore-dangle */
import React, { useContext, useState } from "react";
import UserContext from "Contexts/User";
import dayjs from "dayjs";
import { ForbiddenDestination, Geofence, MandatoryRoute } from "declarations";
import { useMap } from "react-leaflet";
import { LatLngExpression, Layer } from "leaflet";
import Button from "BaseComponents/Buttons/HeaderButton";
import { center, toMilliseconds } from "Shared/utils";
import SimpleSpinner from "BaseComponents/Spinners/SimpleSpinner";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { v4 as uuidv4 } from "uuid";
import { updateForbidden } from "Api/ForbiddenDestination";
import toasts from "Shared/toasts";
import { postRoute, updateRoute } from "Api/MandatoryRoutes";

import {
  Wrapper,
  ListWrapper,
  EditWrapper,
  Label,
  DeleteIcon,
  ItemWrapper,
  InputName,
  ButtonsWrapper,
  ButtonAction,
  Header,
  List,
  EditIcon,
  StyledAvatar,
  AddIcon,
} from "./styles";
import Create from "./Create";
import Edit from "./Edit";

interface CrudRoutesProps {
  routes: MandatoryRoute[];
  centralize?: (latlng: LatLngExpression) => void;
  remove?: (id: string) => void;
  creating?: number[][];
  cancel?: () => void;
  layer?: Layer;
  loading: boolean;
  pages?: number;
  hasNextPage: boolean;
  fetchRoutes?: (skip: number) => void;
  selectedRoute?: (values: Geofence[]) => void;
  loadMore: () => void;
}

const CrudRoutes = ({
  routes,
  centralize,
  remove,
  creating,
  cancel,
  layer,
  loading,
  loadMore,
  pages,
  hasNextPage,
  selectedRoute = () => {},
  fetchRoutes = () => {},
}: CrudRoutesProps) => {
  const map = useMap();
  const [stateCrud, setStateCrud] = useState<"list" | "edit" | "new">("list");

  const { isAdmin } = useContext(UserContext);
  const [routeEdit, setRouteEdit] = useState<MandatoryRoute | null>(null);

  const handleClick = (route: MandatoryRoute) => {
    const point = center(route.route[0].coordinates.coordinates);

    selectedRoute(route.route);

    map.flyTo(point);
  };

  const createDestination = async (name: string, geofences: Geofence[]) => {
    if (stateCrud === "new") {
      const newDestination: MandatoryRoute = {
        name,
        route: geofences,
      };

      try {
        await postRoute(newDestination);
        fetchRoutes(routes.length);
        setStateCrud("list");
      } catch (error) {
        toasts.error((error as Error).message);
      }
    }
  };

  const cancelCreate = () => {
    setStateCrud("list");
  };

  const cancelEdit = () => {
    setRouteEdit(null);
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
  });

  const onSubmit = (data: any) => {
    createDestination(data.name, data.route);
  };

  const editSubmit = async (data: any) => {
    if (!routeEdit) return;

    const newRoute: MandatoryRoute = {
      _id: routeEdit._id,
      name: data.name,
      route: data.route,
    };

    try {
      await updateRoute(newRoute);
      await fetchRoutes(0);
      setRouteEdit(null);
    } catch (error) {
      toasts.error((error as Error).message);
    }
  };

  const content = () => {
    if (stateCrud === "edit" && routeEdit) {
      return (
        <Edit
          cancelEdit={cancelEdit}
          route={routeEdit}
          loading={loading}
          onSubmit={editSubmit}
        />
      );
    }

    if (stateCrud === "new") {
      return (
        <Create
          onSubmit={onSubmit}
          cancelCreate={cancelCreate}
          loading={loading}
          originsToCreate={selectedRoute}
        />
      );
    }

    return (
      <>
        <Header>Listagem</Header>

        <ListWrapper ref={rootRef}>
          <ItemWrapper>
            <Label>Adicionar</Label>

            <Button onClick={() => setStateCrud("new")}>
              <AddIcon onClick={() => setStateCrud("new")} />
            </Button>
          </ItemWrapper>
          <List>
            {routes.map((route, index) => {
              return (
                <ItemWrapper key={route._id || uuidv4()}>
                  <StyledAvatar exceeded={false}>
                    {route.name[0].toUpperCase()}
                  </StyledAvatar>
                  <Label onClick={() => handleClick(route)}>{route.name}</Label>
                  {isAdmin && (
                    <>
                      <Button onClick={() => remove && remove(route._id || "")}>
                        <DeleteIcon />
                      </Button>
                    </>
                  )}
                </ItemWrapper>
              );
            })}
            {loading && <SimpleSpinner ref={sentryRef} />}
          </List>
        </ListWrapper>
      </>
    );
  };

  return <Wrapper>{content()}</Wrapper>;
};

export default CrudRoutes;
