import { H3SemiBold } from "BaseComponents/Typographies";
import styled from "styled-components";

export const WrapperAttendees = styled.div`
  height: 100%;
  overflow: auto;
`;

export const WrapperChoseAttendees = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 12px;
`;

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

export const Title = styled(H3SemiBold)`
  margin-bottom: 24px;
`;
