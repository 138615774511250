/* eslint-disable no-underscore-dangle */
import { TelemetryInfo } from "declarations";
import feathers from "./Feathers";

const telemetriesService = feathers.service("telemetries");

export const getTelemetries = (search: string): Promise<TelemetryInfo[]> =>
  telemetriesService.find({
    query: {
      name: search,
    },
  });
