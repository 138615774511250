import styled from "styled-components";
import {
  Checkbox,
  MenuList,
  Popover,
  TableCell,
  TableSortLabel,
} from "@material-ui/core";

export const CellHeaderSort = styled(TableSortLabel)`
  font-weight: bold;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
`;

export const CellHeader = styled(TableCell)`
  font-weight: bold !important;
  color: ${(props) => props.theme.colors.layout.blackPrimary} !important;
`;

export const StyledCheckBox = styled(Checkbox)`
  span {
    color: ${(props) => {
      if (props.indeterminate) return props.theme.colors.layout.blackPrimary;
      if (props.checked) return props.theme.colors.interactive.primary;
      return props.theme.colors.layout.blackQuaternary;
    }};
  }
`;

export const CellBody = styled(TableCell)<{ highlight?: boolean }>`
  font: normal normal normal 14px Open Sans !important;
  color: ${(props) =>
    props.highlight
      ? props.theme.colors.interactive.primary
      : props.theme.colors.layout.blackSecondary} !important;
  cursor: pointer;
`;

export const StyledPopover = styled(Popover)`
  .MuiPopover-paper {
    box-shadow: 0px 0px 8px ${(props) => props.theme.colors.layout.shadow};
    border-radius: 8px;
    padding: 6px 0 !important;
  }
`;

export const MenuItem = styled(MenuList)`
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  font: normal normal 600 14px Open Sans;
  padding: 8px 16px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  cursor: pointer;

  > svg {
    fill: ${(props) => props.theme.colors.layout.blackTertiary};
  }

  > svg:focus {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
`;
