import styled from "styled-components";

export const Label = styled.label`
  text-align: left;

  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  width: 100%;
  padding-bottom: 8px;
`;
export const StyledInput = styled.input<{
  error: boolean;
  clearWebkit: boolean;
}>`
  background: ${(props) => props.theme.colors.layout.whiteTertiary} 0% 0%
    no-repeat padding-box;
  border-radius: 8px;
  border: 2px solid;
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  padding: 8px 16px 8px 16px;
  text-align: left;

  font-size: 14px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  border-color: ${(props) =>
    props.error
      ? props.theme.colors.denotative.error
      : props.theme.colors.layout.whiteTertiary};
  &:focus {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
    outline: none;
    border: 2px solid;
    border-color: ${(props) =>
      props.error
        ? props.theme.colors.denotative.error
        : props.theme.colors.denotative.medium};
    box-shadow: 0px 3px 6px ${(props) => props.theme.colors.layout.shadow};
  }

  ${(props) =>
    props.clearWebkit &&
    `
    
    &::-webkit-datetime-edit-ampm-field {
      display: none !important;
      color: red !important;
    }

    &::-webkit-clear-button {
      -webkit-appearance: none !important;
      -moz-appearance: none !important;
      -o-appearance: none !important;
      -ms-appearance: none !important;
      appearance: none !important;
      margin: -10px !important;
    }`}
`;
export const Form = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Icon = styled.img`
  position: relative;
  margin-left: 90%;
  bottom: 26px;
`;
export const ErrorText = styled.p`
  text-align: left;
  font-size: 12px;
  margin-top: 4px;
  color: ${(props) => props.theme.colors.denotative.error};
  opacity: 1;
`;
