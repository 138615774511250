import React, { useState } from "react";
import Modal from "Components/Modal";
import { Checkbox } from "@material-ui/core";

import sharedSentences from "Shared/sentences";

import { v4 as uuidv4 } from "uuid";
import {
  Body,
  ButtonCancel,
  ButtonSubmit,
  Footer,
  Header,
  Wrapper,
  ItemList,
  ListWrapper,
  ChipsWrapper,
  StyledChip,
  LabelList,
} from "./styles";

interface MarkerFiltersProps {
  open: boolean;
  onClose: () => void;
  onApplyFilters: (value: string[]) => void;
  initialSelected?: string[];
}

const MarkerFilters = ({
  onClose,
  open,
  onApplyFilters,
  initialSelected = [],
}: MarkerFiltersProps) => {
  const [selected, setSelected] = useState<string[]>(initialSelected);

  const removeSelected = (filter: string) => {
    const filters = [...selected];

    filters.splice(
      filters.findIndex((value) => value === filter),
      1
    );
    setSelected(filters);
  };

  const changeCheckBox = (value: string, checked: boolean) => {
    if (checked) {
      setSelected([...selected, value]);
    } else {
      removeSelected(value);
    }
  };

  const isSelected = (filter: string) => selected.includes(filter);

  const applyFilters = () => {
    onApplyFilters(selected);
    console.log({selected})
    onClose();
  };

  const clearFilters = () => {
    setSelected([]);
    onApplyFilters([]);
  };

  const options = Object.values(sharedSentences.markerSituations);

  return (
    <Modal open={open} onClose={onClose}>
      <Wrapper>
        <Header />
        <Body>
          <ListWrapper>
            {options.map((value) => (
              <ItemList
                key={uuidv4()}
                onClick={() => {
                  changeCheckBox(value, !isSelected(value));
                }}
              >
                <Checkbox
                  key={value}
                  checked={isSelected(value)}
                  value={value}
                  onChange={(e, _) => {
                    changeCheckBox(e.target.value, e.target.checked);
                  }}
                  color="primary"
                />

                <LabelList>{value}</LabelList>
              </ItemList>
            ))}
          </ListWrapper>
        </Body>
        <Footer>
          <ButtonSubmit onClick={applyFilters}>Aplicar</ButtonSubmit>
          <ButtonCancel onClick={clearFilters}>Limpar</ButtonCancel>
          <ButtonCancel onClick={onClose}>Cancelar</ButtonCancel>
        </Footer>
      </Wrapper>
    </Modal>
  );
};

export default MarkerFilters;
