import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

export const Button = styled.button`
  position: relative;
  background-color: ${(props) => props.theme.colors.layout.background};
  border-radius: 8px;
  min-height: 40px;
  overflow: hidden;
  opacity: 1;
  outline: 0;
  border: 2px solid ${(props) => props.theme.colors.layout.background};
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  cursor: pointer;
  padding: 6px 12px;

  &:hover {
    border: 2px solid ${(props) => props.theme.colors.layout.blackQuaternary};
  }

  &:disabled {
    color: ${(props) => props.theme.colors.layout.blackTertiary};
    background-color: ${(props) => props.theme.colors.layout.disabled};
    border: 2px solid ${(props) => props.theme.colors.layout.disabled};
    box-shadow: none;
    cursor: initial;
  }
  &:disabled::before {
    opacity: 0;
  }
  &:disabled::after {
    opacity: 0;
  }
`;

export const ButtonText = styled.p`
  display: flex;
  justify-items: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
`;

export const StyledSpinner = styled(CircularProgress)`
  width: 40px;
  height: 40px;
  color: inherit !important;
`;
