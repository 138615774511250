import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 12px;
  overflow: auto;
  width: 100%;

  ::-webkit-scrollbar {
    background: white;
    width: 24px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ced2d5 0% 0% no-repeat padding-box;
    border-radius: 20px;
    border: 4px solid #fff;
  }
`;
