import { DoubleArrow } from "@material-ui/icons";
import { TripInfo } from "declarations";
import React, { useCallback, useEffect, useState } from "react";
import {
  diffTimeToString,
  fromMilliseconds,
  fromMillisecondsToHour,
} from "Shared/utils";
import theme from "Styles/theme";

import {
  Wrapper,
  Label,
  StyledAvatar,
  WrapperNumTrips,
  LabelQtd,
  Qtd,
  LabelAverage,
  WrapperTrip,
  WrapperAverage,
  LabelGeofence,
} from "./styles";

interface ItemTripProps {
  trip: TripInfo;
  onClick?: () => void;
}

const ItemTrip = ({ trip, onClick = () => {} }: ItemTripProps) => {
  const labelToShow = fromMilliseconds(trip.timeTrip);

  return (
    <Wrapper
      onClick={(e) => {
        onClick();
      }}
      onDoubleClickCapture={(e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.preventDefault();
        e.nativeEvent.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      <LabelQtd>Viagens</LabelQtd>
      <WrapperTrip>
        <LabelGeofence>{trip.geofenceOut.name}</LabelGeofence>
        <WrapperNumTrips>
          <DoubleArrow htmlColor={theme.colors.denotative.medium} />
          <Qtd>{`${trip.qtd}`}</Qtd>
          <DoubleArrow htmlColor={theme.colors.denotative.medium} />
        </WrapperNumTrips>
        <LabelGeofence>{trip.geofenceIn.name}</LabelGeofence>
      </WrapperTrip>
      <WrapperAverage>
        <LabelQtd>Tempo médio</LabelQtd>
        <LabelAverage>{labelToShow}</LabelAverage>
      </WrapperAverage>
    </Wrapper>
  );
};

export default ItemTrip;
