import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

export const Button = styled.button`
  position: relative;
  background-color: ${(props) => props.theme.colors.layout.whitePrimary};
  border-radius: 8px;
  width: 100%;
  min-height: 40px;
  overflow: hidden;
  opacity: 1;
  outline: 0;
  border: 2px solid ${(props) => props.theme.colors.layout.disabled};
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  cursor: pointer;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${(props) => props.theme.colors.layout.blackPrimary};
    background-color: ${(props) => props.theme.colors.layout.whiteTertiary};
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  &:focus {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  &:hover::before {
    opacity: 0.08;
  }
  &:focus::before {
    opacity: 0.24;
  }
  &:hover:focus::before {
    opacity: 0.3;
  }
  &:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  &:active::after {
    opacity: 0.32;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0s;
  }
  &:disabled {
    color: ${(props) => props.theme.colors.layout.blackTertiary};
    background-color: ${(props) => props.theme.colors.layout.disabled};
    border: 2px solid ${(props) => props.theme.colors.layout.disabled};
    box-shadow: none;
    cursor: initial;
  }
  &:disabled::before {
    opacity: 0;
  }
  &:disabled::after {
    opacity: 0;
  }
`;

export const ButtonText = styled.p`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-items: center;
  align-items: center;
`;

export const StyledSpinner = styled(CircularProgress)`
  width: 40px;
  height: 40px;
  color: inherit !important;
`;
