import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

const AbsoluteDiv = styled.div`
  position: absolute;
  z-index: 999;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
  font-family: "Open Sans", "Sans Serif";
`;

interface FullPageSpinnerProps {
  message?: string;
}

const FullPageSpinner = ({
  message = "Carregando...",
}: FullPageSpinnerProps) => (
  <AbsoluteDiv>
    <CircularProgress />
    {message}
  </AbsoluteDiv>
);

export default FullPageSpinner;
