import React, { useState } from "react";
import SecondaryButton from "BaseComponents/Buttons/SecondaryButton";
import { v4 as uuidv4 } from "uuid";
import PopperList from "BaseComponents/Menus/PopperList";
import { Select, MenuItem, ListIcon } from "./styles";

export interface Item {
  label: string;
  action: () => void;
}

interface FilterProps {
  items: Item[];
}

const Filter = ({ items }: FilterProps) => {
  const handleClickItem = (item: Item) => {
    item.action();
  };

  const newItems = items.map((item) => {
    return { label: item.label, onClick: () => handleClickItem(item) };
  });

  return <PopperList label="Filtros" items={newItems} />;
};

export default Filter;
