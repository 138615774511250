/* eslint-disable no-underscore-dangle */
import { Backdrop, CircularProgress } from "@material-ui/core";
import Modal from "Components/Modal";
import React, { useEffect, useState } from "react";
import { MapConsumer, MapContainer } from "react-leaflet";
import { getGeofences } from "Api/Geofences";
import { Geofence, GeofenceWithMachines } from "declarations";
import toasts from "Shared/toasts";
import GeofencePolygon from "Components/Map/Geofences/Geofence";
import { v4 as uuidv4 } from "uuid";
import { center } from "Shared/utils";
import { LatLngExpression } from "leaflet";
import DefaultLayers from "Components/Map/DefaultLayers";
import ListOrigins from "./ListOrigins";
import SelectOrigin from "./SelectOrigin";
import { Wrapper } from "./styles";

interface ModalOriginsProps {
  originsSelected: Geofence[];
  addOrigin: (value: Geofence) => void;
  removeOrigin: (value: Geofence) => void;
  open: boolean;
  onClose: () => void;
  titleListGeofences?: string;
  titleSelectedGeofences?: string;
}

const ModalOrigins = ({
  open,
  onClose,
  originsSelected,
  removeOrigin,
  addOrigin,
  titleListGeofences = "Alvos",
  titleSelectedGeofences = "Alvos selecionados",
}: ModalOriginsProps) => {
  const [origins, setOrigins] = useState<Geofence[]>([]);

  const [originToSelect, setOriginToSelect] = useState<Geofence>();
  const [totalOrigins, setTotalOrigins] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const [coordinatesToZoom, setToZoom] = useState<LatLngExpression>();

  const fetchGeofences = async (skip: number) => {
    try {
      setLoading(true);
      const response = await getGeofences(skip);
      setTotalOrigins(response.total);
      if (skip === 0) {
        setOrigins([...response.data]);
      } else {
        setOrigins([...origins, ...response.data]);
      }
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGeofences(0);
  }, []);

  const setZoom = (geofence: Geofence | GeofenceWithMachines) => {
    setToZoom(center(geofence.coordinates.coordinates));
  };

  const handleAdd = (geofence: Geofence) => {
    addOrigin(geofence);
    setOriginToSelect(undefined);
  };

  const handleRemove = (geofence: Geofence) => {
    removeOrigin(geofence);
  };

  const selectOrigin = (geofence: Geofence | GeofenceWithMachines) => {
    setOriginToSelect({ ...geofence });
  };

  const hasNextPage: boolean = totalOrigins > origins.length;

  const fetchMore = async () => {
    if (isLoading) return;

    await fetchGeofences(origins.length);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Wrapper>
        <Backdrop open={isLoading} style={{ zIndex: 800 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <MapContainer
          zoom={13}
          center={[-20.54213711944774, -43.74270918881854]}
          zoomControl={false}
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <DefaultLayers />
          {origins.map((origin, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <GeofencePolygon
                key={origin?._id || uuidv4()}
                geofence={origin}
                onClick={selectOrigin}
              />
            );
          })}

          <MapConsumer>
            {(map) => {
              coordinatesToZoom && map.flyTo(coordinatesToZoom);
              return null;
            }}
          </MapConsumer>
        </MapContainer>
        <ListOrigins
          origins={origins}
          onClick={setZoom}
          title={titleListGeofences}
          position="Left"
          onSelect={selectOrigin}
          hasNextPage={hasNextPage}
          onLoadMore={fetchMore}
          loading={isLoading}
        />
        <ListOrigins
          origins={originsSelected}
          onClick={setZoom}
          title={titleSelectedGeofences}
          position="Right"
          onRemove={handleRemove}
        />
        <SelectOrigin
          origin={originToSelect}
          onCancel={() => setOriginToSelect(undefined)}
          onConfirm={handleAdd}
          titleSelectedGeofences={titleSelectedGeofences}
        />
      </Wrapper>
    </Modal>
  );
};

export default ModalOrigins;
