import React, { memo } from "react";
import Icon from "Assets/Icons/IconTruck.svg";
import IconCarregadeira from "Assets/Icons/IconCarregadeira.svg";

import L from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { TypeInternMachine } from "declarations";

const IconIntern = (type: TypeInternMachine, label: string | undefined) => {
  let icon = Icon;

  if (type === "PÁ CARREGADEIRA") {
    icon = IconCarregadeira;
  }

  const html = renderToStaticMarkup(
    <>
      <img className="my-div-image" src={icon} width="40px" alt={label} />
      {label && <span className="my-div-span">{label}</span>}
    </>
  );

  return L.divIcon({
    popupAnchor: [15, 0],
    className: "my-div-icon",
    html,
  });
};

export default IconIntern;
