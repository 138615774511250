import React, { useCallback, useContext, useEffect, useState } from "react";
import UserContext from "Contexts/User";
import PageWrapper from "Components/Wrappers/PageWrapper";
import FakeGeofences from "FakeData/geofences-machines-fake-data";
import DetailGeofence from "Components/Map/Geofences/DetailGeofences";
import { Geofence, GeofenceWithMachines, TripInfo } from "declarations";
import { getTrips } from "Api/Trip";
import toasts from "Shared/toasts";
import useLiveMap from "Hooks/useLiveMap";
import { isGeofenceWithMachines } from "Shared/utils";
import { IFilter } from "Components/TableOptions";
import useSearchDate from "Components/TableOptions/SearchDate/useSearchDate";
import dayjs from "dayjs";
import theme from "Styles/theme";
import { DateRangeType } from "Components/TableOptions/SearchDate";
import useSearchMachines from "Components/TableOptions/SearchMachines/useSearchMachines";
import useSearchTelemetries from "Components/TableOptions/SearchTelemetries/useSearchTelemetries";
import useSearchClients from "Components/TableOptions/SearchClients/useSearchClients";
import sharedSentences from "Shared/sentences";
import useSearchGeofences from "Components/TableOptions/SearchGeofences/useSearchGeofences";
import {
  ContentWrapper,
  Listagem,
  ListagemTrips,
  Filters,
  WrapperBoards,
} from "./styles";

const ONE_MINUTE = 60000;
const TWENTY_MINUTES = 1200000;

const initDateRange: DateRangeType = {
  startDate: dayjs(new Date()).startOf("day").toDate(),
  endDate: dayjs(new Date()).endOf("day").toDate(),
  color: theme.colors.interactive.primary,
  key: "selection",
  showDateDisplay: false,
};

const Dashboard = () => {
  const { isAdmin, user, logout } = useContext(UserContext);

  const [trips, setTrips] = useState<TripInfo[]>([]);
  const [geofence, setGeofence] = useState<GeofenceWithMachines | null>(null);
  const [loadingTrips, setLoadingTrips] = useState(false);
  const { fetchGeofences, loading, geofences, geofenceNames } = useLiveMap();
  const [filters, setFilters] = useState<IFilter[]>([]);

  const { SearchDateRange, dateRange, setSearchDate, tagDate } = useSearchDate({
    filters,
    setFilters,
    defaultRange: initDateRange,
  });

  const range = dateRange || initDateRange;

  const {
    SearchMachines,
    filtersMachines,
    setSearchMachines,
    tagMachine,
    clearFilters,
  } = useSearchMachines({ filters, setFilters });

  const {
    SearchTelemetries,
    filtersTelemetries,
    setSearchTelemetries,
    tagTelemetries,
  } = useSearchTelemetries({ filters, setFilters });

  const { SearchClients, filtersClients, setSearchClients, tagClients } =
    useSearchClients({ filters, setFilters });

  const searchIn = useSearchGeofences({
    filters,
    setFilters,
    customLabel: "Alvos de entrada",
    customName: "searchIn",
  });

  const searchOut = useSearchGeofences({
    filters,
    setFilters,
    customLabel: "Alvos de saída",
    customName: "searchOut",
  });

  const fetchTrips = useCallback(async () => {
    try {
      setLoadingTrips(true);

      const response = await getTrips({
        dateIn: range.endDate,
        dateOut: range.startDate,
        plaques: filtersMachines,
        telemetries: filtersTelemetries,
        clients: filtersClients,
        geofenceIn: searchIn.filtersGeofences,
        geofenceOut: searchOut.filtersGeofences,
      });

      setTrips(response);
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoadingTrips(false);
    }
  }, [
    range.startDate,
    range.endDate,
    filtersMachines,
    filtersTelemetries,
    filtersClients,
    searchIn.filtersGeofences,
    searchOut.filtersGeofences,
  ]);

  useEffect(() => {
    fetchGeofences();
    const interval = setInterval(() => fetchGeofences(), ONE_MINUTE);

    return () => clearInterval(interval);
  }, [fetchGeofences]);

  useEffect(() => {
    fetchTrips();
    const interval = setInterval(() => fetchTrips(), TWENTY_MINUTES);

    return () => clearInterval(interval);
  }, [fetchTrips]);

  useEffect(() => {
    searchIn.tagGeofence();
  }, [searchIn.tagGeofence]);

  useEffect(() => {
    searchOut.tagGeofence();
  }, [searchOut.tagGeofence]);

  useEffect(() => {
    tagDate();
  }, [tagDate]);

  useEffect(() => {
    tagMachine();
  }, [tagMachine]);

  useEffect(() => {
    tagTelemetries();
  }, [tagTelemetries]);

  useEffect(() => {
    tagClients();
  }, [tagClients]);

  const openDetails = !!geofence;

  const handleOpenDetails = (
    geofenceClick: GeofenceWithMachines | Geofence
  ) => {
    if (isGeofenceWithMachines(geofenceClick)) {
      setGeofence(geofenceClick);
    }
  };

  const handleCloseDetails = () => {
    setGeofence(null);
  };

  const itemsFilter = [
    {
      label: "Data",
      action: () => setSearchDate(true),
    },
    {
      label: "Alvos de saída",
      action: () => searchOut.setSearchGeofences(true),
    },
    {
      label: "Alvos de entrada",
      action: () => searchIn.setSearchGeofences(true),
    },
    {
      label: sharedSentences.trucks,
      action: () => setSearchMachines(true),
    },
    {
      label: sharedSentences.telemetries,
      action: () => setSearchTelemetries(true),
    },
    {
      label: sharedSentences.clients,
      action: () => setSearchClients(true),
    },
  ];

  const removeFilter = (index: number) => {};

  return (
    <>
      <ContentWrapper>
        <Filters itemsFilter={itemsFilter} filtersTags={filters} />
        <WrapperBoards>
          <Listagem
            geofences={geofences}
            loading={loading}
            clickGeofence={handleOpenDetails}
          />
          <ListagemTrips trips={trips} loading={loadingTrips} />
        </WrapperBoards>
      </ContentWrapper>
      {geofence && (
        <DetailGeofence
          open={openDetails}
          onClose={handleCloseDetails}
          geofence={geofence}
        />
      )}
      {SearchDateRange()}
      {SearchMachines()}
      {SearchTelemetries()}
      {SearchClients()}
      {searchIn.SearchGeofences()}
      {searchOut.SearchGeofences()}
    </>
  );
};

export default Dashboard;
