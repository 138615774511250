import React, { SyntheticEvent } from "react";

import { Button, ButtonText, StyledSpinner } from "./styles";

interface ColorButtonProps {
  disabled?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  children?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  style?: React.CSSProperties;
}

const ColorButton = ({
  disabled,
  onClick,
  children,
  className,
  isLoading = false,
  style,
}: ColorButtonProps) => {
  return (
    <Button
      disabled={disabled}
      onClick={isLoading ? () => {} : onClick}
      className={className}
      style={style}
    >
      {isLoading ? (
        <StyledSpinner size="20px" />
      ) : (
        <ButtonText>{children}</ButtonText>
      )}
    </Button>
  );
};

export default ColorButton;
