import styled from "styled-components";

import { Paper } from "@material-ui/core";
import { H2SemiBold, H5SemiBold } from "BaseComponents/Typographies";

export const PaperInfo = styled(Paper)`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 10px;
`;
export const PaperTitle = styled(H5SemiBold)`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
`;
export const PaperSubtitle = styled(H2SemiBold)`
  color: ${(props) => props.theme.colors.layout.blackPrimary};
`;
