import React, { SyntheticEvent } from "react";

import { Button, ButtonText, StyledSpinner } from "./styles";

interface SecondaryButtonProps {
  disabled?: boolean;
  onClick?: (e: SyntheticEvent) => void;
  children?: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  type?: "button" | "submit" | "reset";
}

const SecondaryButton = ({
  disabled,
  onClick,
  children,
  className,
  isLoading = false,
  type,
}: SecondaryButtonProps) => {
  return (
    <Button
      type={type}
      disabled={disabled}
      onClick={isLoading ? () => {} : onClick}
      className={className}
    >
      {isLoading ? (
        <StyledSpinner size="20px" />
      ) : (
        <ButtonText>{children}</ButtonText>
      )}
    </Button>
  );
};

export default SecondaryButton;
