import React, { useCallback, useEffect, useRef } from "react";
import Header from "Components/Header";
import Caption, { CaptionItem } from "Components/Map/Caption";
import { toast } from "react-toastify";
import toasts from "Shared/toasts";
import { Content, ContentWrapper } from "./styles";

interface MapWrapperProps {
  children?: React.ReactNode;
  title: string;
  subtitle?: string;
  captionItems?: CaptionItem[];
  resized?: boolean;
}

const MapWrapper = ({
  children,
  title,
  subtitle,
  captionItems,
  resized = false,
}: MapWrapperProps) => {
  const elementMax = useRef<HTMLDivElement>(null);
  const fullScreen = useRef(false);

  const resizeWindow = useCallback(() => {
    if (resized) {
      elementMax.current
        ?.requestFullscreen()
        .then(() => {
          fullScreen.current = true;
        })
        .catch(() => {
          toasts.error("Não é possivel maximizar a janela!");
        });
    } else if (fullScreen) {
      if (document.fullscreenElement) {
        document
          .exitFullscreen()
          .then(() => {
            fullScreen.current = false;
          })
          .catch(() => {
            toasts.error("Erro ao minimizar janela!");
          });
      }
    }
  }, [resized]);

  useEffect(() => {
    resizeWindow();
  }, [resizeWindow]);

  return (
    <ContentWrapper ref={elementMax} resized={resized}>
      <Header title={title} subtitle={subtitle} />
      <Content>{children}</Content>
      {captionItems && <Caption items={captionItems} />}
    </ContentWrapper>
  );
};

export default MapWrapper;
