import React from "react";
import { Marker, useMap } from "react-leaflet";
import L, { LatLngExpression } from "leaflet";
import theme from "Styles/theme";
import { MachineLocation } from "declarations";
import Flag from "Assets/Icons/Flag.svg";
import PinLocation from "Assets/Icons/PinLocation.svg";
import PopupMarker from "./PopupMarker";

export type StatusType =
  | "initial"
  | "final"
  | "active"
  | "default"
  | "activeInitial"
  | "activeFinal";

const IconTravelPoint = (status: StatusType) => {
  let color;
  let flag = "";
  let postionIcon: L.Point | L.PointTuple | undefined;
  let positionPopup: L.Point | L.PointTuple | undefined;

  switch (status) {
    case "initial":
      color = theme.colors.layout.blackPrimary;
      flag = `<img class="my-div-image" src=${PinLocation} width="40px"/>`;
      postionIcon = [5, 50];
      break;
    case "final":
      color = theme.colors.layout.blackPrimary;
      flag = `<img class="my-div-image" style="margin-left: 45px;" src=${Flag} width="60px"/>`;
      postionIcon = [5, 70];
      break;

    case "active":
      color = theme.colors.interactive.primary;

      break;

    case "activeInitial":
      color = theme.colors.interactive.primary;
      flag = `<img class="my-div-image" src=${PinLocation} width="40px"/>`;
      postionIcon = [5, 50];
      break;
    case "activeFinal":
      color = theme.colors.interactive.primary;
      flag = `<img class="my-div-image" style="margin-left: 45px;" src=${Flag} width="60px"/>`;
      postionIcon = [5, 70];
      break;

    default:
      color = theme.colors.layout.blackSecondary;

      break;
  }

  return L.divIcon({
    className: "my-div-icon",
    iconAnchor: postionIcon,
    html: `<div style="display: flex;
    flex-direction: column;
    align-items: center;">${flag}<div style="opacity: 90%; width: 20px; height: 20px; border: 2px solid white; background: ${color}; border-radius:50%;" /></div>`,
  });
};

interface MarkerPathProps {
  value: MachineLocation;
  title?: string;
  status: StatusType;
}

const MarkerPath = ({ value, title, status }: MarkerPathProps) => {
  const map = useMap();
  const position: LatLngExpression = {
    lat: value.location.coordinates[1],
    lng: value.location.coordinates[0],
  };

  const handleClick = () => {
    map.flyTo(position, map.getZoom());
  };

  return (
    <Marker
      position={position}
      title={title}
      icon={IconTravelPoint(status)}
      eventHandlers={{ click: handleClick }}
    >
      <PopupMarker location={value} />
    </Marker>
  );
};

export default MarkerPath;
