/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import MapContainer from "Components/Map";
import { MapConsumer } from "react-leaflet";
import Geofence from "Components/Map/Geofences/Geofence";
import Icon from "Assets/Icons/IconTruck.svg";
import IconExceeded from "Assets/Icons/IconTruckExceeded.svg";
import IconSpeed from "Assets/Icons/IconTruckSpeed.svg";
import IconExceededAndSpeed from "Assets/Icons/IconTruckExceededAndSpeed.svg";
import IconLongTimeUpdate from "Assets/Icons/IconTruckLongTimeUpdate.svg";
import useSearchGeofences from "Components/TableOptions/SearchGeofences/useSearchGeofences";
import { IFilter } from "Components/TableOptions";
import HeatMap from "Components/Map/HeatMap";

import { ExceedSpeed } from "declarations";
import useExceedSpeed from "Hooks/useExceedSpeed";
import useSearchDate from "Components/TableOptions/SearchDate/useSearchDate";
import { DateRangeType } from "Components/TableOptions/SearchDate";
import dayjs from "dayjs";
import theme from "Styles/theme";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { Listagem, Filters } from "./styles";

const initDateRange: DateRangeType = {
  startDate: dayjs(new Date()).subtract(7, "days").toDate(),
  endDate: dayjs(new Date()).endOf("day").toDate(),
  color: theme.colors.interactive.primary,
  key: "selection",
  showDateDisplay: false,
};

const LiveMap = () => {
  const [listData, setListData] = useState<ExceedSpeed[]>([]);
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState<IFilter[]>([]);

  const { SearchDateRange, dateRange, tagDate, setSearchDate } = useSearchDate({
    filters,
    setFilters,
    defaultRange: initDateRange,
  });

  const { fetchAllData } = useExceedSpeed({
    setListCrud: setListData,
    setLoading,
    dateRange,
  });

  const points = listData.map((value) => value.machine.location.coordinates);

  useEffect(() => {
    fetchAllData();
  }, [dateRange]);

  useEffect(() => {
    tagDate();
  }, [tagDate]);

  const subtitleScreen =
    listData.length > 1
      ? `(${listData.length} pontos)`
      : `(${listData.length} ponto)`;

  const itemsFilter = [
    {
      label: "Data",
      action: () => setSearchDate(true),
    },
  ];

  const removeFilter = (index: number) => {
    if (filters[index].name === "date") {
      setSearchDate(true);
    }
  };

  return (
    <MapContainer
      title="Monitoramento - Mapa de Calor"
      subtitle={subtitleScreen}
      center={[-20.54213711944774, -43.74270918881854]}
      zoom={13}
      positionZoomControl="bottomleft"
    >
      <Backdrop open={loading} style={{ zIndex: 800 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <HeatMap points={points} />
      <Filters
        itemsFilter={itemsFilter}
        removeTag={removeFilter}
        filtersTags={filters}
      />
      {SearchDateRange()}
    </MapContainer>
  );
};

export default LiveMap;
