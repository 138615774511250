import React, { useCallback, useEffect, useState } from "react";
// @ts-ignore
import createTrend from "trendline";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  ReferenceLine,
  BarChart,
} from "recharts";
import { fromMilliseconds, toMilliseconds } from "Shared/utils";
import theme from "Styles/theme";

export interface IData {
  name: string;
  barValue: number;
}

export interface ITrend extends IData {
  line?: number;
}

export interface IReferenceLine {
  value: number;
  label: string;
  stroke: string;
}

interface BarWithLineProps {
  data: IData[];
  referenceLines?: IReferenceLine[];
  lineLabel?: string;
  barLabel?: string;
}

interface ISegment {
  x?: number | string;
  y?: number | string;
}

const BarWithLine = ({
  data,
  referenceLines = [],
  lineLabel = "lineLabel",
  barLabel = "barLabel",
}: BarWithLineProps) => {
  const [segment, setSegment] = useState<ITrend[]>([]);
  const numberFormat = (value: any) => {
    return fromMilliseconds(value as number);
  };

  const generateTrendLine = useCallback(() => {
    let results: ITrend[] = [];

    if (data.length > 0) {
      const trend = createTrend(data, "name", "barValue");

      results = data.map((value) => {
        return {
          ...value,
          line: trend.calcY(value.name),
        };
      });
    }

    setSegment(results);
  }, [data]);

  useEffect(() => {
    generateTrendLine();
  }, [generateTrendLine]);

  const CustomLabel = (label: string) => <span>{label}</span>;

  return (
    <ResponsiveContainer>
      <ComposedChart width={500} height={400} data={segment}>
        <CartesianGrid stroke={theme.colors.layout.whiteTertiary} />
        <XAxis dataKey="name" scale="band" />
        <YAxis tickFormatter={numberFormat}>
          <Label formatter={numberFormat} />
        </YAxis>
        <Tooltip formatter={numberFormat} />
        <Legend />

        <Bar
          dataKey="barValue"
          name={barLabel}
          barSize={20}
          fill={theme.colors.layout.blackSecondary}
        />

        {referenceLines.map((line) => (
          <ReferenceLine
            y={line.value}
            stroke={line.stroke}
            label={
              <Label
                value={line.label}
                position="top"
                fill={theme.colors.denotative.success}
                fontWeight={600}
              />
            }
            strokeDasharray="3 3"
            isFront={true}
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default BarWithLine;
