import Table, {
  Col as ColTable,
  Option as OptionTable,
} from "BaseComponents/Table";
import dayjs from "dayjs";
import { useLocation } from "react-router";
import Pagination from "BaseComponents/Table/Pagination";
import TableOptions, { ICheck } from "Components/TableOptions";
import PageWrapper from "Components/Wrappers/PageWrapper";
import { Permanence } from "declarations";
import useCrud from "Hooks/useCrud";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import toasts from "Shared/toasts";
import useSearchDate from "Components/TableOptions/SearchDate/useSearchDate";
import useSearchGeofences from "Components/TableOptions/SearchGeofences/useSearchGeofences";
import useSearchMachines from "Components/TableOptions/SearchMachines/useSearchMachines";
import { downloadFile, fromMilliseconds } from "Shared/utils";
import useSearchClients from "Components/TableOptions/SearchClients/useSearchClients";
import sharedSentences from "Shared/sentences";
import { getPermanence, getPermanenceCSV } from "Api/Permanence";
import theme from "Styles/theme";
import { DateRangeType } from "Components/TableOptions/SearchDate";
import { ContentWrapper } from "./styles";

const fields = [
  [sharedSentences.truck, "machine.plaque"],
  ["Alvo", "geofence.name"],
  ["Data de Entrada", "dateIn"],
  ["Data de Saída", "dateOut"],
  ["Tempo de Permanência", "dateDifference", "timestamp"],
  [sharedSentences.telemetry, "machine.telemetry"],
  [sharedSentences.client, "machine.client"],
];

const initDateRange: DateRangeType = {
  startDate: dayjs().subtract(1, "weeks").toDate(),
  endDate: dayjs().endOf("day").toDate(),
  color: theme.colors.interactive.primary,
  key: "selection",
  showDateDisplay: false,
};

const optionsRowsPerPage = [10, 20, 30];

const LocalProibido = () => {
  const [groupBy, setGroupBy] = useState<string | null>(null);

  const {
    listCrud,
    setListCrud,
    totalCrud,
    setTotalCrud,
    loading,
    setLoading,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    filters,
    setFilters,
  } = useCrud<Permanence>();

  
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const { SearchGeofences, filtersGeofences, tagGeofence, setSearchGeofences } =
    useSearchGeofences({ filters, setFilters });

  const { SearchMachines, filtersMachines, setSearchMachines, tagMachine } =
    useSearchMachines({ filters, setFilters, defaultMachines: query.get('machines')?.split(',') });

  const { SearchClients, filtersClients, setSearchClients, tagClients } =
    useSearchClients({
      filters,
      setFilters,
      defaultClients: query.get("clients")?.split(","),
    });

  const dateIn = useSearchDate({
    filters,
    setFilters,
    name: "dateIn",
  });

  const dateOut = useSearchDate({
    filters,
    setFilters,
    name: "dateOut",
    defaultRange: {
      ...initDateRange,
      startDate: query.get('startDate') ? new Date(query.get('startDate')!) : initDateRange.startDate,
      endDate: query.get('endDate') ? new Date(query.get('endDate')!) : initDateRange.endDate,
    }
  });

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const rangeIn = dateIn.dateRange
        ? [
            dateIn.dateRange.startDate.toISOString(),
            dateIn.dateRange.endDate.toISOString(),
          ]
        : undefined;
      const rangeOut = dateOut.dateRange
        ? [
            dateOut.dateRange.startDate.toISOString(),
            dateOut.dateRange.endDate.toISOString(),
          ]
        : undefined;
  
      const response = await getPermanence({
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        plaques: filtersMachines,
        geofences: filtersGeofences,
        clients: filtersClients,
        dateIn: rangeIn,
        dateOut: rangeOut,
        geofenceAllowed: false,
        groupBy,
      });
      setListCrud(response.data);
      setTotalCrud(response.total);
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    dateIn.dateRange,
    dateOut.dateRange,
    rowsPerPage,
    page,
    filtersMachines,
    filtersGeofences,
    setListCrud,
    setTotalCrud,
    filtersClients,
    groupBy
  ]);

  const fetchDataCSV = async () => {
    try {
      setLoading(true);

      const rangeIn = dateIn.dateRange
        ? [
            dateIn.dateRange.startDate.toISOString(),
            dateIn.dateRange.endDate.toISOString(),
          ]
        : undefined;
      const rangeOut = dateOut.dateRange
        ? [
            dateOut.dateRange.startDate.toISOString(),
            dateOut.dateRange.endDate.toISOString(),
          ]
        : undefined;

      const response = await getPermanenceCSV(
        fields,
        999999,
        0,
        filtersMachines,
        filtersGeofences,
        filtersClients,
        rangeIn,
        rangeOut,
        false,
        groupBy
      );

      downloadFile(response, "LocalProibido.csv");
      toasts.success(
        "Solicitação de exportação realizada com sucesso! O download deve começar em breve e pode demorar alguns minutos."
      );
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData, groupBy]);

  useEffect(() => {
    tagMachine();
  }, [tagMachine]);

  useEffect(() => {
    tagGeofence();
  }, [tagGeofence]);

  useEffect(() => {
    dateIn.tagDate();
  }, [dateIn.tagDate]);

  useEffect(() => {
    dateOut.tagDate();
  }, [dateOut.tagDate]);

  useEffect(() => {
    tagClients();
  }, [tagClients]);

  const colsTable: ColTable[] = [
    { name: "machine.plaque", label: sharedSentences.truck, align: "left" },
    { name: "geofence.name", label: "Alvo", align: "left" },
    {
      name: "machine.telemetry",
      label: sharedSentences.telemetry,
      align: "left",
    },
    { name: "machine.client", label: sharedSentences.client, align: "left" },
    { name: "dateIn", label: "Data de entrada", align: "left" },
    { name: "dateOut", label: "Data de saída", align: "left" },
    {
      name: "dateDifference",
      label: "Tempo de permanência",
      align: "left",
      format: (value: any) => `${fromMilliseconds(value)}`,
    },
  ];

  const itemsFilter = [
    {
      label: sharedSentences.trucks,
      action: () => setSearchMachines(true),
    },
    {
      label: "Alvos",
      action: () => setSearchGeofences(true),
    },
    {
      label: "Data de Entrada",
      action: () => dateIn.setSearchDate(true),
    },
    {
      label: "Data de Saída",
      action: () => dateOut.setSearchDate(true),
    },
    {
      label: sharedSentences.client,
      action: () => setSearchClients(true),
    },
  ];

  const handlePage = (pageNumber: number) => {
    setPage(pageNumber - 1);
  };

  const handleRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
  };

  const removeFilter = (index: number) => {
    if (filters[index].name === "machines") {
      setSearchMachines(true);
    }
    if (filters[index].name === "dateIn") {
      dateIn.setSearchDate(true);
    }
    if (filters[index].name === "dateOut") {
      dateOut.setSearchDate(true);
    }
    if (filters[index].name === "geofences") {
      setSearchGeofences(true);
    }
  };

  const onCheck = (check: boolean) => {
    setGroupBy(check ? "plaqueByHour" : null);
  }

  const itensCheck: ICheck[] = [
    {
      check: groupBy === "plaqueByHour",
      label: "Agrupar por placa e hora",
      onCheck,
    },
  ];

  return (
    <PageWrapper title="Local Proibido">
      <ContentWrapper>
        <TableOptions
          itemsCheck={itensCheck}
          itemsFilter={itemsFilter}
          filtersTags={filters}
          removeTag={removeFilter}
          onExportToCSV={fetchDataCSV}
        />
        <Table loading={loading} cols={colsTable} data={listCrud} />
      </ContentWrapper>
      <Pagination
        count={totalCrud}
        onChangePage={handlePage}
        onChangeRowsPerPage={handleRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        optionsRowsPerPage={optionsRowsPerPage}
      />
      {dateIn.SearchDateRange()}
      {dateOut.SearchDateRange()}
      {SearchMachines()}
      {SearchGeofences()}
      {SearchClients()}
    </PageWrapper>
  );
};

export default LocalProibido;
