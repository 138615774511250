import React, { useEffect, useState } from "react";
import DateTimePicker from "BaseComponents/Calendar/DateTimePicker";
import { LocalShipping, SearchOutlined } from "@material-ui/icons";
import useSearchSingleMachine from "Components/TableOptions/SearchMachines/useSearchSingleMachine";
import sharedSentences from "Shared/sentences";
import { Wrapper, StyledButton, StyledMachineButton } from "./styles";

interface SearchPathProps {
  dateIn: Date | null;
  dateEnd: Date | null;
  setDateIn: (value: Date) => void;
  setDateEnd: (value: Date) => void;
  onClick: () => void;
  setMachine?: (value: string) => void;
  machine?: string;
}

const SearchPath = ({
  dateEnd,
  dateIn,
  setDateEnd,
  setDateIn,
  onClick,
  setMachine,
  machine = `Escolher ${sharedSentences.truck}`,
}: SearchPathProps) => {
  const { SearchMachines, filtersMachines, handleOpen } =
    useSearchSingleMachine();

  const disableDateEnd = !dateIn;

  useEffect(() => {
    if (setMachine && filtersMachines[0]) setMachine(filtersMachines[0]);
  }, [filtersMachines, setMachine]);

  return (
    <Wrapper>
      {setMachine && (
        <StyledMachineButton onClick={handleOpen}>
          <LocalShipping />
          {machine}
        </StyledMachineButton>
      )}
      <DateTimePicker date={dateIn} setDate={setDateIn} label="Data inicial" />
      <DateTimePicker
        date={dateEnd}
        setDate={setDateEnd}
        label="Data Final"
        disabled={disableDateEnd}
        minDate={dateIn || undefined}
        minDateMessage="Data não pode ser menor que a data inicial"
      />
      <StyledButton onClick={onClick}>
        <SearchOutlined />
        Buscar
      </StyledButton>
      {SearchMachines()}
    </Wrapper>
  );
};

export default SearchPath;
