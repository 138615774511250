import React, { useState, useEffect } from "react";
import { Polygon, Tooltip, useMap } from "react-leaflet";
import { ForbiddenDestination } from "declarations";
import {
  formatCoordinates,
  center,
  fromMilliseconds,
  diffTimeToString,
} from "Shared/utils";
import {
  Block,
  CheckCircle,
  ScheduleOutlined,
  Speed,
} from "@material-ui/icons";
import theme from "Styles/theme";
import {
  InfoWrapper,
  PopupMarker,
  Title,
  TitleWrapper,
  ItemInfo,
  TitleInfo,
  Info,
} from "./styles";

interface DestinationProps {
  destination: ForbiddenDestination;
  popup?: boolean;
  colorPolygon?: string;
}

const Destination = ({
  destination,
  popup,
  colorPolygon,
}: DestinationProps) => {
  const map = useMap();

  const color = colorPolygon || theme.colors.denotative.medium;

  const handleClick = () => {
    map.flyTo(center(destination.coordinates.coordinates));
  };

  return (
    <Polygon
      positions={formatCoordinates(destination.coordinates.coordinates)}
      pathOptions={{ color }}
      eventHandlers={{ click: handleClick }}
    >
      <Tooltip direction="bottom" offset={[0, 20]} opacity={1}>
        {destination.name}
      </Tooltip>
    </Polygon>
  );
};

export default Destination;
