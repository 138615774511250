import { MenuItem } from "@material-ui/core";
import React from "react";
import { StyledSelect } from "./styles";

interface Item<T> {
  value: T;
  label: string;
}

interface ISelectProps<T> {
  items: Item<T>[];
  onChange?: (value: T) => void;
}

const Select = <T,>({ items, onChange }: ISelectProps<T>) => {
  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    event.preventDefault();
    onChange && onChange(items[event.target.value as number].value);
  };

  return (
    <StyledSelect onChange={handleChange}>
      {items.map((item, index) => (
        <MenuItem key={item.label} value={index}>
          {item.label}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default Select;
