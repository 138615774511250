import React from "react";
import CheckBox from "../CheckBox";
import { StyledForm } from "./styles";

interface CheckBoxProps {
  checked: boolean;
  indeterminate?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  label: string;
}

const CheckBoxWithLabel = ({
  checked,
  indeterminate,
  onChange,
  label,
}: CheckBoxProps) => {
  return (
    <StyledForm
      control={
        <CheckBox
          checked={checked}
          onChange={onChange}
          indeterminate={indeterminate}
        />
      }
      label={label}
    />
  );
};

export default CheckBoxWithLabel;
