import styled from "styled-components";
import { IconButton } from "@material-ui/core";

export const Content = styled.div`
  background-color: ${(props) => props.theme.colors.layout.whitePrimary};
  border-radius: 8px;
  min-height: 40px;
  max-height: 40px;
  overflow: hidden;
  opacity: 1;
  outline: 0;
  border: 2px solid ${(props) => props.theme.colors.interactive.primary};
  margin: 0 6px;

  cursor: pointer;

  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  &:focus {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }
  &:hover::before {
    opacity: 0.08;
  }
  &:focus::before {
    opacity: 0.24;
  }
  &:hover:focus::before {
    opacity: 0.3;
  }
`;

export const Button = styled(IconButton)`
  padding: 2px !important;
  margin-left: 4px !important;
  margin: 0;

  svg {
    width: 18px;
  }

  z-index: 200;
`;

export const TextButton = styled.p`
  font-size: 14px;
  font-weight: 600;

  color: ${(props) => props.theme.colors.layout.blackPrimary};
`;
