import styled from "styled-components";
import WindowOptionButton from "BaseComponents/Buttons/WindowOptionButton";

export const Wrapper = styled.div<{ minimize: boolean }>`
  display: flex;
  flex-direction: column;
  background: white;

  border-radius: 8px;
  overflow-y: hidden;
  min-width: 220px;

  height: ${(props) => (props.minimize ? "40px" : "100%")};
  transition: height linear 0.5s;
`;

export const Header = styled.div`
  background: ${(props) => props.theme.colors.layout.blackPrimary};
  color: ${(props) => props.theme.colors.layout.whitePrimary};
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 10px;
`;

export const ListWrapper = styled.div<{ minimize: boolean }>`
  visibility: ${(props) => (props.minimize ? "hidden" : "unset")};
  margin: ${(props) => (props.minimize ? "0 10px" : "10px")};
  transition: visibility linear 0.5s, margin linear 0.5s;

  overflow-y: auto;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    background: white;
    width: 24px;
  }
  ::-webkit-scrollbar-thumb {
    background: #ced2d5 0% 0% no-repeat padding-box;
    border-radius: 20px;
    border: 4px solid #fff;
  }
`;

export const Button = styled(WindowOptionButton)`
  float: right;
  outline: none;

  &:focus {
    outline: none;
  }
`;
