import styled from "styled-components";
import background from "Assets/Images/background.png";

export const Box = styled.div`
  background: url(${background}) no-repeat;
  background-size: cover;
  height: 100vh;
`;
export const Container = styled.div`
  @media (min-width: 320px) {
    display: flex;
    justify-content: center;
    padding-right: 0px;
    align-items: center;
  }
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 992px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 100px;
  }
  height: 100%;
`;
export const Card = styled.div`
  @media (min-width: 320px) {
    padding: 20px;
    min-width: 240px;
  }
  @media (min-width: 375px) {
    padding: 30px;
    min-width: 260px;
  }
  @media (min-width: 768px) {
    min-width: 280px;
    padding: 64px;
  }
  @media (min-width: 992px) {
    min-width: 360px;
  }
  background: ${(props) => props.theme.colors.layout.whitePrimary} 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px ${(props) => props.theme.colors.layout.shadow};
  border-radius: 8px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Form = styled.form`
  width: 100%;
`;
export const ButtomWrapper = styled.div`
  margin-top: 24px;
`;
export const ImageWrapper = styled.div`
  margin-bottom: 60px;
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const ImageLogo = styled.img`
  width: 60%;
  align-self: center;
`;
