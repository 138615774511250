import React from "react";
import { TileLayer } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

const DefaultLayers = () => {
  return (
    <>
      <TileLayer
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        keepBuffer={10}
        updateWhenZooming={false}
        updateWhenIdle={false}
        maxZoom={20}
      />
      <TileLayer
        url={`https://api.mapbox.com/v4/keeptracking-waf.6i5fdi7u/{z}/{x}/{y}.png?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
        keepBuffer={10}
        updateWhenZooming={false}
        updateWhenIdle={false}
      />

      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png"
        keepBuffer={10}
        updateWhenZooming={false}
        updateWhenIdle={false}
      />
    </>
  );
};

export default DefaultLayers;
