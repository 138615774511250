import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { FormControlLabel, Switch } from "@material-ui/core";
import { timeMask } from "Shared/masks";
import Button from "BaseComponents/Buttons/HeaderButton";
import { Geofence } from "declarations";
import ItemOrigin from "Components/Map/ForbiddenDestinations/ItemOrigin";
import {
  ButtonAction,
  ButtonsWrapper,
  EditWrapper,
  InputName,
  ItemWrapper,
  Label,
  ListOrigins,
  AddOrigin,
  LabelOrigins,
  DeleteIcon,
  AddIcon,
  ErrorText,
} from "../styles";
import ModalOrigins from "../ModalOrigins";
import { MandatoryRoutesValidation } from "../Validation";

interface FormShape {
  name: string;
  route: Geofence[];
}

interface CreateProps {
  onSubmit: (data: FormShape) => void;
  loading: boolean;
  cancelCreate: () => void;
  originsToCreate?: (value: Geofence[]) => void;
}

const Create = ({
  onSubmit,
  loading,
  cancelCreate,
  originsToCreate,
}: CreateProps) => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
    watch,
    getValues,
    formState,
  } = useForm<FormShape>({
    resolver: yupResolver(MandatoryRoutesValidation),
  });
  const [openModal, setOpenModal] = useState(false);
  const route = watch("route", []);

  const toggleOpenModal = () => {
    setOpenModal(true);
  };

  const toggleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    register("route");
  }, [register]);

  const setGeofences = (value: Geofence[]) => {
    setValue("route", [...value]);
    originsToCreate && originsToCreate(getValues("route"));
  };

  const addGeofence = (geofence: Geofence) => {
    const findIndex = route.findIndex((value) => value.name === geofence.name);

    if (findIndex < 0) {
      setGeofences([...route, geofence]);
    }
  };

  const removeOrigin = (geofence: Geofence) => {
    const findIndex = route.findIndex((value) => value.name === geofence.name);

    if (findIndex >= 0) {
      const newArray = [...route];

      newArray.splice(findIndex, 1);

      setGeofences(newArray);
    }
  };

  const submit = handleSubmit(async (data) => {
    if (loading) return;

    onSubmit({ ...data });
  });

  return (
    <EditWrapper onSubmit={submit}>
      <InputName
        label="Nome da rota obrigatória"
        type="text"
        name="name"
        errorText={errors.name?.message || ""}
        ref={register()}
      />
      <ListOrigins>
        <AddOrigin>
          <LabelOrigins>Pontos da rota</LabelOrigins>
          <Button onClick={toggleOpenModal}>
            <AddIcon />
          </Button>
        </AddOrigin>
        {route.map((value) => (
          <ItemOrigin geofence={value} onRemove={removeOrigin} />
        ))}
        {/* @ts-ignore */}
        {errors.route && <ErrorText>{errors.route.message}</ErrorText>}
      </ListOrigins>
      <ButtonsWrapper>
        <ButtonAction onClick={cancelCreate}>Cancelar</ButtonAction>
        <ButtonAction type="submit" isLoading={loading}>
          {" "}
          Salvar
        </ButtonAction>
      </ButtonsWrapper>
      <ModalOrigins
        open={openModal}
        onClose={toggleCloseModal}
        originsSelected={route}
        addOrigin={addGeofence}
        removeOrigin={removeOrigin}
        titleSelectedGeofences="Sequência de rota"
      />
    </EditWrapper>
  );
};

export default Create;
