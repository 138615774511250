import PrimaryButton from "BaseComponents/Buttons/PrimaryButton";
import SecondaryButton from "BaseComponents/Buttons/SecondaryButton";
import { Geofence } from "declarations";
import React from "react";
import { Wrapper, Label, WrapperButtons } from "./styles";

interface SelectOriginProps {
  origin?: Geofence;
  onConfirm?: (origin: Geofence) => void;
  onCancel?: () => void;
  titleSelectedGeofences?: string;
}

const SelectOrigin = ({
  origin,
  onConfirm,
  onCancel,
  titleSelectedGeofences = "lista",
}: SelectOriginProps) => {
  return (
    <Wrapper hidden={!origin}>
      <Label>
        {`Deseja adicionar ${
          origin?.name
        } em ${titleSelectedGeofences.toLowerCase()}?`}
      </Label>
      <WrapperButtons>
        <PrimaryButton onClick={() => origin && onConfirm && onConfirm(origin)}>
          Sim
        </PrimaryButton>
        <SecondaryButton onClick={onCancel}>Não</SecondaryButton>
      </WrapperButtons>
    </Wrapper>
  );
};

export default SelectOrigin;
