/* eslint-disable no-underscore-dangle */
import { ResponseSettings, Settings, SettingsWithoutID } from "declarations";
import feathers from "./Feathers";

const settingsService = feathers.service("configurations");

export const getSettings = (): Promise<ResponseSettings> =>
  settingsService.find({});

export const updateSettings = (
  id: string,
  settings: SettingsWithoutID
): Promise<Settings> => settingsService.update(id, { ...settings });
