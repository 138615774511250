/* eslint-disable no-underscore-dangle */
import Button from "BaseComponents/Buttons/HeaderButton";
import SimpleSpinner from "BaseComponents/Spinners/SimpleSpinner";
import UserContext from "Contexts/User";
import toasts from "Shared/toasts";
import { center } from "Shared/utils";
import {
  Unit
} from "declarations";
import { Layer } from "leaflet";
import React, { useContext, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useMap } from "react-leaflet";
import { v4 as uuidv4 } from "uuid";

import { deleteUnit } from "Api/Units";
import Create from "./Crud";
import {
  AddIcon,
  DeleteIcon,
  Header,
  ItemWrapper,
  Label,
  List,
  ListWrapper,
  StyledAvatar,
  Wrapper
} from "./styles";

interface CrudUnitProps {
  units: Unit[];

  remove?: (id: string) => void;
  creating?: number[][];
  cancel?: () => void;
  layer?: Layer;
  loading: boolean;
  pages?: number;
  hasNextPage: boolean;
  fetchUnits?: (skip: number) => Promise<void>;
  selectedUnit?: (values: Unit) => void;
  loadMore: () => void;
}

const CrudUnit = ({
  units,
  remove,
  creating,
  cancel,
  layer,
  loading,
  loadMore,
  pages,
  hasNextPage,
  selectedUnit = () => {},
  fetchUnits = () => Promise.resolve(),
}: CrudUnitProps) => {
  const map = useMap();
  const [openCrud, setOpenCrud] = useState(false);
  const { isAdmin } = useContext(UserContext);

  const handleOpenCrud = () => {
    setOpenCrud(true);
  };

  const handleCloseCrud = () => {
    setOpenCrud(false);
  };

  const handleClick = (unit: Unit) => {
    const point = center(unit.owner.coordinates.coordinates);

    selectedUnit(unit);

    map.flyTo(point);
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
  });

  const successCreate = async () => {
    await fetchUnits(0);
    setOpenCrud(false);
  };

  const removeUnit = async (id: string) => {
    try {
      await deleteUnit(id);
      await fetchUnits(0);
      toasts.success("Unidade removida com sucesso!");
    } catch (error) {
      toasts.error((error as Error).message);
    }
  };

  const content = () => {
    return (
      <>
        <Header>Listagem</Header>
        <ListWrapper ref={rootRef}>
          <ItemWrapper>
            <Label>Adicionar</Label>

            <Button onClick={handleOpenCrud}>
              <AddIcon onClick={handleOpenCrud} />
            </Button>
          </ItemWrapper>
          <List>
            {units.map((unit, index) => {
              return (
                <ItemWrapper key={unit._id || uuidv4()}>
                  <StyledAvatar exceeded={false}>
                    {unit.name[0].toUpperCase()}
                  </StyledAvatar>
                  <Label onClick={() => handleClick(unit)}>{unit.name}</Label>
                  {isAdmin && (
                    <>
                      <Button onClick={() => removeUnit(unit._id || "")}>
                        <DeleteIcon />
                      </Button>
                    </>
                  )}
                </ItemWrapper>
              );
            })}
            {loading && <SimpleSpinner ref={sentryRef} />}
          </List>
        </ListWrapper>
        <Create
          onClose={handleCloseCrud}
          open={openCrud}
          handleSuccess={successCreate}
        />
      </>
    );
  };

  return <Wrapper>{content()}</Wrapper>;
};

export default CrudUnit;
