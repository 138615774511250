import Table, {
  Col as ColTable,
  Option as OptionTable,
} from "BaseComponents/Table";
import Pagination from "BaseComponents/Table/Pagination";
import TableOptions from "Components/TableOptions";
import PageWrapper from "Components/Wrappers/PageWrapper";
import { TripDetails, TripInfo } from "declarations";
import useCrud from "Hooks/useCrud";
import React, { useCallback, useEffect } from "react";
import toasts from "Shared/toasts";
import useSearchGeofences from "Components/TableOptions/SearchGeofences/useSearchGeofences";
import useSearchMachines from "Components/TableOptions/SearchMachines/useSearchMachines";
import useSearchDate from "Components/TableOptions/SearchDate/useSearchDate";
import useTruckPath from "Components/Map/TruckPath/useTruckPath";
import { MapOutlined } from "@material-ui/icons";

import useSearchClients from "Components/TableOptions/SearchClients/useSearchClients";
import { getTrips, getTripsDetails, getTripsDetailsCSV } from "Api/Trip";
import { DateRangeType } from "Components/TableOptions/SearchDate";
import dayjs from "dayjs";
import theme from "Styles/theme";
import useSearchTelemetries from "Components/TableOptions/SearchTelemetries/useSearchTelemetries";
import { downloadFile, fromMilliseconds } from "Shared/utils";
import sharedSentences from "Shared/sentences";
import { ContentWrapper } from "./styles";

const optionsRowsPerPage = [10, 20, 30];

const formatTime = (value: any) => {
  return fromMilliseconds(value);
};

const initDateRange: DateRangeType = {
  startDate: dayjs(new Date()).startOf("day").toDate(),
  endDate: dayjs(new Date()).endOf("day").toDate(),
  color: theme.colors.interactive.primary,
  key: "selection",
  showDateDisplay: false,
};

const fields = [
  [sharedSentences.truck, "machineIn.name"],
  ["Entrada", "geofenceIn"],
  ["Saída", "geofenceOut"],
  ["Duração da viagem", "timeTrip", "timestamp"],
  [sharedSentences.client, "machineIn.client"],
];

const Viagens = () => {
  const {
    listCrud,
    setListCrud,
    totalCrud,
    setTotalCrud,
    loading,
    setLoading,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    filters,
    setFilters,
  } = useCrud<TripDetails>();

  const searchIn = useSearchGeofences({
    filters,
    setFilters,
    customLabel: "Alvos de entrada",
    customName: "searchIn",
  });

  const searchOut = useSearchGeofences({
    filters,
    setFilters,
    customLabel: "Alvos de saída",
    customName: "searchOut",
  });

  const {
    SearchMachines,
    filtersMachines,
    setSearchMachines,
    tagMachine,
    clearFilters,
  } = useSearchMachines({ filters, setFilters });

  const { SearchClients, filtersClients, setSearchClients, tagClients } =
    useSearchClients({ filters, setFilters });

  const { SearchDateRange, dateRange, setSearchDate, tagDate } = useSearchDate({
    filters,
    setFilters,
    defaultRange: initDateRange,
  });

  const range = dateRange || initDateRange;

  const {
    SearchTelemetries,
    filtersTelemetries,
    setSearchTelemetries,
    tagTelemetries,
  } = useSearchTelemetries({ filters, setFilters });

  const { TruckPathModal, onSearchOnModal } = useTruckPath({});

  const fetchTrips = useCallback(async () => {
    try {
      setLoading(true);

      const response = await getTripsDetails(
        range.startDate,
        range.endDate,
        filtersMachines,
        filtersTelemetries,
        filtersClients,
        searchIn.filtersGeofences,
        searchOut.filtersGeofences,
        page * rowsPerPage,
        rowsPerPage
      );

      setListCrud([...response.data]);
      setTotalCrud(response.total);
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    range.startDate,
    range.endDate,
    filtersMachines,
    filtersTelemetries,
    filtersClients,
    searchIn.filtersGeofences,
    searchOut.filtersGeofences,
    page,
    rowsPerPage,
    setListCrud,
    setTotalCrud,
  ]);

  const fetchTripsCSV = useCallback(async () => {
    try {
      setLoading(true);

      const response = await getTripsDetailsCSV(
        fields,
        range.startDate,
        range.endDate,
        filtersMachines,
        filtersTelemetries,
        filtersClients,
        searchIn.filtersGeofences,
        searchOut.filtersGeofences
      );

      downloadFile(response, "Viagens.csv");
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    range.startDate,
    range.endDate,
    filtersMachines,
    filtersTelemetries,
    filtersClients,
    searchIn.filtersGeofences,
    searchOut.filtersGeofences,
  ]);

  useEffect(() => {
    fetchTrips();
  }, [fetchTrips]);

  useEffect(() => {
    tagMachine();
  }, [tagMachine]);

  useEffect(() => {
    searchIn.tagGeofence();
  }, [searchIn.tagGeofence]);

  useEffect(() => {
    searchOut.tagGeofence();
  }, [searchOut.tagGeofence]);

  useEffect(() => {
    tagDate();
  }, [tagDate]);

  useEffect(() => {
    tagClients();
  }, [tagClients]);

  useEffect(() => {
    tagTelemetries();
  }, [tagTelemetries]);

  const colsTable: ColTable[] = [
    { name: "machineIn.name", label: sharedSentences.truck, align: "left" },
    { name: "geofenceIn", label: "Entrada", align: "left" },
    { name: "geofenceOut", label: "Saída", align: "left" },
    {
      name: "timeTrip",
      label: "Tempo de viagem",
      align: "left",
      format: formatTime,
    },
    {
      name: "machineIn.client",
      label: sharedSentences.client,
      align: "left",
    },
  ];

  const itemsFilter = [
    {
      label: sharedSentences.trucks,
      action: () => setSearchMachines(true),
    },
    {
      label: "Alvos de entrada",
      action: () => searchIn.setSearchGeofences(true),
    },
    {
      label: "Alvos de saída",
      action: () => searchOut.setSearchGeofences(true),
    },
    {
      label: "Data",
      action: () => setSearchDate(true),
    },
    {
      label: sharedSentences.clients,
      action: () => setSearchClients(true),
    },
    {
      label: sharedSentences.telemetries,
      action: () => setSearchTelemetries(true),
    },
  ];

  const handlePage = (pageNumber: number) => {
    setPage(pageNumber - 1);
  };

  const handleRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
  };

  const searchPath = async (data: any) => {
    const dateIn = new Date(data.dateInOnGeofenceOut);

    const dateEnd = new Date(data.dateIn);

    await onSearchOnModal(data.machineIn.plaque, dateIn, dateEnd);
  };

  const optionsTable: OptionTable[] = [
    {
      label: "Ver trajeto",
      action: (itemData) => searchPath(itemData),
      Icon: <MapOutlined />,
    },
  ];

  return (
    <PageWrapper title="Relatório de viagens">
      <ContentWrapper>
        <TableOptions
          itemsFilter={itemsFilter}
          filtersTags={filters}
          onExportToCSV={fetchTripsCSV}
        />
        <Table<TripDetails>
          loading={loading}
          cols={colsTable}
          data={listCrud}
          options={optionsTable}
        />
      </ContentWrapper>
      <Pagination
        count={totalCrud}
        onChangePage={handlePage}
        onChangeRowsPerPage={handleRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        optionsRowsPerPage={optionsRowsPerPage}
      />
      {SearchDateRange()}
      {SearchMachines()}
      {searchIn.SearchGeofences()}
      {searchOut.SearchGeofences()}
      {SearchClients()}
      {TruckPathModal()}
      {SearchTelemetries()}
    </PageWrapper>
  );
};

export default Viagens;
