/* eslint-disable no-underscore-dangle */
import React from "react";
import { Geofence, GeofenceWithMachines } from "declarations";
import { v4 as uuidv4 } from "uuid";
import ItemOrigin from "Components/Map/ForbiddenDestinations/ItemOrigin";
import { WrapperList } from "./styles";

interface ListOriginsProps {
  origins: Geofence[];
  loading?: boolean;
  onSelect?: (geofence: Geofence) => void;
  onRemove?: (geofence: Geofence) => void;
  onClick?: (geofence: Geofence) => void;
  title?: string;
  position: "Left" | "Right";
}

const ListOrigins = ({
  origins,
  loading,
  onSelect,
  onRemove,
  title = "Alvos",
  onClick = () => {},
  position,
}: ListOriginsProps) => {
  return (
    <WrapperList title={title} loading={loading} position={position}>
      {origins.map((geofence) => (
        <ItemOrigin
          key={uuidv4()}
          geofence={geofence}
          onClick={onClick}
          onRemove={onRemove}
          onSelect={onSelect}
        />
      ))}
    </WrapperList>
  );
};

export default ListOrigins;
