import React, { useState, useEffect } from "react";
import { Polygon, Tooltip, useMap } from "react-leaflet";
import {
  Geofence as GeofenceInterface,
  GeofenceWithMachines,
} from "declarations";
import {
  formatCoordinates,
  center,
  fromMilliseconds,
  diffTimeToString,
} from "Shared/utils";
import {
  Block,
  CheckCircle,
  ScheduleOutlined,
  Speed,
} from "@material-ui/icons";
import theme from "Styles/theme";
import sharedSentences from "Shared/sentences";
import {
  InfoWrapper,
  PopupMarker,
  Title,
  TitleWrapper,
  ItemInfo,
  TitleInfo,
  Info,
} from "./styles";

interface GeofenceProps {
  geofence: GeofenceWithMachines | GeofenceInterface;
  popup?: boolean;
  onClick?: (geofence: GeofenceInterface | GeofenceWithMachines) => void;
  colorPolygon?: string;
}

const Geofence = ({
  geofence,
  popup,
  onClick = () => {},
  colorPolygon,
}: GeofenceProps) => {
  const map = useMap();
  const [label, setLabel] = useState("");

  const color =
    colorPolygon ||
    (geofence.allowed
      ? theme.colors.denotative.medium
      : theme.colors.denotative.error);

  useEffect(() => {
    if ("machines" in geofence) {
      setLabel(`${geofence.name} - ${geofence.machines.length}`);
    } else {
      setLabel(`${geofence.name}`);
    }
  }, [geofence]);

  const handleClick = () => {
    onClick(geofence);
    map.flyTo(center(geofence.coordinates.coordinates));
  };

  const geofenceAverageTime = fromMilliseconds(geofence.averageTime || 0);
  const geofenceAverageTimeNow = geofence.averageTimeMachineNow
    ? diffTimeToString(new Date().getTime(), geofence.averageTimeMachineNow)
    : diffTimeToString(0, 0);

  return (
    <Polygon
      positions={formatCoordinates(geofence.coordinates.coordinates)}
      pathOptions={{ color }}
      eventHandlers={{ click: handleClick }}
    >
      <Tooltip direction="bottom" offset={[0, 20]} opacity={1}>
        {label}
      </Tooltip>
      {popup && (
        <PopupMarker>
          <TitleWrapper>
            <Title>{geofence.name}</Title>
          </TitleWrapper>
          <InfoWrapper>
            {geofence.allowed ? (
              <ItemInfo>
                <CheckCircle
                  htmlColor={theme.colors.denotative.success}
                  fontSize="small"
                />
                <TitleInfo>Alvo permitido</TitleInfo>
              </ItemInfo>
            ) : (
              <ItemInfo>
                <Block
                  htmlColor={theme.colors.denotative.error}
                  fontSize="small"
                />
                <TitleInfo>Alvo não permitido</TitleInfo>
              </ItemInfo>
            )}
            <ItemInfo>
              <ScheduleOutlined
                htmlColor={theme.colors.layout.blackTertiary}
                fontSize="small"
              />
              <TitleInfo>Tempo médio esperado:</TitleInfo>
              <Info>{geofenceAverageTime}</Info>
            </ItemInfo>
            <ItemInfo>
              <ScheduleOutlined
                htmlColor={theme.colors.layout.blackTertiary}
                fontSize="small"
              />
              <TitleInfo>{`Tempo médio ${sharedSentences.truck.toLowerCase()}:`}</TitleInfo>
              <Info>{geofenceAverageTimeNow}</Info>
            </ItemInfo>
            <ItemInfo>
              <Speed
                htmlColor={theme.colors.layout.blackTertiary}
                fontSize="small"
              />
              <TitleInfo>Velocidade máxima:</TitleInfo>
              <Info>{`${geofence.maxSpeed} Km/h`}</Info>
            </ItemInfo>
          </InfoWrapper>
        </PopupMarker>
      )}
    </Polygon>
  );
};

export default Geofence;
