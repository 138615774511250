import React from "react";
import { useLocation } from "react-router-dom";
import { Dot, SubItemWrapper, Text, Box, Trapeze } from "./styles";

interface SubItemProps {
  title: string;
  onClick?: () => void;
}

const SubItem = ({ title, onClick }: SubItemProps) => {
  const location = useLocation();

  const getActive = () => {
    const paths = location.pathname.split("/");

    const lastPath = paths[paths.length - 1];

    return title.toLowerCase() === lastPath.toLowerCase();
  };

  return (
    <Box onClick={onClick} active={getActive()}>
      <Trapeze />
      <SubItemWrapper>
        <Dot />
        <Text> {title}</Text>
      </SubItemWrapper>
    </Box>
  );
};

export default SubItem;
