import { MenuItem } from "@material-ui/core";
import React from "react";
import { H5Regular } from "BaseComponents/Typographies";
import { v4 as uuidv4 } from "uuid";
import {
  StyledPagination,
  StyledPaginationItem,
  PaginationWrapper,
  StyledSelect,
} from "./styles";

interface PaginationProps {
  count: number;
  onChangePage: (page: number) => void;
  page: number;
  onChangeRowsPerPage: (rows: number) => void;
  rowsPerPage: number;
  optionsRowsPerPage: number[];
}

const Pagination = ({
  optionsRowsPerPage,
  count,
  onChangePage,
  page,
  onChangeRowsPerPage,
  rowsPerPage,
}: PaginationProps) => {
  const numPages =
    count % rowsPerPage === 0
      ? count / rowsPerPage
      : Math.trunc(count / rowsPerPage + 1);

  return (
    <PaginationWrapper>
      <StyledPagination
        count={numPages}
        onChange={(_, pageNumber) => onChangePage(pageNumber)}
        page={page + 1}
        shape="rounded"
        size="small"
        renderItem={(props) => <StyledPaginationItem {...props} />}
      />
      <H5Regular>Itens por página</H5Regular>
      <StyledSelect
        value={rowsPerPage}
        onChange={(event) => onChangeRowsPerPage(Number(event.target.value))}
        id="selectPagination"
        variant="outlined"
        margin="dense"
      >
        {optionsRowsPerPage.map((item) => (
          <MenuItem key={uuidv4()} value={item}>
            {item}
          </MenuItem>
        ))}
      </StyledSelect>
    </PaginationWrapper>
  );
};

export default Pagination;
