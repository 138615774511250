import React from "react";
import { PaperInfo, PaperSubtitle, PaperTitle } from "./styles";

interface CardInfoProps {
  title: string;
  subtitle: string;
}

const CardInfo = ({ title, subtitle }: CardInfoProps) => {
  return (
    <PaperInfo elevation={1}>
      <PaperTitle>{title}</PaperTitle>
      <PaperSubtitle>{subtitle}</PaperSubtitle>
    </PaperInfo>
  );
};

export default CardInfo;
