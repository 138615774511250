import styled from "styled-components";
import ListOrigins from "Components/Map/MandatoryRoutes/CrudRoutes/ModalOrigins/ListOrigins";

export const Wrapper = styled.div`
  background: white 0% 0% no-repeat padding-box;

  box-shadow: 0px 0px 20px ${(props) => props.theme.colors.layout.shadow};
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 90vw;

  border-radius: 8px;

  &:focus {
    outline: none;
  }
`;

export const WrapperContent = styled.div``;

export const WrapperMap = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const StyledList = styled(ListOrigins)`
  top: -95%;
  width: 20%;
  left: 75%;

  z-index: 800;
  position: relative;
  height: 90%;
`;
