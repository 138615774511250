import styled from "styled-components";

export const Button = styled.button`
  position: absolute;
  margin: 20px;
  z-index: 800;
  bottom: 100px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.theme.colors.layout.whitePrimary};
  border-radius: 6px;
  padding: 12px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  font-weight: 600;

  &:hover {
    background: ${(props) => props.theme.colors.layout.whitePrimary};
    color: ${(props) => props.theme.colors.layout.blackPrimary};
  }
`;
