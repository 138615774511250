/* eslint-disable no-underscore-dangle */
import React from "react";
import { TripInfo } from "declarations";
import { v4 as uuidv4 } from "uuid";
import ItemTrip from "Components/Map/Trips/ItemTrip";
import BoardList from "Components/BoardList";

interface ListTripsProps {
  trips: TripInfo[];
  loading?: boolean;
  clickGeofence?: () => void;
  className?: string;
}

const BoardTrips = ({
  trips,
  loading,
  clickGeofence = () => {},
  className,
}: ListTripsProps) => {
  return (
    <BoardList title="Viagens" loading={loading} className={className}>
      {trips.map((trip, index) => {
        return <ItemTrip key={uuidv4()} onClick={() => {}} trip={trip} />;
      })}
    </BoardList>
  );
};

export default BoardTrips;
