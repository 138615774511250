import React, { useState } from "react";
import SecondaryButton from "BaseComponents/Buttons/SecondaryButton";
import { v4 as uuidv4 } from "uuid";
import Button from "BaseComponents/Buttons/HeaderButton";
import CheckBox from "BaseComponents/Inputs/CheckBox";
import {
  Select,
  MenuItem,
  ListIcon,
  EditIcon,
  OptionsWrapper,
  LabelWrapper,
} from "./styles";

interface IAction {
  content: React.ReactNode;
  onAction: () => void;
}

interface ISelect {
  onSelect: () => void;
  state: boolean;
}

export interface Item {
  label: string;
  action?: IAction;
  select?: ISelect;
  onClick?: () => void;
}

interface PopperListProps {
  label: string;
  items: Item[];
}

const PopperList = ({ label, items }: PopperListProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = !!anchorEl;
  const id = open ? "simple-popper" : undefined;

  const handleActionItem = (item: Item) => {
    if (item.action) {
      item.action.onAction();
      handleClose();
    }
  };

  const handleSelectItem = (item: Item) => {
    item.select && item.select.onSelect();
  };

  const handleClickItem = (item: Item) => {
    if (item.onClick) {
      item.onClick();
      handleClose();
    }
  };

  const renderItens = () => {
    return items.map((item) => (
      <MenuItem
        key={uuidv4()}
        onClick={() => handleClickItem(item)}
        clickable={!!item.onClick}
      >
        <LabelWrapper>{item.label}</LabelWrapper>

        <OptionsWrapper>
          {item.select && (
            <CheckBox
              onChange={() => handleSelectItem(item)}
              checked={item.select.state}
            />
          )}
          {item.action && (
            <Button onClick={() => handleActionItem(item)}>
              {item.action.content}
            </Button>
          )}
        </OptionsWrapper>
      </MenuItem>
    ));
  };

  return (
    <div>
      <SecondaryButton aria-describedby={id} onClick={handleClick}>
        {label} <ListIcon active={!!open} />
      </SecondaryButton>

      <Select
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {renderItens()}
      </Select>
    </div>
  );
};

export default PopperList;
