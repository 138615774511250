import React, { useState, useCallback } from "react";
import Search from ".";
import { IFilter } from "..";

const useSearchSingleMachine = () => {
  const [openSearchMachines, setSearchMachines] = useState(false);
  const [filtersMachines, setFiltersMachines] = useState<string[]>([]);

  const clearFilters = () => {
    setFiltersMachines([]);
  };

  const handleOpen = () => {
    setSearchMachines(true);
  };

  const SearchMachines = () => (
    <Search
      open={openSearchMachines}
      onClose={() => setSearchMachines(false)}
      onApplyFilters={(value) => setFiltersMachines(value)}
      singleSearch
    />
  );

  return {
    SearchMachines,
    filtersMachines,
    setSearchMachines,
    openSearchMachines,
    clearFilters,
    handleOpen,
  };
};

export default useSearchSingleMachine;
