import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

const Wrapper = styled.div`
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  align-self: center;
  margin: auto;
`;

const TextSpinner = ({message = "Carregando..."}: {message?: string}) => (
  <Wrapper>
    <CircularProgress />
    {message}
  </Wrapper>
);

export default TextSpinner;
