import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import UserContext from "Contexts/User";
import Logo from "Assets/Images/logoWhite.svg";
import LogoGerdau from "Assets/Images/logoGerdau.svg";
import { ExitToApp } from "@material-ui/icons";
import theme from "Styles/theme";
import {
  MenuLateralContainer,
  SideBarWrapper,
  SideBar,
  TopImage,
  ImageWrapper,
  Nav,
  BottomImageWrapper,
  BottomImage,
  SideBarFooter,
  LogoutButton,
  LogoutLabel,
} from "./styles";
import Collapisable from "./Collapisable";
import SubItem from "./SubItem";
import MenuIcon from "./MenuIcon";

export interface IListItems {
  titleLabel: string;
  listLabels: string[];
  icon: React.ReactNode;
}

interface MenuLateralProps {
  listItems: IListItems[];
}

const MenuLateral = ({ listItems }: MenuLateralProps) => {
  const history = useHistory();
  const { logout } = useContext(UserContext);
  const [active, setActive] = useState("open");

  const goToPage = (label: string) => {
    const labelNormalize = label
      .toLowerCase()
      .replaceAll(" ", "_")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    history.push(`/${labelNormalize}`);
  };

  const collapse = () =>
    setActive((state) => (state === "closed" ? "open" : "closed"));

  return (
    <MenuLateralContainer active={active}>
      <Nav active={active} onClick={collapse}>
        <MenuIcon active={active} />
      </Nav>

      <SideBar active={active}>
        <ImageWrapper>
          <TopImage src={Logo} alt="." />
        </ImageWrapper>
        <SideBarWrapper>
          {listItems.map((item) => (
            <Collapisable
              key={item.titleLabel}
              title={item.titleLabel}
              icon={item.icon}
              onClick={
                item.listLabels.length === 0
                  ? () => goToPage(item.titleLabel)
                  : undefined
              }
            >
              {item.listLabels.map((label) => (
                <SubItem
                  key={`${item.titleLabel}-${label}`}
                  title={label}
                  onClick={() => goToPage(`${item.titleLabel}/${label}`)}
                />
              ))}
            </Collapisable>
          ))}
        </SideBarWrapper>
        <SideBarFooter>
          <LogoutButton onClick={logout}>
            <ExitToApp htmlColor={theme.colors.layout.blackQuaternary} />
            <LogoutLabel>Sair</LogoutLabel>
          </LogoutButton>
          <BottomImageWrapper>
            <BottomImage src={LogoGerdau} alt="." />
          </BottomImageWrapper>
        </SideBarFooter>
      </SideBar>
    </MenuLateralContainer>
  );
};

export default MenuLateral;
