import { getInternLiveMap } from "Api/InternLiveMap";
import { InternMachine } from "declarations";
import { useCallback, useState } from "react";
import toasts from "Shared/toasts";

const useInterLiveMap = () => {
  const [machines, setMachines] = useState<InternMachine[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchInternMachines = useCallback(async () => {
    try {
      setLoading(true);

      const response = await getInternLiveMap();

      if (response.internMachines) {
        setMachines([...response.internMachines]);
      }
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    fetchInternMachines,
    loading,
    internMachines: machines,
  };
};

export default useInterLiveMap;
