import { Avatar } from "@material-ui/core";
import { DeleteOutline, AddOutlined } from "@material-ui/icons";
import { H4SemiBold } from "BaseComponents/Typographies";
import styled from "styled-components";

export const StyledAvatar = styled(Avatar)`
  background-color: "#0EE616" !important;
  width: 40px !important;
  height: 40px !important;
`;

export const Wrapper = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 2px 0;
  background: none;
  border: none;
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
  transition: background-color 400ms;
  position: relative;
  &:focus {
    outline: none;
  }
  &:active,
  &:hover {
    background: ${(props) => props.theme.colors.layout.whiteTertiary};
  }
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform-style: flat;
    transform: translate3d(-50%, -50%, 0);
    background: #ffffff1a;
    border-radius: 100%;
    transition: width 0.3s ease, height 0.3s ease;
  }
  &:active {
    &:after {
      width: 200px;
      height: 200px;
    }
  }
`;

export const WrapperLabels = styled.div`
  width: 100%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  text-align: left;
`;

export const Title = styled(H4SemiBold)`
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  margin-bottom: 2px;
`;

export const DeleteIcon = styled(DeleteOutline)`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  font-size: 14px;
`;

export const AddIcon = styled(AddOutlined)`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  font-size: 14px;
`;
