import { MenuList, Popover } from "@material-ui/core";
import styled from "styled-components";
import { EditOutlined, FilterList } from "@material-ui/icons";

interface FilterListProps {
  active: boolean;
}

export const Container = styled.div``;

export const Select = styled(Popover)`
  .MuiPopover-paper {
    box-shadow: 0px 0px 8px ${(props) => props.theme.colors.layout.shadow};
    border-radius: 8px;
    padding: 12px !important;
  }
`;

export const MenuItem = styled(MenuList)<{ clickable: boolean }>`
  color: ${(props) => props.theme.colors.layout.blackSecondary};
  font: normal normal 600 14px Open Sans;
  padding: 8px 16px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  cursor: ${(props) => (props.clickable ? "pointer" : "unset")};
  > svg {
    fill: ${(props) => props.theme.colors.layout.blackTertiary};
  }

  > svg:focus {
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
    background-color: ${(props) =>
      props.clickable ? "rgba(0, 0, 0, 0.04)" : "unset"};
  }
`;

export const ListIcon = styled(FilterList)<FilterListProps>`
  margin-left: 5px;

  -webkit-transform: ${(props) => (props.active ? "rotate(-180deg)  " : "")};
  transform: ${(props) => (props.active ? "rotate(-180deg)  " : "")};

  transition: all 1s ease-in;
`;

export const EditIcon = styled(EditOutlined)`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  font-size: 14px;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 50%;
  justify-content: space-around;
`;

export const LabelWrapper = styled.div`
  min-width: 50%;
`;
