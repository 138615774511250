import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlLabel, Switch } from "@material-ui/core";
import { Geofence } from "declarations";
import React  from "react";
import { useForm } from "react-hook-form";
import { timeMask } from "Shared/masks";
import { fromMillisecondsToHour } from "Shared/utils";
import { v4 as uuidv4 } from "uuid";
import {
  ButtonAction,
  ButtonsWrapper,
  EditWrapper,
  InputName,
  ItemWrapper,
  Label,
  PointsWrapper,
} from "../styles";
import { GeofenceValidation } from "../Validation";

interface FormShape {
  name: string;
  averageTime: string;
  maxSpeed: number;
  allowed: boolean;
  minStayTime?: string;
  maxStayTimeBeforeAlert?: string;
}

interface EditProps {
  onSubmit: (data: FormShape) => void;
  loading: boolean;
  geofence: Geofence;
  cancelEdit: () => void;
}

const Edit = ({ onSubmit, loading, cancelEdit, geofence }: EditProps) => {
  const { register, handleSubmit, errors, reset, watch, setValue } =
    useForm<FormShape>({
      resolver: yupResolver(GeofenceValidation),
      defaultValues: {
        averageTime: fromMillisecondsToHour(geofence.averageTime),
        minStayTime: geofence.minStayTime
          ? fromMillisecondsToHour(geofence.minStayTime)
          : undefined,
        maxStayTimeBeforeAlert: geofence.maxStayTimeBeforeAlert
          ? fromMillisecondsToHour(geofence.maxStayTimeBeforeAlert)
          : undefined,
        maxSpeed: geofence.maxSpeed,
        name: geofence.name,
        allowed: !geofence.allowed,
      },
    });

  const whatchIsAllowed = watch("allowed");

  const submit = handleSubmit(async (data) => {
    if (loading) return;

    const minStayTime = data.minStayTime === "" ? undefined : data.minStayTime;
    const maxStayTimeBeforeAlert = data.maxStayTimeBeforeAlert === "" ? undefined : data.maxStayTimeBeforeAlert;
    onSubmit({
      ...data,
      allowed: !data.allowed,
      minStayTime,
      maxStayTimeBeforeAlert,
    });
  });

  const points = () => {
    return geofence.coordinates.coordinates[0].map((coords) => (
      <ItemWrapper key={uuidv4()}>
        <Label>{`Lat: ${coords[0]} / Long: ${coords[1]}`}</Label>
      </ItemWrapper>
    ));
  };

  return (
    <EditWrapper onSubmit={submit}>
      <InputName
        label="Nome do alvo"
        type="text"
        name="name"
        errorText={errors.name?.message || ""}
        ref={register()}
      />
      <InputName
        label="Tempo médio (hh:mm:ss)"
        type="text"
        name="averageTime"
        errorText={errors.averageTime?.message || ""}
        ref={register}
        onChange={(e) => setValue("averageTime", timeMask(e.target.value))}
      />
      <InputName
        label="Tempo mínimo (hh:mm:ss)"
        type="text"
        name="minStayTime"
        errorText={errors.minStayTime?.message || ""}
        ref={register}
        onChange={(e) => setValue("minStayTime", timeMask(e.target.value))}
      />
      <InputName
        label="Tempo máximo para alerta via WhatsApp (hh:mm:ss)"
        type="text"
        name="maxStayTimeBeforeAlert"
        errorText={errors.maxStayTimeBeforeAlert?.message || ""}
        ref={register}
        onChange={(e) =>
          setValue("maxStayTimeBeforeAlert", timeMask(e.target.value))}
      />
      <InputName
        label="Velocidade Máxima Km/h"
        type="number"
        name="maxSpeed"
        defaultValue={0}
        errorText={errors.maxSpeed?.message || ""}
        ref={register({ pattern: /\d/g })}
      />
      <FormControlLabel
        control={
          <Switch
            color="primary"
            inputRef={register}
            name="allowed"
            id="allowed"
            checked={whatchIsAllowed}
            onChange={(_, checked) => setValue("allowed", checked)}
          />
        }
        label="É proibida?"
      />
      <PointsWrapper>{points()}</PointsWrapper>
      <ButtonsWrapper>
        <ButtonAction onClick={cancelEdit}>Cancelar</ButtonAction>
        <ButtonAction type="submit" isLoading={loading}>
          {" "}
          Salvar
        </ButtonAction>
      </ButtonsWrapper>
    </EditWrapper>
  );
};

export default Edit;
