import { ResponseMachine } from "declarations";
import feathers from "./Feathers";

const machineService = feathers.service("machine-data");

export const getMachines = (
  limit: number,
  skip: number,
  plaques?: string[],
  clients?: string[]
): Promise<ResponseMachine> =>
  machineService.find({
    query: {
      $limit: limit,
      $skip: skip,
      client: {
        $in: clients,
      },
      plaque: {
        $in: plaques,
      },
    },
  });

export const getMachinesCSV = (
  fields: string[][],
  limit: number,
  skip: number,
  plaques?: string[],
  clients?: string[]
): Promise<ResponseMachine> =>
  machineService.find({
    query: {
      csv: true,
      fields,
      $limit: limit,
      $skip: skip,
      client: {
        $in: clients,
      },
      plaque: {
        $in: plaques,
      },
    },
  });
