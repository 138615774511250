/* eslint-disable no-underscore-dangle */
import { User } from "declarations";
import React, { useState } from "react";
import MarkerFilter from ".";

interface useMarkerFilterProps {
  user: User | null;
}

const useMarkerFilter = ({ user }: useMarkerFilterProps) => {
  const [openMarkerFiler, setMarkerFilter] = useState(false);
  const [selected, setSelected] = useState<string[]>(() => {
    try {
      if (!user) return [];

      const storageString = localStorage.getItem(user._id);

      if (storageString) {
        const storageObject = JSON.parse(storageString);

        if (storageObject.markerFilter) {
          return storageObject.markerFilter;
        }

        return ["Normal"];
      }
      return ["Normal"];
    } catch (error) {
      return [];
    }
  });

  const setValue = (value: string[]) => {
    if (!user) return;

    const storageString = localStorage.getItem(user._id);
    const newSelected = [...value];

    if (storageString) {
      const storageObject = JSON.parse(storageString);

      const newStorageObject = {
        ...storageObject,
        markerFilter: newSelected,
      };

      localStorage.setItem(user._id, JSON.stringify(newStorageObject));
    } else {
      const newStorageObject = {
        markerFilter: newSelected,
      };

      localStorage.setItem(user._id, JSON.stringify(newStorageObject));
    }
    setSelected([...newSelected]);
  };

  const MarkerFilters = () => (
    <MarkerFilter
      open={openMarkerFiler}
      onClose={() => setMarkerFilter(false)}
      onApplyFilters={setValue}
      initialSelected={selected}
    />
  );

  return {
    MarkerFilters,
    selectedMarkers: selected,
    setMarkerFilter,
    openMarkerFiler,
  };
};

export default useMarkerFilter;
