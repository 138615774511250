import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import feathers from "Api/Feathers";
import { useForm } from "react-hook-form";
import Input from "BaseComponents/Inputs/Input";
import PrimaryButton from "BaseComponents/Buttons/PrimaryButton";
import Logo from "Assets/Images/logoBlack.svg";
import UserContext from "Contexts/User";
import toasts from "Shared/toasts";
import {
  Box,
  Card,
  Container,
  CardWrapper,
  Form,
  ButtomWrapper,
  ImageWrapper,
  ImageLogo,
} from "./styles";

type FormShape = {
  email: string;
  senha: string;
};

export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm<FormShape>();
  const { login } = useContext(UserContext);
  const onSubmit = handleSubmit(({ email, senha }) => {
    setIsLoading(true);
    login(email, senha).finally(() => setIsLoading(false));
  });

  return (
    <Box>
      <Container>
        <Card>
          <CardWrapper>
            <ImageWrapper>
              <ImageLogo src={Logo} alt="." />
            </ImageWrapper>

            <Form onSubmit={onSubmit}>
              <Input
                label="Email"
                type="text"
                name="email"
                errorText={errors.email ? "Por favor, digite seu email" : ""}
                ref={register({ required: true })}
              />
              <Input
                label="Senha"
                type="password"
                name="senha"
                errorText={errors.senha ? "Por favor, digite sua senha" : ""}
                ref={register({ required: true })}
              />
              <ButtomWrapper>
                <PrimaryButton onClick={onSubmit} isLoading={isLoading}>
                  Entrar
                </PrimaryButton>
              </ButtomWrapper>
            </Form>
          </CardWrapper>
        </Card>
      </Container>
    </Box>
  );
}
