import styled from "styled-components";

import { Modal, Avatar } from "@material-ui/core";
import { H4SemiBold } from "BaseComponents/Typographies";
import SecondaryButton from "BaseComponents/Buttons/SecondaryButton";

export const Wrapper = styled(Modal)`
  margin: 65px;
`;

export const Content = styled.div`
  background: white 0% 0% no-repeat padding-box;
  border-radius: 8px;
  box-shadow: 0px 0px 20px ${(props) => props.theme.colors.layout.shadow};

  &:focus {
    outline: none;
  }
`;

export const Header = styled.div`
  padding: 24px;
  font-weight: bold;
  font-size: 24px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  border-bottom: 1px solid ${(props) => props.theme.colors.layout.whiteTertiary};
`;

export const Body = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: row;
`;

export const Footer = styled.div`
  padding: 24px;
  display: flex;
  gap: 24px;
  flex-direction: row-reverse;
  border-top: 1px solid ${(props) => props.theme.colors.layout.whiteTertiary};
`;

export const WrapperMachines = styled.div`
  padding: 10px;
  min-width: 160px;
  width: 30%;
`;

export const ListMachines = styled.div`
  overflow-y: auto;
  max-height: 40vh;
`;

export const ItemMachine = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 2px 0;
  background: none;
  border: none;
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
  transition: background-color 400ms;
  position: relative;
  &:focus {
    outline: none;
  }
  &:active,
  &:hover {
    background: ${(props) => props.theme.colors.layout.whiteTertiary};
  }
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform-style: flat;
    transform: translate3d(-50%, -50%, 0);
    background: #ffffff1a;
    border-radius: 100%;
    transition: width 0.3s ease, height 0.3s ease;
  }
  &:active {
    &:after {
      width: 200px;
      height: 200px;
    }
  }
`;

export const StyledAvatar = styled(Avatar)`
  background-color: ${(props) =>
    props.theme.colors.denotative.success} !important;
  width: 30px !important;
  height: 30px !important;
`;

export const TitleItem = styled(H4SemiBold)`
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  margin-left: 10px;
`;

export const InfoWrapper = styled.div`
  width: 100%;
`;
export const InfoContent = styled.div`
  padding: 10px;
  width: 100%;
`;

export const WrapperPapers = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
`;

export const TitleInfo = styled(H4SemiBold)`
  color: ${(props) => props.theme.colors.layout.blackSecondary};
  margin-bottom: 10px;
`;

export const ButtonClose = styled(SecondaryButton)`
  width: 90px;
`;
