/* eslint-disable no-underscore-dangle */
import React from "react";
import { v4 as uuidv4 } from "uuid";
import BoardList from "Components/BoardList";
import { InternMachine } from "declarations";
import ItemInternMachine from "../ItemInternMachine";

interface InternMachineStatusProps {
  list: any[];
  loading?: boolean;
  onClick?: (internMachine: InternMachine) => void;
  className?: string;
  resize?: boolean;
  shouldStartMinimized?: boolean;
}

const InternMachineStatus = ({
  list,
  loading,
  onClick = () => {},
  className,
  resize,
  shouldStartMinimized = false,
}: InternMachineStatusProps) => {

  const renderItemList = (internMachine:any) => {
    return (
      <ItemInternMachine
        key={internMachine._id || uuidv4()}
        onClick={() => onClick(internMachine)}
        title={internMachine.name}
        status={internMachine.status}
        statusDate={internMachine.statusDate}
        statusReason={internMachine.statusReason}
        statusReasonDate={internMachine.statusReasonDate}
      />
    );
  };

  return (
    <BoardList
      title="Pás Carregadeiras"
      loading={loading}
      className={className}
      resize={resize}
      shouldStartMinimized={shouldStartMinimized}
    >
      {list.map((internMachine) => renderItemList(internMachine))}
    </BoardList>
  );
};

export default InternMachineStatus;
