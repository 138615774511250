import { ResponseIncompleteRoutes } from "declarations";
import feathers from "./Feathers";

const incompleteRouteService = feathers.service("incomplete-route");

export const getIncompletRoutes = (
  limit: number,
  skip: number,
  plaques?: string[],
  geofences?: string[],
  clients?: string[],
  date?: Date[]
): Promise<ResponseIncompleteRoutes> =>
  incompleteRouteService.find({
    query: {
      $limit: limit,
      $skip: skip,
      $sort: {
        dateInRegisterGeofence: -1,
      },
      "machine.plaque": {
        $in: plaques,
      },
      "machine.client": {
        $in: clients,
      },
      registerGeofence: {
        $in: geofences,
      },
      $and: [
        {
          dateInRegisterGeofence: {
            $gte: date && date[0],
          },
        },
        {
          dateInRegisterGeofence: {
            $lte: date && date[1],
          },
        },
      ],
    },
  });

export const getIncompletRoutesCSV = (
  fields: string[][],
  limit: number,
  skip: number,
  plaques?: string[],
  geofences?: string[],
  clients?: string[],
  date?: Date[]
): Promise<ResponseIncompleteRoutes> =>
  incompleteRouteService.find({
    query: {
      csv: true,
      fields,
      $limit: limit,
      $skip: skip,
      "machine.plaque": {
        $in: plaques,
      },
      "machine.client": {
        $in: clients,
      },
      registerGeofence: {
        $in: geofences,
      },
      $and: [
        {
          dateInRegisterGeofence: {
            $gte: date && date[0],
          },
        },
        {
          dateInRegisterGeofence: {
            $lte: date && date[1],
          },
        },
      ],
    },
  });
