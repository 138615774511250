import styled from "styled-components";
import { LabelLista, Lista, Tabs } from "BaseComponents/Typographies";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.layout.blackSecondary};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

export const WrapperItem = styled.div`
  display: flex;
  align-items: center;

  width: fit-content;
  margin: 4px 12px;
`;

export const LabelTitle = styled(Lista)`
  margin-left: 4px;
  color: ${(props) => props.theme.colors.layout.whiteTertiary};
`;
