import styled from "styled-components";
import BoardGeofences from "Components/Map/Geofences/BoardGeofences";
import TableOptions from "Components/TableOptions";

export const Listagem = styled(BoardGeofences)`
  z-index: 800;
  position: absolute;
  bottom: 20px;
  top: 20px;
  right: 20px;
`;

export const Filters = styled(TableOptions)`
  z-index: 800;
  position: absolute;
  top: 0px;
  left: 0px;
`;
