import Table, {
  Col as ColTable,
  Option as OptionTable,
} from "BaseComponents/Table";
import Pagination from "BaseComponents/Table/Pagination";
import TableOptions, { ICheck } from "Components/TableOptions";
import PageWrapper from "Components/Wrappers/PageWrapper";
import { ExceedSpeed } from "declarations";
import useCrud from "Hooks/useCrud";
import React, { useState, useEffect, useMemo } from "react";
import theme from "Styles/theme";
import { useLocation } from "react-router";
import dayjs from "dayjs";
import useSearchGeofences from "Components/TableOptions/SearchGeofences/useSearchGeofences";
import useSearchMachines from "Components/TableOptions/SearchMachines/useSearchMachines";
import useSearchDate from "Components/TableOptions/SearchDate/useSearchDate";
import useTruckPath from "Components/Map/TruckPath/useTruckPath";
import { MapOutlined } from "@material-ui/icons";

import useExceedSpeed from "Hooks/useExceedSpeed";
import useSearchClients from "Components/TableOptions/SearchClients/useSearchClients";
import sharedSentences from "Shared/sentences";
import { ContentWrapper } from "./styles";

const optionsRowsPerPage = [10, 20, 30];

const initDateRange = {
  startDate: dayjs(new Date()).subtract(2, 'weeks').toDate(),
  endDate: dayjs(new Date()).endOf("day").toDate(),
  color: theme.colors.interactive.primary,
  key: "selection",
  showDateDisplay: false,
};

const ExcessoDeVelocidade = () => {
  const [groupBy, setGroupBy] = useState<string | null>(null);

  const {
    listCrud,
    setListCrud,
    totalCrud,
    setTotalCrud,
    loading,
    setLoading,
    setPage,
    setRowsPerPage,
    page,
    rowsPerPage,
    filters,
    setFilters,
  } = useCrud<ExceedSpeed>();

  const { SearchGeofences, filtersGeofences, tagGeofence, setSearchGeofences } =
    useSearchGeofences({ filters, setFilters });
  
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const initDateRangeWithQuery = {
    ...initDateRange,
    startDate: query.get('startDate') ? new Date(query.get('startDate')!) : initDateRange.startDate,
    endDate: query.get('endDate') ? new Date(query.get('endDate')!) : initDateRange.endDate
  }

  const {
    SearchMachines,
    filtersMachines,
    setSearchMachines,
    tagMachine,
    clearFilters,
  } = useSearchMachines({ filters, setFilters, defaultMachines: query.get('machines')?.split(',') });

  const { SearchClients, filtersClients, setSearchClients, tagClients } =
    useSearchClients({ filters, setFilters, defaultClients: query.get('clients')?.split(',') });

  const { SearchDateRange, dateRange, setSearchDate, tagDate } = useSearchDate({
    filters,
    setFilters,
    defaultRange: initDateRangeWithQuery,
  });

  const { TruckPathModal, onSearchOnModal } = useTruckPath({});

  const { fetchData, fetchDataCSV } = useExceedSpeed({
    dateRange,
    filtersGeofences,
    filtersMachines,
    filtersClients,
    page,
    rowsPerPage,
    setListCrud,
    setLoading,
    setTotalCrud,
  });

  useEffect(() => {
    fetchData(groupBy);
  }, [fetchData, groupBy]);

  useEffect(() => {
    tagMachine();
  }, [tagMachine]);

  useEffect(() => {
    tagGeofence();
  }, [tagGeofence]);

  useEffect(() => {
    tagDate();
  }, [tagDate]);

  useEffect(() => {
    tagClients();
  }, [tagClients]);

  const colsTable: ColTable[] = [
    { name: "machine.name", label: sharedSentences.truck, align: "left" },
    { name: "geofence.name", label: "Alvo", align: "left" },
    {
      name: "geofence.maxSpeed",
      label: "L. Vel. Alvo",
      align: "left",
    },
    { name: "speed", label: "Velocidade", align: "left" },
    { name: "date", label: "Data", align: "left" },
    {
      name: "machine.telemetry",
      label: sharedSentences.telemetry,
      align: "left",
    },
    { name: "machine.client", label: sharedSentences.client, align: "left" },
  ];

  const itemsFilter = [
    {
      label: sharedSentences.trucks,
      action: () => setSearchMachines(true),
    },
    {
      label: "Alvos",
      action: () => setSearchGeofences(true),
    },
    {
      label: "Data",
      action: () => setSearchDate(true),
    },
    {
      label: sharedSentences.clients,
      action: () => setSearchClients(true),
    },
  ];

  const handlePage = (pageNumber: number) => {
    setPage(pageNumber - 1);
  };

  const handleRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
  };

  const searchPath = async (data: any) => {
    const dateIn = new Date(data.date);
    dateIn.setHours(dateIn.getHours() - 1);
    const dateEnd = new Date(data.date);
    dateEnd.setHours(dateEnd.getHours() + 1);

    await onSearchOnModal(data.machine.plaque, dateIn, dateEnd);
  };

  const optionsTable: OptionTable[] = [
    {
      label: "Ver trajeto",
      action: (itemData) => searchPath(itemData),
      Icon: <MapOutlined />,
    },
  ];

  const onCheck = (check: boolean) => {
    setGroupBy(check ? "plaqueByHour" : null);
  }

  const itensCheck: ICheck[] = [
    {
      check: groupBy === "plaqueByHour",
      label: "Agrupar por placa e hora",
      onCheck,
    },
  ];

  return (
    <PageWrapper title="Excesso de Velocidade">
      <ContentWrapper>
        <TableOptions
          itemsCheck={itensCheck}
          itemsFilter={itemsFilter}
          filtersTags={filters}
          onExportToCSV={() => fetchDataCSV(groupBy)}
        />
        <Table
          loading={loading}
          cols={colsTable}
          data={listCrud}
          options={optionsTable}
        />
      </ContentWrapper>
      <Pagination
        count={totalCrud}
        onChangePage={handlePage}
        onChangeRowsPerPage={handleRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        optionsRowsPerPage={optionsRowsPerPage}
      />
      {SearchDateRange()}
      {SearchMachines()}
      {SearchGeofences()}
      {SearchClients()}
      {TruckPathModal()}
    </PageWrapper>
  );
};

export default ExcessoDeVelocidade;
