import styled from "styled-components";
import { ArrowDropUp } from "@material-ui/icons";
import { Tabs } from "BaseComponents/Typographies";

export const Label = styled(Tabs)`
  color: ${(props) => props.theme.colors.layout.blackQuaternary};
  margin-left: 10px;
`;
export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  min-height: 40px;
  justify-content: space-between;
  padding: 0 8px 0px 16px;
`;
export const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding-left: 4px;
`;
export const CollapisableWrapper = styled.div`
  cursor: pointer;
  width: 95%;
  min-height: 40px;
  min-width: 207px;
  margin: 5px 0;
`;

export const Content = styled.div<{ active: string }>`
  max-height: ${(props) => (props.active === "closed" ? "0" : "400px")};
  overflow: hidden;
  transition: max-height ease 0.5s;
  width: 100%;
  margin-bottom: ${(props) => (props.active === "closed" ? "0" : "8px")};
`;
export const Arrow = styled(ArrowDropUp)<{ active: string }>`
  transform: ${(props) =>
    props.active === "closed" ? "rotate(-180deg)" : "rotate(0deg)"};
  transition: transform ease 1s;
  color: ${(props) => props.theme.colors.layout.blackQuaternary};
`;
