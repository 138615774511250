import { Geofence } from "declarations";
import * as yup from "yup";

export const MandatoryRoutesValidation = yup.object({
  name: yup.string().required("Por favor, insira um nome para o alvo!"),
  route: yup
    .array()
    .min(2, "Selecione pelo menos dois pontos")
    .required("Selecione pelo menos uma origem"),
});
