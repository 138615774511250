import React, { useContext } from "react";
import UserContext from "Contexts/User";
import { Add, GetApp } from "@material-ui/icons";
import PrimaryButton from "BaseComponents/Buttons/PrimaryButton";
import Tag from "BaseComponents/Buttons/Tag";
import SearchBar from "BaseComponents/SearchBar";
import Filter, { Item } from "Components/Filter";
import AutoComplete from "BaseComponents/Inputs/AutoComplete";
import { v4 as uuidv4 } from "uuid";

import CheckBox from "BaseComponents/Inputs/CheckBox";
import CheckBoxWithLabel from "BaseComponents/Inputs/CheckBoxWithLabel";
import {
  Wrapper,
  FilterWrapper,
  SearchWrapper,
  ButtonNew,
  ButttonExport,
} from "./styles";

export interface IFilter {
  label: string;
  name: string;
  clear?: () => void;
  open?: () => void;
}

export interface ICheck {
  label: string;
  check: boolean;
  onCheck: (check: boolean) => void;
}

interface TableOptionsProps {
  itemsFilter?: Item[];
  filtersTags?: IFilter[];
  itemsCheck?: ICheck[];
  removeTag?: (index: number) => void;
  clickTag?: (index: number) => void;
  onSearch?: (value: string) => void;
  onNew?: () => void;
  placeholderSearchBar?: string;
  className?: string;
  onExportToCSV?: () => void;
}

const TableOptions = ({
  itemsFilter,
  itemsCheck,
  filtersTags,
  removeTag,
  onSearch,
  onNew,
  placeholderSearchBar,
  className,
  clickTag,
  onExportToCSV,
}: TableOptionsProps) => {
  const { isAdmin } = useContext(UserContext);

  const closeAction = (filter: IFilter, index: number) => {
    if (filter.clear) {
      filter.clear();
    } else if (removeTag) {
      removeTag(index);
    }
  };

  const clickAction = (filter: IFilter, index: number) => {
    if (filter.open) {
      filter.open();
    } else if (clickTag) {
      clickTag(index);
    }
  };

  return (
    <>
      <Wrapper className={className}>
        <FilterWrapper>
          {itemsFilter && <Filter items={itemsFilter} />}

          {filtersTags?.map((filter, index) => (
            <Tag
              key={filter.label || uuidv4()}
              label={filter.label}
              onClose={() => closeAction(filter, index)}
              onClick={() => clickAction(filter, index)}
            />
            ))}
        </FilterWrapper>
        <SearchWrapper>
          {onSearch && (
            <SearchBar onSearch={onSearch} placeholder={placeholderSearchBar} />
          )}

          {isAdmin && onNew && (
            <ButtonNew onClick={onNew}>
              Novo <Add />
            </ButtonNew>
          )}
        </SearchWrapper>
        {onExportToCSV && (
          <ButttonExport onClick={onExportToCSV}>
            Exportar
            <GetApp />
          </ButttonExport>
        )}
      </Wrapper>
      {itemsCheck && itemsCheck.length > 0 && (
        <Wrapper className={className}>
          {itemsCheck.map((value) => (
            <CheckBoxWithLabel
              key={value.label}
              checked={value.check}
              onChange={(event) => value.onCheck(event.target.checked)}
              label={value.label}
            />
          ))}
        </Wrapper>
      )}
    </>
  );
};

export default TableOptions;
