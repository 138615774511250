import { getPermanence } from "Api/Permanence";
import BarWithLine, {
  IData,
  IReferenceLine,
} from "BaseComponents/Charts/BarWithLine";
import { H3SemiBold, H4SemiBold } from "BaseComponents/Typographies";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import toasts from "Shared/toasts";
import theme from "Styles/theme";
import SimpleSpinner from "BaseComponents/Spinners/SimpleSpinner";
import { getAveragePermanenceByDay } from "Api/DashboardData";
import { Unit } from "declarations";
import PopperList from "BaseComponents/Menus/PopperList";
import { Edit } from "@material-ui/icons";
import { toMilliseconds } from "Shared/utils";
import { updateRoute } from "Api/Units";
import DefaultWrapper from "../DefaultWrapper";
import EditPermanenceGoal, { FormShape } from "./EditPermanenceGoal";
import { PopperWrapper } from "./styles";

const ONE_MINUTE = 60 * 1000;

const THIRTY_MINUTES = 30 * ONE_MINUTE;

interface GraphBarWithLineProps {
  name: string;
  beg: Date;
  end: Date;
  unit: Unit;
  timeToUpdate?: number;
  update?: () => void;
}

const GraphBarWithLine = ({
  name,
  unit,
  beg,
  end,
  timeToUpdate = THIRTY_MINUTES,
  update = () => {},
}: GraphBarWithLineProps) => {
  const [visible, setVisible] = useState(false);
  const [currDate, setCurrDate] = useState<null | Date>(null);
  const [data, setData] = useState<IData[]>([]);
  const [showMetric, setShowMetric] = useState(true);
  const [openPermanence, setOpenPermanence] = useState(false);
  const [metricsReference, setMetricsReference] = useState<IReferenceLine[]>(
    []
  );
  const [loading, setLoading] = useState(false);

  const handleShowMetric = () => {
    setShowMetric(!showMetric);
  };

  const handleOpenPermanence = () => {
    setOpenPermanence(true);
  };
  const handleClosePermanence = () => {
    setOpenPermanence(false);
  };

  const fetchPermanence = async (
    newBeg: Date,
    newEnd: Date,
    geofences: string[]
  ) => {
    try {
      setLoading(true);

      const response = await getAveragePermanenceByDay({
        dateIn: newBeg,
        dateOut: newEnd,
        geofences,
      });

      const dataOut = response.map((value) => {
        const reg: IData = {
          barValue: value.avg,

          name: `${value.day}`,
        };

        return reg;
      });

      setData(dataOut);
    } catch (error) {
      toasts.error(`Erro ao buscar informações de ttp diário!`);
    } finally {
      setLoading(false);
    }
  };

  const processData = useCallback(async () => {
    const newBeg = dayjs(beg);
    const newEnd = dayjs(end);

    const afterBeg = dayjs().isAfter(newBeg);
    const afterEnd = dayjs().isAfter(newEnd);

    setVisible(afterBeg);
    const geofences = [unit.owner.name];

    setCurrDate(end);
    await fetchPermanence(newBeg.toDate(), newEnd.toDate(), geofences);
  }, [beg, unit]);

  useEffect(() => {
    processData();
    const interval = setInterval(() => processData(), timeToUpdate);

    return () => clearInterval(interval);
  }, [processData]);

  const itensMetrics = [
    {
      label: "Objetivo Mensal TTP",
      action: {
        content: <Edit />,
        onAction: handleOpenPermanence,
      },
      select: {
        onSelect: handleShowMetric,
        state: showMetric,
      },
    },
  ];

  useEffect(() => {
    if (showMetric) {
      setMetricsReference([
        {
          label: "Objetivo Mensal TTP",
          stroke: theme.colors.interactive.secondary,
          value: unit.metrics?.goals.permanenceAvg || 0,
        },
      ]);
    } else {
      setMetricsReference([]);
    }
  }, [showMetric, unit]);

  const onEditMetric = async (metricsData: FormShape) => {
    try {
      const newUnit: Unit = {
        ...unit,
        metrics: {
          goals: { permanenceAvg: toMilliseconds(metricsData.monthGoalTTP) },
        },
      };
      const response = await updateRoute(newUnit);

      update();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DefaultWrapper
      style={{
        width: "100%",
        textAlign: "center",
      }}
      visible={visible}
    >
      <PopperWrapper>
        <PopperList label="Métricas" items={itensMetrics} />
      </PopperWrapper>

      <H3SemiBold color={theme.colors.layout.blackPrimary}>{name}</H3SemiBold>
      <BarWithLine
        data={data}
        barLabel="TTP diário"
        lineLabel="Linear"
        referenceLines={metricsReference}
      />
      <EditPermanenceGoal
        open={openPermanence}
        onClose={handleClosePermanence}
        defaultValue={unit.metrics?.goals.permanenceAvg}
        onApply={onEditMetric}
      />
    </DefaultWrapper>
  );
};

export default GraphBarWithLine;
