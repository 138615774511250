import styled from "styled-components";
import Button from "BaseComponents/Buttons/PrimaryButton";
import { H3Bold, H4Bold, H5Bold } from "BaseComponents/Typographies";

export const Wrapper = styled.div`
  background: white 0% 0% no-repeat padding-box;

  box-shadow: 0px 0px 20px ${(props) => props.theme.colors.layout.shadow};
  overflow: auto;

  height: 100%;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const WrapperMap = styled.div`
  height: 90vh;
  width: 90vw;
  border-radius: 8px;
  overflow: auto;
`;

export const WrapperMessage = styled.div`
  background-color: ${(props) =>
    `${props.theme.colors.layout.blackSecondary}99`};
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 800;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 224px;
`;

export const LabelMessage = styled(H3Bold)`
  color: ${(props) => props.theme.colors.layout.whitePrimary};
`;

export const WrapperControls = styled.div`
  height: 90vh;
  display: flex;
  margin-top: -90vh;
  flex-direction: column;
`;
