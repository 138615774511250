import styled from "styled-components";
import BoardGeofences from "Components/Map/Geofences/BoardGeofences";
import TableOptions from "Components/TableOptions";
import InternMachineStatus from "Components/Map/InternMachines/InternMachineStatus";

export const ListagemGeocercas = styled(BoardGeofences)`
`;

export const ListagemContainer = styled.div`
  z-index: 800;
  position: absolute;
  top: 75px;
  right: 20px;

  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 85%;
`;

export const ListagemPaCarregadeira = styled(InternMachineStatus)`
`;

export const Filters = styled(TableOptions)`
  z-index: 800;
  position: absolute;
  top: 0px;
  left: 0px;
`;
