/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from "react";
import MapContainer from "Components/Map";
import HeatMap from "Components/Map/HeatMap";

import { MachineStale } from "declarations";
import toasts from "Shared/toasts";
import useSearchDate from "Components/TableOptions/SearchDate/useSearchDate";
import { DateRangeType } from "Components/TableOptions/SearchDate";
import dayjs from "dayjs";
import theme from "Styles/theme";
import { Backdrop, CircularProgress } from "@material-ui/core";
import useCrud from "Hooks/useCrud";
import { getStaleMachines } from "Api/MachineStales";
import { Filters } from "./styles";

const initDateRange: DateRangeType = {
  startDate: dayjs(new Date()).subtract(7, "days").toDate(),
  endDate: dayjs(new Date()).endOf("day").toDate(),
  color: theme.colors.interactive.primary,
  key: "selection",
  showDateDisplay: false,
};

const PlacasParadas = () => {
  const {
    listCrud,
    loading,
    setListCrud,
    setLoading,
    filters,
    setFilters,
  } = useCrud<MachineStale>();

  const { SearchDateRange, dateRange, tagDate, setSearchDate } = useSearchDate({
    filters,
    setFilters,
    defaultRange: initDateRange,
  });

  const getAltitude = (value: MachineStale) => (
    Math.min(1, dayjs(value.machine.locationDate).diff(value.staleDate, 'h') / 24)
  )

  const points = listCrud.map((value) => [
    ...value.machine.location.coordinates,
    Math.abs(getAltitude(value))
  ]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const date = dateRange
        ? [
            dateRange.startDate.toISOString(),
            dateRange.endDate.toISOString(),
          ]
        : [
          initDateRange.startDate.toISOString(),
          initDateRange.endDate.toISOString(),
        ];
      
  
      const data = await getStaleMachines(date);
      setListCrud(data);
    }
    catch(error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [dateRange]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    tagDate();
  }, [tagDate]);

  const subtitleScreen =
    listCrud.length > 1
      ? `(${listCrud.length} pontos)`
      : `(${listCrud.length} ponto)`;

  const itemsFilter = [
    {
      label: "Data",
      action: () => setSearchDate(true),
    },
  ];

  const removeFilter = (index: number) => {
    if (filters[index].name === "date") {
      setSearchDate(true);
    }
  };

  return (
    <MapContainer
      title="Mapa de Calor - Veículos Parados"
      subtitle={subtitleScreen}
      center={[-20.54213711944774, -43.74270918881854]}
      zoom={13}
      positionZoomControl="bottomleft"
    >
      <Backdrop open={loading} style={{ zIndex: 800 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <HeatMap points={points} />
      <Filters
        itemsFilter={itemsFilter}
        removeTag={removeFilter}
        filtersTags={filters}
      />
      {SearchDateRange()}
    </MapContainer>
  );
};

export default PlacasParadas;
