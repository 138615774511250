import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:  'Open Sans', 'sans-serif';

    ::-webkit-scrollbar {
      background: white;
      width: 16px;
    }
    
    ::-webkit-scrollbar-thumb {
      background: #ced2d5 0% 0% no-repeat padding-box;
      border-radius: 20px;
      border: 4px solid #fff;
    }

  }

  body {
    background: ${(props) => props.theme.colors.layout.whitePrimary};
        
  }

  .leaflet-container {
  width: 100%;
  height: 100%;
  }

  .sr-only {
    display: none;
  }

  #mapid { height: 180px; }

  .my-div-image {
    content-visibility: auto;
  }

  .my-div-span {
    text-align: center;
    background: ${(props) => `${props.theme.colors.layout.blackPrimary}B0`};
    color: white;
    width: 100px;
    display: block;
    border: none;
    margin-left: -250%;
    padding:  2.5px 5px;
    border-radius: 4px;
    margin-top: -15px;
    font-weight: 600;
  }
`;
