import React, { useEffect, useState } from "react";

import IconButton from "@material-ui/core/IconButton";
import Play from "@material-ui/icons/PlayArrow";
import SkipNext from "@material-ui/icons/SkipNext";
import SkipPrevious from "@material-ui/icons/SkipPrevious";
import { Pause, Stop } from "@material-ui/icons";
import theme from "Styles/theme";
import { LabelTime, StyledSlider, Wrapper } from "./styles";

interface ControlPathProps {
  onPrev: () => void;
  onNext: () => void;
  beginHour: string;
  endHour: string;
  min: number;
  max: number;
  step: number;
  valueSlider: number;
  onChangePicker: (value: number) => void;
  onPlay?: () => void;
  onPause?: () => void;
  isPlay?: boolean;
}

const ControlPath = ({
  beginHour,
  endHour,
  max,
  min,
  onChangePicker,
  onNext,
  onPrev,
  step,
  valueSlider,
  onPlay,
  isPlay,
  onPause,
}: ControlPathProps) => {
  const handleChange = (value: number | number[]) => {
    if (typeof value === "number") {
      onChangePicker(value);
    } else {
      onChangePicker(value[0]);
    }
  };

  return (
    <Wrapper>
      <IconButton onClick={onPrev}>
        <SkipPrevious htmlColor={theme.colors.layout.blackPrimary} />
      </IconButton>
      {isPlay ? (
        <IconButton onClick={onPause}>
          <Pause htmlColor={theme.colors.layout.blackPrimary} />
        </IconButton>
      ) : (
        <IconButton onClick={onPlay}>
          <Play htmlColor={theme.colors.layout.blackPrimary} />
        </IconButton>
      )}
      <IconButton onClick={onNext}>
        <SkipNext htmlColor={theme.colors.layout.blackPrimary} />
      </IconButton>
      <LabelTime>
        {beginHour}/{endHour}
      </LabelTime>
      <StyledSlider
        min={min}
        max={max}
        valueLabelDisplay="off"
        value={valueSlider}
        step={step}
        onChange={(_, value) => handleChange(value)}
      />
    </Wrapper>
  );
};

export default ControlPath;
