import styled from "styled-components";
import { LabelWrapper, Label } from "../Collapisable/styles";

export const Dot = styled.div`
  background-color: ${(props) => props.theme.colors.layout.blackTertiary};
  height: 10px;
  width: 10px;
  min-width: 10px;
  border-radius: 50%;
`;
export const SubItemWrapper = styled(LabelWrapper)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 6.5%;
`;
export const Text = styled(Label)``;
export const Trapeze = styled.div`
  border-radius: 8px 0px 0px 8px;
  width: 3%;
  height: 100%;
`;
export const Box = styled.div<{ active: boolean }>`
  opacity: 1;
  width: 100%;
  border-radius: 8px;
  height: 40px;
  display: flex;
  align-items: center;

  background-color: ${(props) =>
    props.active && props.theme.colors.layout.blackSecondary};

  ${Dot} {
    background-color: ${(props) =>
      props.active && props.theme.colors.layout.whitePrimary};
  }

  ${Trapeze} {
    background-color: ${(props) =>
      props.active && props.theme.colors.layout.whitePrimary};
  }

  ${Text} {
    color: ${(props) => props.theme.colors.layout.whitePrimary};
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.layout.blackSecondary};
  }
  &:hover ${Dot} {
    background-color: ${(props) => props.theme.colors.layout.whitePrimary};
  }
  &:hover ${Trapeze} {
    background-color: ${(props) => props.theme.colors.layout.whitePrimary};
  }
  &:hover ${Text} {
    color: ${(props) => props.theme.colors.layout.whitePrimary};
  }
`;
