import { AddOutlined } from "@material-ui/icons";
import ColorButton from "BaseComponents/Buttons/ColorButton";
import HeaderButton from "BaseComponents/Buttons/HeaderButton";
import styled from "styled-components";

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
  height: 100%;
  text-align: center;
  gap: 20px;
  overflow: auto;
`;

export const StyledButton = styled(ColorButton)`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  min-height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;

  margin-top: 10px;
`;

export const AddIcon = styled(AddOutlined)`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  font-size: 14px;
`;

export const WrapperShifts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

export const WrapperInputs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
  gap: 20px;
`;
