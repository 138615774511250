import styled from "styled-components";

export const MapMosaicContainer = styled.div<{
  pointsOfInterestLenght: number;
  widthOfContainer?: number;
  heightOfContainer?: number;
}>`
  display: flex;
  flex: 1;
  min-width: ${({ pointsOfInterestLenght }) =>
    pointsOfInterestLenght ? 3000 / (pointsOfInterestLenght * 1.3) : 300}px;
  flex-direction: column;
  align-items: center;
  border: solid ${(props) => props.theme.colors.layout.blackPrimary} 1px;
`;

export const MosaicWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex: 1;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
`;

export const EmptyFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
`;

export const EmptyFilterText = styled.p`
  font-size: 18px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  font-weight: bold;
  margin-bottom: 10px;
`;
