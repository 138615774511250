import React, { useState } from "react";
import Zoom from "@material-ui/core/Zoom";
import { v4 as uuidv4 } from "uuid";
import { Wrapper } from "./styles";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  className?: string;
}

const Modal = ({ open, onClose, children, className }: ModalProps) => {
  const [key, setKey] = useState(uuidv4());
  return (
    <Wrapper
      BackdropProps={{
        timeout: 500,
      }}
      className={className}
      open={open}
      onClose={onClose}
    >
      <Zoom in={open} onEnter={() => setKey(uuidv4())}>
        <div key={key}>{children}</div>
      </Zoom>
    </Wrapper>
  );
};

export default Modal;
