/* eslint-disable no-underscore-dangle */
import { Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { MapConsumer, MapContainer, TileLayer } from "react-leaflet";
import GeofencePolygon from "Components/Map/Geofences/Geofence";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { Geofence, GeofenceWithMachines } from "declarations";
import toasts from "Shared/toasts";
import { v4 as uuidv4 } from "uuid";
import { getGeofences } from "Api/Geofences";
import { LatLngExpression } from "leaflet";
import { center } from "Shared/utils";

import { H4SemiBold } from "BaseComponents/Typographies";
import { WrapperContent, WrapperChoseOwner, LabelOwner, Title } from "./styles";
import { StyledList, WrapperMap } from "../styles";

interface OwnerProps {
  owner?: Geofence;
  selectOwner: (value: Geofence) => void;
}

const Owner = ({ owner, selectOwner }: OwnerProps) => {
  const [origins, setOrigins] = useState<Geofence[]>([]);
  const [totalOrigins, setTotalOrigins] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [coordinatesToZoom, setToZoom] = useState<LatLngExpression>();
  const fetchGeofences = async (skip: number) => {
    try {
      setLoading(true);
      const response = await getGeofences(skip);
      setTotalOrigins(response.total);
      if (skip === 0) {
        setOrigins([...response.data]);
      } else {
        setOrigins([...origins, ...response.data]);
      }
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGeofences(0);
  }, []);

  const selectOrigin = (geofence: Geofence | GeofenceWithMachines) => {
    selectOwner({ ...geofence });
  };
  const setZoom = (geofence: Geofence | GeofenceWithMachines) => {
    setToZoom(center(geofence.coordinates.coordinates));
  };

  const hasNextPage: boolean = totalOrigins > origins.length;

  const fetchMore = async () => {
    if (isLoading) return;

    await fetchGeofences(origins.length);
  };

  return (
    <WrapperContent>
      <WrapperChoseOwner>
        <Title>Escolha o alvo principal:</Title>

        {owner && (
          <>
            <H4SemiBold>Alvo selecionado:</H4SemiBold>
            <LabelOwner>{owner.name}</LabelOwner>
          </>
        )}
      </WrapperChoseOwner>
      <WrapperMap style={{}}>
        <Backdrop open={isLoading} style={{ zIndex: 800 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <MapContainer
          zoom={13}
          center={[-20.54213711944774, -43.74270918881854]}
          zoomControl={false}
          style={{ width: "100%", height: "100%" }}
        >
          <ReactLeafletGoogleLayer
            apiKey={process.env.REACT_APP_GMAPS_API_KEY}
            type="satellite"
          />
          <TileLayer url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png" />
          {origins.map((origin, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <GeofencePolygon
                key={origin?._id || uuidv4()}
                geofence={origin}
                onClick={selectOrigin}
              />
            );
          })}

          <MapConsumer>
            {(map) => {
              coordinatesToZoom && map.flyTo(coordinatesToZoom);
              return null;
            }}
          </MapConsumer>
        </MapContainer>
        <StyledList
          hasNextPage={hasNextPage}
          onLoadMore={fetchMore}
          origins={origins}
          onClick={setZoom}
          onSelect={selectOrigin}
          title="Alvos disponíveis"
          position="Right"
        />
      </WrapperMap>
    </WrapperContent>
  );
};

export default Owner;
