/* eslint-disable no-underscore-dangle */
import React, { useContext, useState } from "react";
import UserContext from "Contexts/User";
import dayjs from "dayjs";
import { ForbiddenDestination, Geofence } from "declarations";
import { useMap } from "react-leaflet";
import { LatLngExpression, Layer } from "leaflet";
import Button from "BaseComponents/Buttons/HeaderButton";
import { center, toMilliseconds } from "Shared/utils";
import SimpleSpinner from "BaseComponents/Spinners/SimpleSpinner";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { v4 as uuidv4 } from "uuid";
import { updateForbidden } from "Api/ForbiddenDestination";
import toasts from "Shared/toasts";
import { DestinationValidation } from "./Validation";

import {
  Wrapper,
  ListWrapper,
  EditWrapper,
  Label,
  DeleteIcon,
  ItemWrapper,
  InputName,
  ButtonsWrapper,
  ButtonAction,
  Header,
  List,
  EditIcon,
  StyledAvatar,
} from "./styles";
import Create from "./Create";
import Edit from "./Edit";

interface CrudDestinationsProps {
  destinations: ForbiddenDestination[];
  centralize?: (latlng: LatLngExpression) => void;
  remove?: (id: string) => void;
  creating?: number[][];
  create: (newDestination?: ForbiddenDestination) => void;
  cancel?: () => void;
  layer?: Layer;
  loading: boolean;
  pages?: number;
  hasNextPage: boolean;
  fetchDestinations?: (skip: number) => void;
  originsOfDestintion?: (values: Geofence[]) => void;
  loadMore: () => void;
}

const CrudGeofences = ({
  destinations,
  centralize,
  remove,
  creating,
  create,
  cancel,
  layer,
  loading,
  loadMore,
  pages,
  hasNextPage,
  originsOfDestintion = () => {},
  fetchDestinations = () => {},
}: CrudDestinationsProps) => {
  const map = useMap();

  const { isAdmin } = useContext(UserContext);
  const [destinationEdit, setDestinationEdit] =
    useState<ForbiddenDestination | null>(null);

  const handleClick = (geofence: ForbiddenDestination) => {
    const point = center(geofence.coordinates.coordinates);

    originsOfDestintion(geofence.origins);

    map.flyTo(point);
  };

  const createDestination = (name: string, origins: Geofence[]) => {
    if (creating) {
      const lastCoordinate = creating[0];
      const coordinates = [
        [
          ...creating.map(([latitude, longitude]) => [longitude, latitude]),
          [lastCoordinate[1], lastCoordinate[0]],
        ],
      ];
      const newDestination: ForbiddenDestination = {
        name,
        coordinates: {
          type: "Polygon",
          coordinates,
        },
        origins,
      };

      create(newDestination);
    }
  };

  const cancelCreate = () => {
    if (cancel && layer) {
      map.removeLayer(layer);
      cancel();
    }
  };

  const cancelEdit = () => {
    setDestinationEdit(null);
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
  });

  const onSubmit = (data: any) => {
    createDestination(data.name, data.origins);
  };

  const editSubmit = async (data: any) => {
    if (!destinationEdit) return;

    const newDestination: ForbiddenDestination = {
      _id: destinationEdit._id,
      name: data.name,
      coordinates: {
        type: "Polygon",
        coordinates: destinationEdit?.coordinates.coordinates,
      },
      origins: data.origins,
    };

    try {
      await updateForbidden(newDestination);
      await fetchDestinations(0);
      setDestinationEdit(null);
    } catch (error) {
      toasts.error((error as Error).message);
    }
  };

  const content = () => {
    if (destinationEdit) {
      return (
        <Edit
          cancelEdit={cancelEdit}
          destination={destinationEdit}
          loading={loading}
          onSubmit={editSubmit}
        />
      );
    }

    if (creating) {
      return (
        <Create
          creating={creating}
          onSubmit={onSubmit}
          cancelCreate={cancelCreate}
          loading={loading}
          originsToCreate={originsOfDestintion}
        />
      );
    }

    return (
      <>
        <Header>Listagem</Header>

        <ListWrapper ref={rootRef}>
          <List>
            {destinations.map((destination, index) => {
              return (
                <ItemWrapper key={destination._id || uuidv4()}>
                  <StyledAvatar exceeded={false}>
                    {destination.name[0].toUpperCase()}
                  </StyledAvatar>
                  <Label onClick={() => handleClick(destination)}>
                    {destination.name}
                  </Label>
                  {isAdmin && (
                    <>
                      <Button onClick={() => setDestinationEdit(destination)}>
                        <EditIcon
                          onClick={() => setDestinationEdit(destination)}
                        />
                      </Button>
                      <Button
                        onClick={() => remove && remove(destination._id || "")}
                      >
                        <DeleteIcon />
                      </Button>
                    </>
                  )}
                </ItemWrapper>
              );
            })}
            {loading && <SimpleSpinner ref={sentryRef} />}
          </List>
        </ListWrapper>
      </>
    );
  };

  return <Wrapper>{content()}</Wrapper>;
};

export default CrudGeofences;
