import React, { useCallback, useEffect, useState } from "react";
import sharedSentences from "Shared/sentences";
import { diffTimeToString, fromMilliseconds } from "Shared/utils";

import {
  Wrapper,
  Label,
  StyledAvatar,
  WrapperLabels,
  Title,
  SubTitle,
} from "./styles";

interface ItemGeofenceProps {
  title: string;
  averageTime?: number;
  averageTimeNow?: number;
  onClick?: () => void;
  machines?: number;
  exceeded?: boolean;
}

const ItemGeofence = ({
  title,
  averageTime,
  averageTimeNow,
  machines,
  onClick = () => {},
  exceeded = false,
}: ItemGeofenceProps) => {
  const showMachine = machines !== undefined;
  const showLabel = averageTimeNow !== undefined;

  const labelMachines = `${machines} ${sharedSentences.trucks}`;

  const labelToShow = averageTimeNow
    ? diffTimeToString(new Date().getTime(), averageTimeNow)
    : diffTimeToString(0, 0);

  return (
    <Wrapper
      onClick={(e) => {
        onClick();
      }}
      onDoubleClickCapture={(e) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.preventDefault();
        e.nativeEvent.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      <StyledAvatar exceeded={exceeded}>{title[0]}</StyledAvatar>
      <WrapperLabels>
        <Title exceeded={exceeded}>{title}</Title>
        {showLabel && <SubTitle>{labelToShow}</SubTitle>}
        {showMachine && <SubTitle>{labelMachines}</SubTitle>}
      </WrapperLabels>
    </Wrapper>
  );
};

export default ItemGeofence;
