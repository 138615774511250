import { Card } from "@material-ui/core";
import styled, { css } from "styled-components";

export const TitleWrapper = styled.div`
  border-bottom: 1px solid #f2f4f7;
  padding-bottom: 6px;
`;

export const Title = styled.span`
  text-align: left;
  font: normal normal bold 18px Open Sans;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  opacity: 1;
`;

export const InfoWrapper = styled.div`
  margin-top: 6px;
  display: flex;
  flex-direction: row;
`;

export const LabelInfo = styled.span`
  font: normal normal 16px Open Sans;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  opacity: 1;
  margin-left: 7px;
`;

export const InfoTitle = styled.span`
  font: normal normal 600 16px Open Sans;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  opacity: 1;
  margin-left: 7px;
`;

export const Arrow = styled.p<{
  placement: "bottom" | "right" | "top" | "left";
}>`
  position: absolute;
  font-size: 7;

  &::before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }

  ${(props) => {
    switch (props.placement) {
      case "bottom":
        return css`
          margin-top: -0.9em;
          width: 3em;
          height: 1em;
          &::before {
            border-width: 0 1em 1em 1em;
            border-color: transparent transparent
              ${props.theme.colors.layout.whitePrimary} transparent;
          }
        `;

      case "left":
        return css`
          right: 0;
          margin-left: -0.3em;
          width: 1em;
          height: 3em;
          &::before {
            border-width: 1em 0 1em 1em;
            border-color: transparent transparent transparent
              ${props.theme.colors.layout.whitePrimary};
          }
        `;
      case "right":
        return css`
          left: 0;
          margin-left: -0.3em;
          width: 1em;
          height: 3em;
          &::before {
            border-width: 1em 1em 1em 0;
            border-color: transparent ${props.theme.colors.layout.whitePrimary}
              transparent transparent;
          }
        `;
      case "top":
        return css`
          bottom: 0;
          left: 0;
          margin-bottom: -0.3em;
          width: 3em;
          height: 1em;
          &::before {
            border-width: 1em 1em 0 1em;
            border-color: ${props.theme.colors.layout.whitePrimary} transparent
              transparent transparent;
          }
        `;

      default:
        return css`
          margin-top: -0.9em;
          width: 3em;
          height: 1em;
          &::before {
            border-width: 0 1em 1em 1em;
            border-color: transparent transparent
              ${props.theme.colors.layout.whitePrimary} transparent;
          }
        `;
    }
  }}
`;

export const Wrapper = styled(Card)`
  margin: 10px;
  max-width: 450px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
`;
