import { getTrips } from "Api/Trip";
import SimpleSpinner from "BaseComponents/Spinners/SimpleSpinner";
import { H2SemiBold, H3SemiBold } from "BaseComponents/Typographies";
import ItemTrip from "Components/Map/Trips/ItemTrip";
import TableOptions, { IFilter } from "Components/TableOptions";
import { DateRangeType } from "Components/TableOptions/SearchDate";
import useSearchDate from "Components/TableOptions/SearchDate/useSearchDate";
import dayjs from "dayjs";
import { TripInfo, Unit } from "declarations";
import React, { useCallback, useEffect, useState } from "react";
import { timeInMS } from "Shared/constants";
import toasts from "Shared/toasts";
import theme from "Styles/theme";
import { v4 as uuidv4 } from "uuid";
import DefaultWrapper from "../DefaultWrapper";
import { FilterOption, Title, WrapperTrips } from "./styles";

const { ONE_MINUTE } = timeInMS;
const TWENTY_MINUTES = ONE_MINUTE * 20;
interface TripsBoardProps {
  selectedUnit?: Unit;
}

const initDateRange: DateRangeType = {
  startDate: dayjs(new Date()).startOf("day").toDate(),
  endDate: dayjs(new Date()).endOf("day").toDate(),
  color: theme.colors.interactive.primary,
  key: "selection",
  showDateDisplay: false,
};

const TripsBoard = ({ selectedUnit }: TripsBoardProps) => {
  const [loadingTrips, setLoadingTrips] = useState(false);
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [trips, setTrips] = useState<TripInfo[]>([]);
  const { SearchDateRange, dateRange, setSearchDate, tagDate } = useSearchDate({
    filters,
    setFilters,
    defaultRange: initDateRange,
  });

  const range = dateRange || initDateRange;

  const fetchTrips = useCallback(async () => {
    if (selectedUnit) {
      try {
        setLoadingTrips(true);

        const geofencesRange = [
          selectedUnit.owner.name,
          ...selectedUnit.attendees.map((value) => value.name),
        ];

        const response = await getTrips({
          dateIn: range.endDate,
          dateOut: range.startDate,
          geofenceIn: [...geofencesRange],
          geofenceOut: [...geofencesRange],
        });

        setTrips(response);
      } catch (error) {
        toasts.error("Erro ao buscar viagens");
      } finally {
        setLoadingTrips(false);
      }
    }
  }, [selectedUnit, range.startDate, range.endDate]);

  useEffect(() => {
    fetchTrips();
    const interval = setInterval(() => fetchTrips(), TWENTY_MINUTES);

    return () => clearInterval(interval);
  }, [fetchTrips]);

  const itemsFilter = [
    {
      label: "Data",
      action: () => setSearchDate(true),
    },
  ];

  useEffect(() => {
    tagDate();
  }, [tagDate]);

  const Trips = () => {
    return trips.map((trip) => (
      <ItemTrip key={uuidv4()} onClick={() => {}} trip={trip} />
    ));
  };

  return (
    <DefaultWrapper
      style={{
        width: "100%",
        textAlign: "center",
        maxHeight: "400px",
      }}
      visible={!!selectedUnit}
    >
      {loadingTrips ? (
        <SimpleSpinner />
      ) : (
        <>
          <div>
            <FilterOption itemsFilter={itemsFilter} filtersTags={filters} />
            <Title>Viagens unidade</Title>
          </div>

          <WrapperTrips>{Trips()}</WrapperTrips>
        </>
      )}

      {SearchDateRange()}
    </DefaultWrapper>
  );
};

export default TripsBoard;
