import React, { useEffect, useState } from "react";
import PageWrapper from "Components/Wrappers/PageWrapper";
import { Save } from "@material-ui/icons";
import Input from "BaseComponents/Inputs/Input";
import { timeMask } from "Shared/masks";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import toasts from "Shared/toasts";
import { getSettings, updateSettings } from "Api/Settings";
import { Settings } from "declarations";
import { milliseconds } from "date-fns";
import { fromMillisecondsToHour, toMilliseconds } from "Shared/utils";
import sharedSentences from "Shared/sentences";
import {
  Body,
  ButtonCancel,
  Footer,
  Wrapper,
  ButtonSubmit,
  Option,
  OptionLabel,
  OptionChangeValue,
  Title,
  SubTitle,
} from "./styles";

interface FormShape {
  maxUpdateTime: string;
}

const Configuracoes = () => {
  const [isLoading, setLoading] = useState(false);
  const [settingsId, setSettingsId] = useState<string>();

  const { register, handleSubmit, errors, reset, setValue, watch } =
    useForm<FormShape>({});

  const fetchSettings = async () => {
    try {
      setLoading(true);

      const response = await getSettings();

      const settingsAux = response.data[response.data.length - 1];

      // eslint-disable-next-line no-underscore-dangle
      setSettingsId(settingsAux._id);

      setValue(
        "maxUpdateTime",
        fromMillisecondsToHour(settingsAux.maxUpdateTime)
      );
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const saveSettings = async (data: FormShape) => {
    if (settingsId) {
      try {
        setLoading(true);

        await updateSettings(settingsId, {
          maxUpdateTime: toMilliseconds(data.maxUpdateTime),
        });
        toasts.success("Configurações salvas com sucesso!");
        await fetchSettings();
      } catch (error) {
        toasts.error((error as Error).message);
      } finally {
        setLoading(false);
      }
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    if (isLoading) return;

    await saveSettings(data);
  });

  return (
    <PageWrapper title="Configurações">
      <Wrapper onSubmit={onSubmit}>
        <Body>
          <Option>
            <OptionLabel>
              <Title>{`Tempo de atualização da ${sharedSentences.truck.toLowerCase()}`}</Title>
              <SubTitle>
                {`Define o tempo máximo sem atualização que a ${sharedSentences.truck.toLowerCase()} ainda pode
                contabilizar nas métricas.`}
              </SubTitle>
            </OptionLabel>
            <OptionChangeValue>
              <Input
                errorText={errors.maxUpdateTime?.message || ""}
                label={`Atualização da ${sharedSentences.truck.toLowerCase()}`}
                type="text"
                name="maxUpdateTime"
                ref={register}
                onChange={(e) => {
                  setValue("maxUpdateTime", timeMask(e.target.value));
                }}
              />
            </OptionChangeValue>
          </Option>
        </Body>
        <Footer>
          <ButtonSubmit isLoading={isLoading} type="submit">
            Salvar
          </ButtonSubmit>
        </Footer>
      </Wrapper>
    </PageWrapper>
  );
};

export default Configuracoes;
