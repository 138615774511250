import React, { useCallback, useContext, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { getUnits } from "Api/Units";
import Select from "BaseComponents/Inputs/Select";
import GeofencesBoard from "Components/DashboardComponents/GeofencesBoard";
import GraphBarWithLine from "Components/DashboardComponents/GraphBarWithLine";
import ShiftBoard from "Components/DashboardComponents/ShiftBoard";
import TripsBoard from "Components/DashboardComponents/TripsBoard";
import PageWrapper from "Components/Wrappers/PageWrapper";
import UserContext from "Contexts/User";
import useLiveMap from "Hooks/useLiveMap";
import DefaultDashboard from "Pages/Dashboard";
import { timeInMS } from "Shared/constants";
import toasts from "Shared/toasts";
import dayjs from "dayjs";
import { Unit } from "declarations";
import {
  ContentWrapper,
  DataWrapper,
  OwnerWrapper,
  WrapperShifts,
} from "./styles";

const { ONE_MINUTE } = timeInMS;

const Dashboard = () => {
  const { isAdmin, user, logout } = useContext(UserContext);

  const { fetchGeofences, geofences } = useLiveMap();

  const [loading, setLoading] = useState(false);

  const [units, setUnits] = useState<Unit[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);

  const geofencesName = !selectedUnit ? [] : [selectedUnit.owner.name];

  const fetchUnits = useCallback(async () => {
    try {
      setLoading(true);

      const response = await getUnits(0);

      setUnits(response.data);

      setSelectedUnit((prev) => {
        if (prev) {
          const newUnit = response.data.find(
            (unitRes) => unitRes.name === prev.name
          );
          if (newUnit) {
            return newUnit;
          }
        }
        return prev;
      });
    } catch (error) {
      toasts.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUnits();
  }, [fetchUnits]);

  useEffect(() => {
    fetchGeofences();
    const interval = setInterval(() => fetchGeofences(), ONE_MINUTE);

    return () => clearInterval(interval);
  }, [fetchGeofences]);

  const ShiftsBoards = () => {
    if (selectedUnit) {
      const shifts = selectedUnit.shifts.map((value, index) => {
        const name = `Turno ${index + 1}`;
        const newBeg = dayjs(value[0] ? value[0] : new Date());
        const newEnd = dayjs(value[1] ? value[1] : new Date());

        const beg = dayjs()
          .set("h", newBeg.hour())
          .set("minute", newBeg.minute());

        const end = dayjs()
          .set("h", newEnd.hour())
          .set("minute", newEnd.minute());

        return (
          <ShiftBoard
            key={name}
            name={name}
            beg={beg.toDate()}
            end={end.toDate()}
            unit={selectedUnit}
          />
        );
      });

      return shifts;
    }

    return [];
  };

  const LongShiftsBoards = () => {
    if (selectedUnit) {
      const monthShift = (
        <ShiftBoard
          name="Esse mês"
          beg={dayjs().startOf("M").toDate()}
          end={dayjs().endOf("M").toDate()}
          unit={selectedUnit}
        />
      );

      const dayShift = (
        <ShiftBoard
          name="Hoje"
          beg={dayjs().startOf("d").toDate()}
          end={dayjs().endOf("d").toDate()}
          unit={selectedUnit}
        />
      );

      return [dayShift, monthShift];
    }

    return [];
  };

  const GraphTimePermanenceDays = () => {
    if (selectedUnit) {
      return (
        <GraphBarWithLine
          beg={dayjs().startOf("M").toDate()}
          end={dayjs().toDate()}
          name="TTP por dia"
          unit={selectedUnit}
          update={fetchUnits}
        />
      );
    }

    return null;
  };

  const itemsSelect = [
    ...units.map((unit) => {
      return {
        label: unit.name,
        value: unit,
      };
    }),
    {
      label: "Padrão",
      value: null,
    },
  ];

  const renderData = () => {
    if (selectedUnit) {
      return (
        <>
          <DataWrapper>
            <WrapperShifts>{ShiftsBoards()}</WrapperShifts>
            <WrapperShifts>{LongShiftsBoards()}</WrapperShifts>
            <WrapperShifts height={350}>
              {GraphTimePermanenceDays()}
            </WrapperShifts>
            <WrapperShifts height={350}>
              <TripsBoard selectedUnit={selectedUnit} />
            </WrapperShifts>
          </DataWrapper>
          <OwnerWrapper>
            <GeofencesBoard selectedUnit={selectedUnit} geofences={geofences} />
          </OwnerWrapper>
        </>
      );
    }

    return <DefaultDashboard />;
  };

  return (
    <PageWrapper title="Dashboard">
      <Backdrop open={loading} style={{ zIndex: 800 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Select<Unit | null>
        onChange={(value) => setSelectedUnit(value)}
        items={itemsSelect}
      />
      <ContentWrapper>{renderData()}</ContentWrapper>
    </PageWrapper>
  );
};

export default Dashboard;
