import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MarkerProps, Marker, useMap } from "react-leaflet";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ptBr from "dayjs/locale/pt-br";
import { diffTimeToString } from "Shared/utils";
import { MachineWithGeoInfo } from "declarations";
import { LatLngExpression } from "leaflet";
import sharedSentences from "Shared/sentences";
import IconTruck from "./IconTruck";
import PopupTruck, { TimeGeofence } from "./PopupTruck";

dayjs.extend(relativeTime);
dayjs.locale(ptBr);

interface MarkerTruckProps extends MarkerProps {
  popup?: boolean;
  machine: MachineWithGeoInfo;
  position: LatLngExpression;
  subtitleOption?: string;
  filterMarker?: string[];
  currentZoom?: number;
}

const MarkerTruck = ({
  popup,
  machine,
  position,
  subtitleOption = "Tranportadora",
  filterMarker = [],
  currentZoom
}: MarkerTruckProps) => {
  const [exceeded, setExceeded] = useState(false);
  const [exceededSpeed, setExceededSpeed] = useState(false);
  const [listTimes, setListTimes] = useState<TimeGeofence[]>([]);
  const map = useMap();
  const handleClick = () => {
    map.flyTo(position);
  };

  const lat = position.toString().split(",")[0];
  const lng = position.toString().split(",")[1];

  const title = useMemo(() => {
    switch (subtitleOption) {
      case sharedSentences.client: {
        const transport = machine.client
          ? machine.client.split(" ")
          : undefined;
        return !transport
          ? machine.telemetry
          : transport
              .filter((value, index) => index < 2)
              .toString()
              .replace(",", " ");
      }
      case sharedSentences.telemetry: {
        return machine.telemetry;
      }
      case sharedSentences.truck: {
        return machine.plaque;
      }
      case "Nenhum": {
        return undefined;
      }
      default:
        return machine.telemetry;
    }
  }, [machine.client, machine.plaque, machine.telemetry, subtitleOption]);

  const initialData = useCallback(() => {
    if (machine.geofenceInfo) {
      const keys = Object.keys(machine.geofenceInfo);
      let situation: boolean = false;
      let situationSpeed: boolean = false;

      const times = keys.map((value) => {
        if (!situation) {
          const diff =
            new Date().getTime() -
            new Date(machine.geofenceInfo[value].dateIn).getTime();

          situation =
            machine.geofenceInfo[value].situation === "Exceeded" ||
            diff > machine.geofenceInfo[value].averageTime;
        }

        if (!situationSpeed)
          situationSpeed =
            machine.geofenceInfo[value].situationSpeed === "Exceeded";

        return {
          geofenceName: value,
          time: diffTimeToString(
            new Date().getTime(),
            new Date(machine.geofenceInfo[value].dateIn).getTime()
          ),
        } as TimeGeofence;
      });

      setExceeded(situation);
      setListTimes([...times]);
      setExceededSpeed(situationSpeed);
    } else {
      setExceeded(false);

      setExceededSpeed(false);
    }
  }, [machine]);

  useEffect(() => {
    initialData();
  }, [initialData]);

  const icon = useMemo(() => {
    const iconSize = currentZoom && currentZoom > 16 ? 25 : 40

    return IconTruck(
      title,
      exceeded,
      exceededSpeed,
      machine.longTimeUpdate,
      iconSize
    );
  }, [exceeded, exceededSpeed, machine.longTimeUpdate, title, currentZoom]);

  const showMarker = useMemo(() => {
    if (filterMarker.length === 0) return true;

    if (!exceeded) {
      if (!exceededSpeed) {
        if (!machine.longTimeUpdate) {
          return filterMarker.includes(sharedSentences.markerSituations.normal);
        }
      }
    }

    if (
      exceeded &&
      exceededSpeed &&
      !filterMarker.includes(
        sharedSentences.markerSituations.exceededTimeAndSpeed
      )
    )
      return false;

    if (
      exceeded &&
      !filterMarker.includes(sharedSentences.markerSituations.exceeded)
    )
      return false;

    if (
      exceededSpeed &&
      !filterMarker.includes(sharedSentences.markerSituations.exceededSpeed)
    )
      return false;

    if (
      machine.longTimeUpdate &&
      !filterMarker.includes(sharedSentences.markerSituations.longTimeUpdate)
    )
      return false;

    return true;
  }, [exceeded, exceededSpeed, machine.longTimeUpdate, filterMarker]);

  return showMarker ? (
    <Marker
      position={position}
      title={title}
      icon={icon}
      eventHandlers={{ click: handleClick }}
    >
      {popup && (
        <PopupTruck
          geofenceInfo={machine.geofenceInfo}
          latlngLabel={`Lat: ${lat} / Lng: ${lng}`}
          listTimes={listTimes}
          speed={machine.speed}
          telemetry={machine.telemetry}
          title={machine.plaque}
          lastUpdate={machine.locationDate}
          client={machine.client}
        />
      )}
    </Marker>
  ) : null;
};

export default MarkerTruck;
