import React from "react";
import { Wrapper } from "./styles";

interface DefaultWrapperProps {
  children: React.ReactNode;
  visible?: boolean;
  style?: React.CSSProperties;
}

const DefaultWrapper = ({
  children,
  visible = true,
  style,
}: DefaultWrapperProps) => {
  return (
    <Wrapper visible={visible} style={style}>
      {children}
    </Wrapper>
  );
};

export default DefaultWrapper;
