import {
  Card,
  CardContent,
  CardActions,
  Button,
  Fade,
} from "@material-ui/core";
import Popper, { PopperProps } from "@material-ui/core/Popper";
import { H3SemiBold, H4Bold, H4SemiBold } from "BaseComponents/Typographies";
import React, { useRef } from "react";
import { Arrow, Message, StyledActions, StyledCard } from "./styles";

type PropsPopper = Pick<PopperProps, "open" | "anchorEl" | "id">;

interface Props extends PropsPopper {
  onConfirm?: () => void;
  onCancel?: () => void;
  message?: string;
  placement?: "bottom" | "right" | "top" | "left";
}

const ConfirmationPopper = ({
  open,
  anchorEl,
  id,
  onCancel = () => {},
  onConfirm = () => {},
  message,
  placement = "bottom",
}: Props) => {
  const arrowRef = useRef<HTMLParagraphElement | null>(null);

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      style={{ zIndex: 10000 }}
      transition
      placement={placement}
      modifiers={{
        flip: {
          enabled: true,
        },
        arrow: {
          enabled: !!arrowRef.current,
          element: arrowRef.current,
        },
      }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <StyledCard elevation={10}>
            <Arrow ref={arrowRef} placement={placement} />
            <CardContent>
              <H3SemiBold>
                Tem certeza que deseja realizar essa ação?
              </H3SemiBold>
              {message && <Message>{message}</Message>}
            </CardContent>
            <StyledActions>
              <Button color="primary" size="small" onClick={onConfirm}>
                <H4Bold>Sim, tenho certeza!</H4Bold>
              </Button>
              <Button size="small" onClick={onCancel}>
                <H4Bold>Não, não tenho certeza!</H4Bold>
              </Button>
            </StyledActions>
          </StyledCard>
        </Fade>
      )}
    </Popper>
  );
};

export default ConfirmationPopper;
