import styled from "styled-components";
import { Slider } from "@material-ui/core";

export const StyledSlider = styled(Slider)`
  margin-left: 14px;
  .MuiSlider-root {
    color: ${(props) => props.theme.colors.interactive.primary};
    height: 8px;
    margin: 0 10px;
  }
  .MuiSlider-rail {
    height: 8px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.layout.blackQuaternary};
  }
  .MuiSlider-track {
    height: 8px;
  }

  .MuiSlider-thumb {
    height: 24px;
    width: 3px;
    background-color: ${(props) => props.theme.colors.interactive.primary};
    border-radius: 0;
    border: 2px solid currentColor;
    margin-top: -8px;
    margin-left: 0;
    &:focus,
    &:hover,
    &:active {
      box-shadow: "inherit";
    }
  }
`;

export const Wrapper = styled.div`
  background: white;
  width: 100%;
  height: 80px;
  z-index: 999;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 60px;
  box-shadow: 0px -3px 6px ${(props) => props.theme.colors.layout.shadow};
`;

export const LabelTime = styled.span`
  font: normal normal normal 14px Open Sans;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.layout.blackPrimary};
  opacity: 1;
`;
