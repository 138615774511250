import {
  H1Bold,
  H2Bold,
  H3SemiBold,
  H4SemiBold,
  H5SemiBold,
} from "BaseComponents/Typographies";
import styled from "styled-components";

export const OwnerLabel = styled(H1Bold)`
  color: ${(props) => props.theme.colors.interactive.primary};
  margin: 6px 6px 0px 6px;
`;

export const InfoOwnerLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  justify-content: center;
  margin-bottom: 6px;
`;

export const UnitInfoLabel = styled(H3SemiBold)`
  color: ${(props) => props.theme.colors.interactive.secondary};
`;

export const InfoLabel = styled(H5SemiBold)`
  color: ${(props) => props.theme.colors.layout.blackTertiary};
  margin-left: 3px;
`;
