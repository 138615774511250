import { ResponseMachine } from "declarations";
import feathers from "./Feathers";

const machineService = feathers.service("machine-actual-locations");

export const getMachines = (
  skip: number,
  search?: string
): Promise<ResponseMachine> =>
  machineService.find({
    query: {
      $limit: 10,
      $skip: skip,
      plaque: {
        $search: search,
      },
    },
  });
