import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { FormControlLabel, Switch } from "@material-ui/core";
import { timeMask } from "Shared/masks";
import Button from "BaseComponents/Buttons/HeaderButton";
import { Geofence } from "declarations";
import ItemOrigin from "Components/Map/ForbiddenDestinations/ItemOrigin";
import {
  ButtonAction,
  ButtonsWrapper,
  EditWrapper,
  InputName,
  ItemWrapper,
  Label,
  ListOrigins,
  AddOrigin,
  LabelOrigins,
  DeleteIcon,
  AddIcon,
  ErrorText,
} from "../styles";
import ModalOrigins from "../ModalOrigins";
import { DestinationValidation } from "../Validation";

interface FormShape {
  name: string;
  origins: Geofence[];
}

interface CreateProps {
  onSubmit: (data: FormShape) => void;
  creating: number[][];
  loading: boolean;
  cancelCreate: () => void;
  originsToCreate?: (value: Geofence[]) => void;
}

const Create = ({
  onSubmit,
  creating,
  loading,
  cancelCreate,
  originsToCreate,
}: CreateProps) => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    setValue,
    watch,
    getValues,
    formState,
  } = useForm<FormShape>({
    resolver: yupResolver(DestinationValidation),
  });
  const [openModal, setOpenModal] = useState(false);
  const origins = watch("origins", []);

  const toggleOpenModal = () => {
    setOpenModal(true);
  };

  const toggleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    register("origins");
  }, [register]);

  const setOrigins = (value: Geofence[]) => {
    setValue("origins", [...value]);
    originsToCreate && originsToCreate(getValues("origins"));
  };

  const addOrigin = (geofence: Geofence) => {
    const findIndex = origins.findIndex(
      (value) => value.name === geofence.name
    );

    if (findIndex < 0) {
      setOrigins([...origins, geofence]);
    }
  };

  const removeOrigin = (geofence: Geofence) => {
    const findIndex = origins.findIndex(
      (value) => value.name === geofence.name
    );

    if (findIndex >= 0) {
      const newArray = [...origins];

      newArray.splice(findIndex, 1);

      setOrigins(newArray);
    }
  };

  const submit = handleSubmit(async (data) => {
    if (loading) return;

    onSubmit({ ...data });
  });

  return (
    <EditWrapper onSubmit={submit}>
      <InputName
        label="Nome do destino proibido"
        type="text"
        name="name"
        errorText={errors.name?.message || ""}
        ref={register()}
      />
      <ListOrigins>
        <AddOrigin>
          <LabelOrigins>Origens</LabelOrigins>
          <Button onClick={toggleOpenModal}>
            <AddIcon />
          </Button>
        </AddOrigin>
        {origins.map((value) => (
          <ItemOrigin geofence={value} onRemove={removeOrigin} />
        ))}
        {/* @ts-ignore */}
        {errors.origins && <ErrorText>{errors.origins.message}</ErrorText>}
      </ListOrigins>
      {creating.map((points) => (
        <ItemWrapper key={uuidv4()}>
          <Label>{`Lat: ${points[0]} / Long: ${points[1]}`}</Label>
        </ItemWrapper>
      ))}
      <ButtonsWrapper>
        <ButtonAction onClick={cancelCreate}>Cancelar</ButtonAction>
        <ButtonAction type="submit" isLoading={loading}>
          {" "}
          Salvar
        </ButtonAction>
      </ButtonsWrapper>
      <ModalOrigins
        open={openModal}
        onClose={toggleCloseModal}
        originsSelected={origins}
        addOrigin={addOrigin}
        removeOrigin={removeOrigin}
      />
    </EditWrapper>
  );
};

export default Create;
