import { H2SemiBold, H3SemiBold } from "BaseComponents/Typographies";
import styled from "styled-components";

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow: auto;
`;

export const WrapperChoseOwner = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 12px;
`;

export const LabelOwner = styled(H2SemiBold)`
  color: ${(props) => props.theme.colors.interactive.primary};
`;

export const Title = styled(H3SemiBold)`
  margin-bottom: 24px;
`;
