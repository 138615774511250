import React, { useState } from "react";
import dayjsUtils from "@date-io/dayjs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StyledTimePicker } from "./styles";

interface TimePickerProps {
  date: Date | null;
  setDate: (value: Date) => void;
  label?: string;
  disabled?: boolean;
  minDateMessage?: string;
  placeholder?: string;
}

const TimePicker = ({
  setDate,
  date,
  label,
  disabled,
  minDateMessage,
  placeholder,
}: TimePickerProps) => {
  return (
    <MuiPickersUtilsProvider utils={dayjsUtils}>
      <StyledTimePicker
        value={date}
        label={label}
        onChange={(value) => {}}
        onAccept={(value) => value && setDate(value.toDate())}
        ampm={false}
        views={["hours", "minutes"]}
        autoOk={false}
        variant="inline"
        disabled={disabled}
        minutesStep={1}
        minDateMessage={minDateMessage}
        placeholder={placeholder}
        InputProps={{
          "aria-label": placeholder,
          disableUnderline: true,
        }}
        format="HH:mm"
      />
    </MuiPickersUtilsProvider>
  );
};
export default TimePicker;
