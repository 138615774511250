/* eslint-disable no-underscore-dangle */
import {
  H3SemiBold,
  H2SemiBold,
  H4SemiBold,
  H5SemiBold,
} from "BaseComponents/Typographies";
import ItemGeofence from "Components/Map/Geofences/ItemGeofence";
import { GeofenceWithMachines, Unit } from "declarations";
import React from "react";
import { diffTimeToString, fromMilliseconds } from "Shared/utils";
import { v4 as uuidv4 } from "uuid";
import DefaultWrapper from "../DefaultWrapper";
import { OwnerLabel, InfoOwnerLine, UnitInfoLabel, InfoLabel } from "./styles";

interface GeofencesBoardProps {
  selectedUnit?: Unit;
  geofences: GeofenceWithMachines[];
}

const GeofencesBoard = ({ selectedUnit, geofences }: GeofencesBoardProps) => {
  const getGeofence = (name: string) => {
    return geofences.find((value) => value.name === name);
  };

  const formatTimeSelected = () => {
    if (selectedUnit) {
      const time = getGeofence(selectedUnit.owner.name)?.averageTimeMachineNow;

      if (time) return diffTimeToString(new Date().getTime(), time);

      return diffTimeToString(0, 0);
    }
    return null;
  };

  return (
    <DefaultWrapper
      style={{
        alignContent: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
      visible={!!selectedUnit}
    >
      {selectedUnit && (
        <>
          <H3SemiBold>Alvo principal</H3SemiBold>
          <OwnerLabel>{selectedUnit.owner.name}</OwnerLabel>
          <InfoOwnerLine>
            <UnitInfoLabel>{formatTimeSelected()}</UnitInfoLabel>
          </InfoOwnerLine>
          <InfoOwnerLine>
            <UnitInfoLabel>
              {getGeofence(selectedUnit.owner.name)?.machines.length}
            </UnitInfoLabel>
            <InfoLabel>placas</InfoLabel>
          </InfoOwnerLine>

          <hr />
          {selectedUnit.attendees.map((attendee) => (
            <ItemGeofence
              key={attendee._id || uuidv4()}
              title={attendee.name}
              machines={getGeofence(attendee.name)?.machines.length}
              averageTimeNow={getGeofence(attendee.name)?.averageTimeMachineNow}
            />
          ))}
        </>
      )}
    </DefaultWrapper>
  );
};

export default GeofencesBoard;
