import React from "react";
import {
  Bar,
  MenuItemWrapper,
} from "./styles";

interface MenuIconProps {
  active: string;
}

export default function MenuIcon({ active}: MenuIconProps) {
  return (
    <MenuItemWrapper active={active}>
      <Bar active={active} />
      <Bar active={active} />
      <Bar active={active} />
    </MenuItemWrapper>
  );
}
