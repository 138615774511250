import { H3SemiBold } from "BaseComponents/Typographies";
import styled from "styled-components";

export const LineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-content: center;
  margin-top: 5px;
`;

export const WrapperInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  > h5 {
    font-size: 12px;
    color: ${(props) => props.theme.colors.layout.blackTertiary};
    margin-left: 3px;
  }
`;

export const InfoLabel = styled(H3SemiBold)`
  color: ${(props) => props.theme.colors.interactive.secondary};
`;
