import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlLabel, Switch } from "@material-ui/core";
import { ForbiddenDestination, Geofence } from "declarations";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { timeMask } from "Shared/masks";
import { fromMillisecondsToHour } from "Shared/utils";
import { v4 as uuidv4 } from "uuid";
import {
  ButtonAction,
  ButtonsWrapper,
  EditWrapper,
  InputName,
  ItemWrapper,
  Label,
} from "../styles";
import { DestinationValidation } from "../Validation";

interface FormShape {
  name: string;
}

interface EditProps {
  onSubmit: (data: FormShape) => void;
  loading: boolean;
  destination: ForbiddenDestination;
  cancelEdit: () => void;
}

const Edit = ({ onSubmit, loading, cancelEdit, destination }: EditProps) => {
  const { register, handleSubmit, errors, reset, watch, setValue } =
    useForm<FormShape>({
      resolver: yupResolver(DestinationValidation),
      defaultValues: {
        name: destination.name,
      },
    });

  const whatchIsAllowed = watch("allowed");

  const submit = handleSubmit(async (data) => {
    if (loading) return;

    onSubmit({ ...data });
  });

  return (
    <EditWrapper onSubmit={submit}>
      <InputName
        label="Nome do destino proibido"
        type="text"
        name="name"
        errorText={errors.name?.message || ""}
        ref={register()}
      />
      {destination.coordinates.coordinates[0].map((points) => (
        <ItemWrapper key={uuidv4()}>
          <Label>{`Lat: ${points[0]} / Long: ${points[1]}`}</Label>
        </ItemWrapper>
      ))}
      <ButtonsWrapper>
        <ButtonAction onClick={cancelEdit}>Cancelar</ButtonAction>
        <ButtonAction type="submit" isLoading={loading}>
          {" "}
          Salvar
        </ButtonAction>
      </ButtonsWrapper>
    </EditWrapper>
  );
};

export default Edit;
