import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Label,
  LabelWrapper,
  TextWrapper,
  CollapisableWrapper,
  Content,
  Arrow,
} from "./styles";

interface CollapsibleProps {
  icon?: React.ReactNode;
  title: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

const Collapisable = ({ title, icon, children, onClick }: CollapsibleProps) => {
  const [active, setActive] = useState("closed");

  const location = useLocation();

  useEffect(() => {
    location.pathname.split("/")[1].toLowerCase() === title.toLowerCase() &&
      setActive("open");
  }, [location, title]);

  const collapse = () => {
    active === "open" ? setActive("closed") : setActive("open");
  };
  return (
    <CollapisableWrapper>
      <LabelWrapper onClick={onClick || collapse}>
        {icon}
        <TextWrapper>
          <Label>{title}</Label>
        </TextWrapper>
        {!onClick && <Arrow active={active} />}
      </LabelWrapper>
      <Content active={active}>{children}</Content>
    </CollapisableWrapper>
  );
};

export default Collapisable;
