import { H3SemiBold } from "BaseComponents/Typographies";
import TableOptions from "Components/TableOptions";
import styled from "styled-components";

export const WrapperTrips = styled.div`
  overflow: auto;
`;

export const FilterOption = styled(TableOptions)`
  margin: 0;
`;

export const Title = styled(H3SemiBold)`
  margin: 12px;
  font-color: ${(props) => props.theme.colors.layout.blackPrimary};
`;
