import React, { useState, useEffect } from "react";
import Modal from "Components/Modal";
import DateRange from "BaseComponents/Calendar/DateRange";
import theme from "Styles/theme";
import dayjs from "dayjs";
import { ButtonCancel, ButtonSubmit, Footer, Wrapper } from "./styles";

export interface DateRangeType {
  startDate: Date;
  endDate: Date;
  color?: string;
  key?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  showDateDisplay?: boolean;
}

interface SearchDateProps {
  open: boolean;
  onClose: () => void;
  onChange: (value: DateRangeType | null) => void;
}

const SearchDate = ({ open, onClose, onChange }: SearchDateProps) => {
  const [dateRange, setDateRange] = useState<DateRangeType[]>([
    {
      startDate: dayjs(new Date()).startOf("day").toDate(),
      endDate: dayjs(new Date()).endOf("day").toDate(),
      color: theme.colors.interactive.primary,
      key: "selection",
      showDateDisplay: false,
    },
  ]);

  const applyDate = () => {
    const dateFixEndDate: DateRangeType = {
      ...dateRange[0],
      endDate: dayjs(dateRange[0].endDate).endOf("day").toDate(),
    };
    onChange(dateFixEndDate);
    onClose();
  };

  const clearDate = () => {
    setDateRange([
      {
        startDate: dayjs(new Date()).startOf("day").toDate(),
        endDate: dayjs(new Date()).endOf("day").toDate(),
        color: theme.colors.interactive.primary,
        key: "selection",
        showDateDisplay: false,
      },
    ]);
    onChange(null);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Wrapper>
        <DateRange
          range={dateRange}
          setRange={(value) => setDateRange([value])}
        />
        <Footer>
          <ButtonSubmit onClick={applyDate}>Aplicar</ButtonSubmit>
          <ButtonCancel onClick={clearDate}>Limpar</ButtonCancel>
          <ButtonCancel onClick={onClose}>Cancelar</ButtonCancel>
        </Footer>
      </Wrapper>
    </Modal>
  );
};

export default SearchDate;
