import { Stepper } from "@material-ui/core";
import styled from "styled-components";

export const CustomStepper = styled(Stepper)`
  padding: 16px !important;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.layout.blackQuaternary};
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 8px;
  }
`;
