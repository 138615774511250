import React, { forwardRef } from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

const Wrapper = styled.div`
  background-color: white;
  color: ${(props) => props.theme.colors.layout.blackQuaternary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  align-self: center;
  margin: auto;
`;

const FullPageSpinner = forwardRef<HTMLDivElement>((props, ref) => (
  <Wrapper ref={ref}>
    <CircularProgress color="inherit" />
  </Wrapper>
));

export default FullPageSpinner;
