import React, { useCallback, useEffect, useState } from "react";
import Modal from "Components/Modal";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import SearchBar from "BaseComponents/SearchBar";
import toasts from "Shared/toasts";
import { getClients } from "Api/Clients";
import useInfiniteScroll from "react-infinite-scroll-hook";
import InfiniteScroll from "BaseComponents/InfinityScroll";
import { v4 as uuidv4 } from "uuid";
import {
  Body,
  ButtonCancel,
  ButtonSubmit,
  Footer,
  Header,
  Wrapper,
  ItemList,
  ListWrapper,
  ChipsWrapper,
  StyledChip,
} from "./styles";

interface MarkerOptionsProps {
  open: boolean;
  onClose: () => void;
  onApplyOption: (value: string) => void;
  values: string[];
  selectedValue?: string;
}

const MarkerOptions = ({
  onClose,
  open,
  onApplyOption,
  values,
  selectedValue,
}: MarkerOptionsProps) => {
  const [selected, setSelected] = useState<string | undefined>(selectedValue);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSelected(value);
  };

  const applyOption = () => {
    if (selected) onApplyOption(selected);

    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Wrapper>
        <Header />
        <Body>
          <RadioGroup
            aria-label="markerOptions"
            name="options"
            value={selected}
            onChange={handleChange}
          >
            {values.map((value) => (
              <FormControlLabel
                key={uuidv4()}
                value={value}
                control={<Radio color="primary" />}
                label={value}
              />
            ))}
          </RadioGroup>
        </Body>
        <Footer>
          <ButtonSubmit onClick={applyOption}>Aplicar</ButtonSubmit>
          <ButtonCancel onClick={onClose}>Cancelar</ButtonCancel>
        </Footer>
      </Wrapper>
    </Modal>
  );
};

export default MarkerOptions;
